import React, { useState, useEffect } from 'react'
import { MemberDetailsCardContainer, MemberDetailsCardContainerEmpty, MemberImageContainer, MemberImgEdit } from '../style/home.styles'
import { AbsoluteCenter, AbsoluteTopRight, Body, BodyMediumG60, FlexBox, FlexBoxSA, FlexBoxSE, MagnifyIcon, SubBodyMediumG40, SubTitle, SubTitleItalics, SubTitleSemiBold } from '../../../global/global.styles'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';
import { ImgHolder } from '../../../global/components/imgHolder';
import dpPH from "../../../assets/images/dpPH.png"
import { SpacerLeftRightSmall, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall, SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer';
import { NutrientBlock } from './nutrientBlock';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import { Divider } from '../../../global/components/divider';

export const MemberDetailsCard = (props) => {

    const [ambit, setAmbit] = useState("empty")
    const [memberData, setMemberData] = useState([])

    useEffect(() => {
        setMemberData(props.users?.filter((x) => x.userId === props.specId)[0])
    }, [props.users])


    return (
        <>
            {
                props.resetAmbit !== "empty"
                    &&
                    ambit === "empty"
                    ?
                    props.content?.length > 0
                        ?
                        <MemberDetailsCardContainer>
                            <AbsoluteTopRight style={{ padding: 8 }}>
                                <Tooltip title="Edit" onClick={() => setAmbit("edit")}>
                                    <MagnifyIcon>
                                        <BorderColorRoundedIcon
                                            sx={{
                                                cursor: "pointer"
                                            }}
                                        />
                                    </MagnifyIcon>
                                </Tooltip>
                            </AbsoluteTopRight>
                            <FlexBoxSA>
                                <MemberImageContainer>
                                    <ImgHolder
                                        width="100%"
                                        height="100%"
                                        borderRadius="50%"
                                        img={memberData.dp ? memberData.dp : dpPH}
                                    />
                                </MemberImageContainer>
                            </FlexBoxSA>
                            <SpacerTopBottomXL />
                            <FlexBoxSA>
                                <SubTitleSemiBold>
                                    {`${memberData?.firstname} ${memberData?.lastname}`}
                                </SubTitleSemiBold>
                            </FlexBoxSA>
                            <SpacerTopBottomMedium />
                            <FlexBoxSA>
                                <FlexBox>
                                    <BodyMediumG60>
                                        {`${props.specGender}`},
                                    </BodyMediumG60>
                                    <SpacerLeftRightSmall />
                                    <BodyMediumG60>
                                        {`${props.specAge}y`},
                                    </BodyMediumG60>
                                    <SpacerLeftRightSmall />
                                    <BodyMediumG60>
                                        {`${props.specWeight}kg`}
                                    </BodyMediumG60>
                                </FlexBox>
                            </FlexBoxSA>
                            <SpacerTopBottomXL />
                            <SubBodyMediumG40>
                                Diet Style
                            </SubBodyMediumG40>
                            <SpacerTopBottomSmall />
                            <SubTitleItalics>
                                {props.specDietStyle}
                            </SubTitleItalics>
                            <SpacerTopBottomSmall />
                            <Divider 
                                width="100%"
                                height={1}
                                color="#949494"
                            />
                            <SpacerTopBottomLarge />
                            <NutrientBlock
                                ambit={ambit}
                                border="2px solid blue"
                                color="blue"
                                bgColor="#F8F9FF"
                                label="Carbohydrate"
                                valuePresent={props.specNutrients.carbohydrate}
                            />
                            <SpacerTopBottomMedium />
                            <NutrientBlock
                                ambit={ambit}
                                border="2px solid green"
                                color="green"
                                bgColor="#F9FFF8"
                                label="Protein"
                                valuePresent={props.specNutrients.protein}
                            />
                            <SpacerTopBottomMedium />
                            <NutrientBlock
                                ambit={ambit}
                                border="2px solid orange"
                                color="orange"
                                bgColor="#FFFDFA"
                                label="Fats"    
                                valuePresent={props.specNutrients.fats}
                            />
                        </MemberDetailsCardContainer>
                        :
                        <MemberDetailsCardContainerEmpty>
                            <AbsoluteCenter>
                                <Tooltip title="Add new member" onClick={() => setAmbit("edit")}>
                                    <MagnifyIcon>
                                        <AddCircleRoundedIcon
                                            sx={{
                                                color: "#373738",
                                                cursor: "pointer"
                                            }}
                                            fontSize="large"
                                        />
                                    </MagnifyIcon>
                                </Tooltip>
                            </AbsoluteCenter>
                        </MemberDetailsCardContainerEmpty>
                    :
                    ambit === "edit"
                    &&
                    <MemberDetailsCardContainer>
                        <AbsoluteTopRight style={{ padding: 8 }}>
                            <Tooltip title="Cancel edit" onClick={() => setAmbit("empty")}>
                                <MagnifyIcon>
                                    <DisabledByDefaultRoundedIcon
                                        color='error'
                                        sx={{
                                            cursor: "pointer"
                                        }}
                                    />
                                </MagnifyIcon>
                            </Tooltip>
                        </AbsoluteTopRight>
                        <FlexBoxSA>
                            <MemberImageContainer htmlFor="file">
                                <input
                                    id="file"
                                    type='file'
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={props.onChangeFile}
                                />
                                {
                                    props.file
                                        ?
                                        <ImgHolder
                                            width="100%"
                                            height="100%"
                                            borderRadius="50%"
                                            img={URL.createObjectURL(props.file)}
                                        />
                                        :
                                        <ImgHolder
                                            width="100%"
                                            height="100%"
                                            borderRadius="50%"
                                            img={dpPH}
                                        />
                                }
                                <MemberImgEdit>
                                    <MagnifyIcon>
                                        <AddPhotoAlternateRoundedIcon
                                            color='secondary'
                                            sx={{
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </MagnifyIcon>
                                </MemberImgEdit>
                            </MemberImageContainer>
                        </FlexBoxSA>
                        <SpacerTopBottomXL />
                        <FlexBoxSA>
                            <FormControl sx={{ width: 120 }}>
                                <InputLabel size='small' id="demo-simple-select-label">Select member</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select member"
                                    value={props.member}
                                    onChange={props.onChangeMember}
                                    size='small'
                                    fullWidth
                                >
                                    {props.allMembers?.map((option) => (
                                        <MenuItem key={option.userId} value={option.userId}>
                                            {`${option.firstname} ${option.lastname} ( ${option.familyId})`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FlexBoxSA>
                        <SpacerTopBottomMedium />
                        <FlexBoxSE>
                            <FormControl sx={{ width: 120 }}>
                                <InputLabel size='small' id="demo-simple-select-label">Gender</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="gender"
                                    value={props.gender}
                                    onChange={props.onChangeGender}
                                    size='small'
                                    fullWidth
                                >
                                    {props.genderArr.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                id="age"
                                label="Age (Y)"
                                name="age"
                                type="number"
                                size='small'
                                autoComplete='off'
                                sx={{ width: 120 }}
                                value={props.age}
                                onChange={props.onChangeAge}
                                inputProps={{ style: { fontSize: '16px' } }}
                                placeholder={props.placeholderAge}
                            />
                            <TextField
                                id="weight"
                                label="Weight"
                                name="weight"
                                type="number"
                                size='small'
                                autoComplete='off'
                                sx={{ width: 120 }}
                                value={props.weight}
                                onChange={props.onChangeWeight}
                                inputProps={{ style: { fontSize: '16px' } }}
                                placeholder={props.placeholderWeight}
                            />
                        </FlexBoxSE>
                        <SpacerTopBottomMedium />
                        <TextField
                            variant="standard"
                            id="dietStyle"
                            label="Diet style"
                            name="dietStyle"
                            type="text"
                            size='small'
                            autoComplete='off'
                            fullWidth
                            value={props.dietStyle}
                            onChange={props.onChangeDietStyle}
                            inputProps={{ style: { fontSize: '16px' } }}
                            placeholder={props.placeholderDietStyle}
                        />
                        <SpacerTopBottomLarge />
                        <NutrientBlock
                            ambit={ambit}
                            border="2px solid blue"
                            color="blue"
                            bgColor="#F8F9FF"
                            label="Carbohydrate"
                            value={props.carbValue}
                            onChangeValue={props.onChangeCarbValue}
                        />
                        <SpacerTopBottomMedium />
                        <NutrientBlock
                            ambit={ambit}
                            border="2px solid green"
                            color="green"
                            bgColor="#F9FFF8"
                            label="Protein"
                            value={props.proteinValue}
                            onChangeValue={props.onChangeProteinValue}
                        />
                        <SpacerTopBottomMedium />
                        <NutrientBlock
                            ambit={ambit}
                            border="2px solid orange"
                            color="orange"
                            bgColor="#FFFDFA"
                            label="Fats"
                            value={props.fatValue}
                            onChangeValue={props.onChangeFatValue}
                        />
                        <SpacerTopBottomLarge />
                        <Button
                            variant='contained'
                            color='success'
                            fullWidth
                            size='small'
                            sx={{
                                textTransform: "none"
                            }}
                            onClick={props.saveMember}>
                            Save
                        </Button>
                    </MemberDetailsCardContainer>

            }
        </>
    )
}

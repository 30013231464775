// DEFAULT
import React from 'react'

// INTERFACE
import { LoginForm } from './interface/loginForm'
import { AppLogo } from './interface/appLogo'
import { AbsoluteCenter, AbsoluteLogo, FlexColumnSE, RelativeContainer } from '../../global/global.styles'
import { LoginWrapper } from './style/login.styles'
import { SpacerTopBottomMedium, SpacerTopBottomXXL } from '../../design/spacer/spacer'
import { LoginFooter } from './interface/loginFooter'

// MAIN
export const Login = () => {
    return (
        <LoginWrapper>
            <FlexColumnSE style={{ height: '100%' }}>
                <AppLogo />
                <LoginForm />
                <LoginFooter />
            </FlexColumnSE>
        </LoginWrapper>
    )
}
import styled from 'styled-components'

// ----------------------------------------Containers----------------------------------------//

export const HomeMenuContainer = styled.div`
    margin-top: ${(props) => props.theme.space[6]};
    margin-bottom: ${(props) => props.theme.space[6]};
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[16]};
    padding-right: ${(props) => props.theme.space[16]};
    background-color: #D8D9D9;
`;

export const AddImageContainer = styled.label`
    position: relative;
    border: 2px dashed #B1B7B7;
    width: 128px;
    height: 128px;
    margin: 0 16px 4px 0;
    display: inline-block;
`;

export const InvBlockContainer = styled.div`
    padding-top: ${(props) => props.theme.space[5]};
    padding-bottom: ${(props) => props.theme.space[3]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    box-shadow: 1px 1px 6px 1px rgba(150,150,150,0.75);
-webkit-box-shadow: 1px 1px 6px 1px rgba(150,150,150,0.75);
-moz-box-shadow: 1px 1px 6px 1px rgba(150,150,150,0.75);
    width: 200px;
    height: 288px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;

    &:hover {
        transform: scale(1.03);
        transition: all 0.3s ease;
        box-shadow: 1px 1px 6px 1px rgba(150,150,150,0.75);
-webkit-box-shadow: 1px 1px 6px 1px rgba(150,150,150,0.75);
-moz-box-shadow: 1px 1px 6px 1px rgba(150,150,150,0.75);
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }  
`;

export const InvBlockWrapper = styled.div`
    padding-top: ${(props) => props.theme.space[3]};
    padding-bottom: ${(props) => props.theme.space[3]};
    padding-left: ${(props) => props.theme.space[2]};
    padding-right: ${(props) => props.theme.space[2]};
`;

export const InvBlockQtyCircle = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: 1px solid #6E6D70;
`;

export const InnerCircle = styled.div`
    border-radius: 50%;
    width: 26px;
    height: 26px;
    border: 1px solid #6E6D70;
`;

export const InvBlockQtyRectangle = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    width: 64px;
    height: 32px;
    border: 1px solid #6E6D70;
`;

export const InnerRectangle = styled.div`
    width: 56px;
    height: 24px;
    border: 1px solid #6E6D70;
`;

export const InvItemDetailsContainer = styled.div`
    padding-top: ${(props) => props.theme.space[2]};
    padding-bottom: ${(props) => props.theme.space[2]};
    padding-left: ${(props) => props.theme.space[2]};
    padding-right: ${(props) => props.theme.space[2]};

    @media only screen and (max-width: 650px) {
        padding: 0;
    }
`;

export const SourceBlockWrapper = styled.div`
    margin-top: ${(props) => props.theme.space[2]};
    margin-bottom: ${(props) => props.theme.space[2]};
    margin-right: ${(props) => props.theme.space[3]};
`;

export const SourceBlockContainer = styled.div`
    padding-top: ${(props) => props.theme.space[3]};
    padding-bottom: ${(props) => props.theme.space[3]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    border-radius: 8px;
    border: 2px solid #BDFF00;
    width: 120px;
    height: 152px;
`;

export const IngredientsArea = styled.div`
    padding-top: ${(props) => props.theme.space[2]};
    padding-bottom: ${(props) => props.theme.space[2]};
    padding-left: ${(props) => props.theme.space[2]};
    padding-right: ${(props) => props.theme.space[2]};
    border-radius: 4px;
    background-color: #F8F8F8;
    min-height: 120px;
`;

export const IngredientTagContainer = styled.div`
    padding-top: ${(props) => props.theme.space[1]};
    padding-bottom: ${(props) => props.theme.space[1]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[2]};
    border-radius: 16px;
    background-color: #373738;
    margin: ${(props) => props.theme.space[1]};
`;

export const IngredientCompositionBlockContainer = styled.div`
    padding-top: ${(props) => props.theme.space[2]};
    padding-bottom: ${(props) => props.theme.space[2]};
    padding-left: ${(props) => props.theme.space[2]};
    padding-right: ${(props) => props.theme.space[2]};
    border: 1px solid black;
`;

export const MemberDetailsCardContainer = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]}; 
    margin: ${(props) => props.theme.space[2]};
    width: 320px;
    height: 480px;
    border-radius: 8px;
    box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
-webkit-box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
-moz-box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
    overflow-y: auto;
    position: relative;
`;

export const MemberDetailsCardContainerEmpty = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]}; 
    margin: ${(props) => props.theme.space[2]};
    border: 2px dashed black;
    position: relative;
    width: 320px;
    height: 480px;
    border-radius: 8px;
    overflow-y: auto;
`;

export const MemberImageContainer = styled.label`
    width: 88px;
    height: 88px;
    border-radius: 50%;
    position: relative;
`;

export const MemberImgEdit = styled.div`
    position: absolute;
    right: -4px;
    top: -4px;
`;

export const NutrientBlockContainer = styled.div`
    border-radius: 10px;
    padding-top: ${(props) => props.theme.space[3]};
    padding-bottom: ${(props) => props.theme.space[3]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]}; 
`;

export const TimeframeBlockVerticalContainer = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    width: 200px;
    height: 456px;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
-webkit-box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
-moz-box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);

    &:hover {
        transform: scale(1.03);
        transition: all 0.3s ease;
        box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
-webkit-box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
-moz-box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }
`;

export const TimeframeBlockHorizontalContainer = styled.div`
    padding-top: ${(props) => props.theme.space[3]};
    padding-bottom: ${(props) => props.theme.space[3]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    width: 340px;
    height: 220px;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
-webkit-box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
-moz-box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);

    &:hover {
        transform: scale(1.03);
        transition: all 0.3s ease;
        box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
-webkit-box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
-moz-box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }
`;

export const DayMealEntryContainer = styled.div`
    border-radius: 10px;
    padding-top: ${(props) => props.theme.space[2]};
    padding-bottom: ${(props) => props.theme.space[3]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]}; 
    border: 2px solid #E2E2E3;
`;

export const MealEntryCardContainer = styled.div`
    padding-top: ${(props) => props.theme.space[3]};
    padding-bottom: ${(props) => props.theme.space[3]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    position: relative;
    box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
-webkit-box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75);
-moz-box-shadow: 0px 1px 5px 1px rgba(147,147,147,0.75); 
`;

export const ObjectTileContainer = styled.div`
    padding-top: ${(props) => props.theme.space[1]};
    padding-bottom: ${(props) => props.theme.space[1]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    border-radius: 24px;
    background-color: #373738;
`;

export const IngCompTileContainer = styled.div`
    padding-top: ${(props) => props.theme.space[3]};
    padding-bottom: ${(props) => props.theme.space[3]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    border-radius: 8px;
    background-color: #F4F6F6;
    position: relative;
    min-width: 120px;
    min-height: 40px;
    border: 1px solid #AAA9AE;
`;

export const ConsumInputContainer = styled.div`
    padding-top: ${(props) => props.theme.space[1]};
    padding-bottom: ${(props) => props.theme.space[1]};
    padding-left: ${(props) => props.theme.space[1]};
    padding-right: ${(props) => props.theme.space[1]};
    border: 1px solid black;
`;

export const MealTableContainer = styled.div`
    padding-top: ${(props) => props.theme.space[4]};
    padding-bottom: ${(props) => props.theme.space[4]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    background-color: #F8F8F8;
`;

export const MealTileWrapper = styled.div`
    
`;

export const MealTileContainer = styled.div`
    padding-top: ${(props) => props.theme.space[2]};
    padding-bottom: ${(props) => props.theme.space[2]};
    padding-left: ${(props) => props.theme.space[2]};
    padding-right: ${(props) => props.theme.space[2]};
    background-color: #FFFFFF;
    border-radius: 8px;
`;

export const MealTileMealBlockContainer = styled.div`
    padding-top: ${(props) => props.theme.space[1]};
    padding-bottom: ${(props) => props.theme.space[1]};
    padding-left: ${(props) => props.theme.space[1]};
    padding-right: ${(props) => props.theme.space[1]};
    border: 1px solid #000000;
`;
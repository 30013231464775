import React from 'react'
import { AbsoluteCenter, BodySemiBold, FlexBox, FlexBoxSA, FlexBoxSB, MagnifyIcon, SubBodyBold, Title } from '../../../global/global.styles'
import { SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerRightSmall, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall, SpacerTopBottomXL } from '../../../design/spacer/spacer'
import { Button, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip } from '@mui/material'
import { AddImageContainer } from '../style/home.styles';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { ImgHolder } from '../../../global/components/imgHolder';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

export const NewPurchaseForm = (props) => {

  return (
    <>
      <Title>
        {
          props.scope === "edit"
            ?
            "Edit purchase details"
            :
            "Enter purchase details:"
        }
      </Title>
      <SpacerTopBottomXL />
      <FlexBoxSB>
        {
          props.itemType === "piece"
            ?
            <div>
              <BodySemiBold>
                Quanity purchased*
              </BodySemiBold>
              <SpacerTopBottomSmall />
              <TextField
                id="qty"
                name="qty"
                type="number"
                size='small'
                autoComplete='off'
                fullWidth
                value={props.qty}
                onChange={props.onChangeQty}
                inputProps={{ style: { fontSize: '16px' } }}
                placeholder={props.placeholderQty}
                sx={{ width: props.width > 450 ? 160 : 120 }}
              />
            </div>
            :
            props.itemType === "packaged"
              ?
              <div>
                <BodySemiBold>
                  Quanity purchased*
                </BodySemiBold>
                <SpacerTopBottomSmall />
                <TextField
                  id="qty"
                  name="qty"
                  type="number"
                  size='small'
                  autoComplete='off'
                  fullWidth
                  value={props.qty}
                  onChange={props.onChangeQty}
                  inputProps={{ style: { fontSize: '16px' } }}
                  placeholder={props.placeholderQty}
                  sx={{ width: props.width > 450 ? 160 : 120 }}
                />
              </div>
              :
              props.itemType === "weighted"
              &&
              <div>
                <BodySemiBold>
                  Weight (gm / l)*
                </BodySemiBold>
                <SpacerTopBottomSmall />
                <TextField
                  id="wQty"
                  name="wQty"
                  type="number"
                  size='small'
                  autoComplete='off'
                  fullWidth
                  value={props.wQty}
                  onChange={props.onChangeWQty}
                  inputProps={{ style: { fontSize: '16px' } }}
                  placeholder={props.placeholderWQty}
                  sx={{ width: props.width > 450 ? 160 : 120 }}
                />
              </div>
        }
        <SpacerLeftRightMedium />
        <div>
          <BodySemiBold>
            MRP (₹)*
          </BodySemiBold>
          <SpacerTopBottomSmall />
          <TextField
            id="price"
            name="price"
            type="number"
            size='small'
            autoComplete='off'
            fullWidth
            value={props.price}
            onChange={props.onChangePrice}
            inputProps={{ style: { fontSize: '16px' } }}
            placeholder={props.placeholderPrice}
            sx={{ width: props.width > 450 ? 160 : 120 }}
          />
        </div>
      </FlexBoxSB>
      <SpacerTopBottomLarge />
      <FlexBoxSB>
        <div>
          <BodySemiBold>
            Source Type
          </BodySemiBold>
          <SpacerTopBottomSmall />
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.sourceType}
              onChange={props.onChangeSourceType}
              size='small'
              sx={{ width: props.width > 450 ? 160 : 120 }}
            >
              {props.medium.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <SpacerLeftRightMedium />
        <div>
          <BodySemiBold>
            Source Name*
          </BodySemiBold>
          <SpacerTopBottomSmall />
          <TextField
            id="sourceName"
            name="sourceName"
            type="text"
            size='small'
            autoComplete='off'
            fullWidth
            value={props.sourceName}
            onChange={props.onChangeSourceName}
            inputProps={{ style: { fontSize: '16px' }, maxLength: 15 }}
            placeholder={props.placeholderSourceName}
            sx={{ width: props.width > 450 ? 160 : 120 }}
          />
        </div>
      </FlexBoxSB>
      <SpacerTopBottomLarge />
      <FlexBoxSB>
        <div>
          <BodySemiBold>
            Payment Mode
          </BodySemiBold>
          <SpacerTopBottomSmall />
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.paymentMode}
              onChange={props.onChangePaymentMode}
              size='small'
              sx={{ width: props.width > 450 ? 160 : 120 }}
            >
              {props.paymentModeArr.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <SpacerLeftRightMedium />
        <div>
          <BodySemiBold>
            Discount (%)
          </BodySemiBold>
          <SpacerTopBottomSmall />
          <TextField
            id="discount"
            name="discount"
            type="number"
            size='small'
            autoComplete='off'
            fullWidth
            value={props.discount}
            onChange={props.onChangeDiscount}
            inputProps={{ style: { fontSize: '16px' } }}
            placeholder={props.placeholderDiscount}
            sx={{ width: props.width > 450 ? 160 : 120 }}
          />
        </div>
      </FlexBoxSB>
      <SpacerTopBottomLarge />
      <FlexBoxSB>
        <div>
          <BodySemiBold>
            Usage start date
          </BodySemiBold>
          <SpacerTopBottomSmall />
          <TextField
            id="startDate"
            name="startDate"
            type="date"
            size='small'
            autoComplete='off'
            sx={{
              width: props.width > 450 ? 160 : 120
            }}
            value={props.startDate}
            onChange={props.onChangeStartDate}
            inputProps={{ style: { fontSize: '16px' } }}
            placeholder={props.placeholderStartDate}
          />
        </div>
        <SpacerLeftRightMedium />
        {
          props.itemType === "packaged"
          &&
          <div>
            <BodySemiBold>
              Manufactured date
            </BodySemiBold>
            <SpacerTopBottomSmall />
            <TextField
              id="mfdDate"
              name="mfdDate"
              type="date"
              size='small'
              autoComplete='off'
              sx={{
                width: props.width > 450 ? 160 : 120
              }}
              value={props.mfdDate}
              onChange={props.onChangeMfdDate}
              inputProps={{ style: { fontSize: '16px' } }}
              placeholder={props.placeholderMfdDate}
            />
          </div>
        }
      </FlexBoxSB>
      {
        props.scope === "edit"
        &&
        <>
          <SpacerTopBottomLarge />
          <FlexBox>
            <div>
              <BodySemiBold>
                Usage end date
              </BodySemiBold>
              <SpacerTopBottomSmall />
              <TextField
                id="endDate"
                name="endDate"
                type="date"
                size='small'
                autoComplete='off'
                sx={{
                  width: props.width > 450 ? 160 : 120
                }}
                value={props.endDate}
                onChange={props.onChangeEndDate}
                inputProps={{ style: { fontSize: '16px' } }}
                placeholder={props.placeholderEndDate}
              />
            </div>
          </FlexBox>
        </>
      }
      {/* <SpacerTopBottomLarge />
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={props.usageType}
          onChange={props.onChangeUsageType}
        >
          <FormControlLabel value="using" control={<Radio />} label="Using" />
          <FormControlLabel value="storage" control={<Radio />} label="Storage" />
          <FormControlLabel value="info" control={<Radio />} label="Information" />
        </RadioGroup>
      </FormControl> */}
      <SpacerTopBottomLarge />
      {
        props.loading
          ?
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            fullWidth
            variant="outlined"
            sx={{
              textTransform: 'none'
            }}
            size='large'
          >
            Saving
          </LoadingButton>
          :
          props.itemType === "piece"
            ?
            <Button
              variant='contained'
              color={props.btnColor}
              fullWidth
              sx={{
                textTransform: 'none'
              }}
              onClick={props.handleSave}
              disabled={props.disabledPiece}>
              {props.btnTitle}
            </Button>
            :
            props.itemType === "packaged"
              ?
              <Button
                variant='contained'
                color={props.btnColor}
                fullWidth
                sx={{
                  textTransform: 'none'
                }}
                onClick={props.handleSave}
                disabled={props.disabledPackaged}>
                {props.btnTitle}
              </Button>
              :
              props.itemType === "weighted"
              &&
              <Button
                variant='contained'
                color={props.btnColor}
                fullWidth
                sx={{
                  textTransform: 'none'
                }}
                onClick={props.handleSave}
                disabled={props.disabledWeighted}>
                {props.btnTitle}
              </Button>
      }
      <SpacerTopBottomMedium />
      <Button
        variant='outlined'
        color={props.btnColor}
        fullWidth
        color="error"
        sx={{
          textTransform: 'none'
        }}
        onClick={props.handleCancel}>
        Cancel
      </Button>
    </>
  )
}

import React, { createContext, useState } from 'react'

export const ModalContext = createContext()

export const ModalProvider = ({ children }) => {

    const [addTransactions, setAddTransactions] = useState(false);
    const [editTransactions, setEditTransactions] = useState(false);

    return (
        <ModalContext.Provider
            value={{
                addTransactions,
                setAddTransactions,
                editTransactions,
                setEditTransactions,
            }}
        >
            {children}
        </ModalContext.Provider>
    )
}
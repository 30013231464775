import React from 'react'
import { BodyMediumG60 } from '../../../global/global.styles'
import { InvSlider } from './invSlider'

export const InvSlab = (props) => {

    return (
        <>
            <BodyMediumG60>
                {props.title}
            </BodyMediumG60>
            <InvSlider
                content={props.content}
                location={props.location}
                width={props.width}
            />
        </>
    )
}

// DEFAULT
import React, { useState, useContext } from 'react'


// STYLES
import { Container, FlexPageCenter, FlexRowEnd } from '../../../global/global.styles'
import { LoginFormComponent, LoginFormContainer, TemplateStyle } from '../style/login.styles'

// COMPONENTS
import { Component } from '../components/components'
import { Button, TextField } from '@mui/material'
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer'
import { AuthContext } from '../../../context/auth/auth.provider'

// FUNCTIONS


// LIBRARIES
import { useNavigate } from 'react-router-dom';



// MAIN
export const LoginForm = () => {

    const navigate = useNavigate()

    const { logInWithEmailAndPassword } = useContext(AuthContext);

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    return (
        <>
            <LoginFormComponent>
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Email"
                    name="email"
                    type='email'
                    size='small'
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <SpacerTopBottomLarge />
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Password"
                    name="password"
                    type='password'
                    size='small'
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <SpacerTopBottomLarge />
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => logInWithEmailAndPassword(email, password)}>
                    Login
                </Button>
            </LoginFormComponent>
        </>
    )
}
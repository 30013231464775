import React, { useEffect, useState } from 'react'
import { FlexColumnSB, TitleSemiBoldG40 } from '../../../global/global.styles'
import moment from 'moment'
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer';
import { NoteBlock } from './noteBlock';
import { Button } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const TimeRight = (props) => {

    const [time, setTime] = useState(Date.now());

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <FlexColumnSB style={{ height: 320 }}>
            <div>
                <TitleSemiBoldG40>
                    {moment(time).format("h:mm:ss a")}
                </TitleSemiBoldG40>
                <SpacerTopBottomLarge />
                <NoteBlock
                    bgColor={"#F4F6F6"}
                    noteTitle="Pending tasks"
                />
            </div>
            <Button
                variant='contained'
                color="secondary"
                sx={{
                    textTransform: "none", 
                    backgroundColor: '#CC0062',
                    color: '#FFFFFF',
                    ':hover': {
                        bgcolor: '#AC0053',
                        color: '#FFFFFF',
                    },
                }}
                size='small'
                endIcon={<InfoOutlinedIcon />}>
                Neurons
            </Button>
        </FlexColumnSB>
    )
}

import { Button, Grid } from '@mui/material'
import React from 'react'
import { HomeMenuContainer } from '../style/home.styles'

export const HomeMenu = (props) => {

    const handleNavigate = (n) => {
        props.setMenuShow(false)
        props.navigate(`home/${n}`)
    }

    return (
        <>
            <HomeMenuContainer>
                <Grid container spacing={2}>
                    <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => handleNavigate("inventory")}>
                            Inventory
                        </Button>
                    </Grid>
                    <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={() => handleNavigate("meal-plan")}>
                            Meal Plan
                        </Button>
                    </Grid>
                </Grid>
            </HomeMenuContainer>
        </>
    )
}

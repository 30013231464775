import React, { useState } from 'react'
import { SpacerLeftRightSmall } from '../../design/spacer/spacer'
import { FlexBox, FlexBoxSB, PasswordContainer, PasswordShowContainer } from '../global.styles'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import { Tooltip } from '@mui/material';
import useWindowDimensions from './screenSize';
import { useEffect } from 'react';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';

export const PasswordTile = (props) => {

    const { width } = useWindowDimensions()

    const [hide, setHide] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setHide(true)
        }, 3000);
    }, [hide])

    const mobileShow = () => {
        setHide(false)
    }

    return (
        <FlexBoxSB>
            {
                hide
                    ?
                    <FlexBox>
                        {
                            [...props.value].map((item, index) => (
                                <div key={index}>
                                    <FiberManualRecordRoundedIcon
                                        sx={{
                                            fontSize: 8
                                        }}
                                    />
                                </div>
                            ))
                        }
                    </FlexBox>
                    :
                    <PasswordContainer>
                        {props.value}
                    </PasswordContainer>
            }
            <SpacerLeftRightSmall />
            <PasswordShowContainer>
                {
                    width > 850
                        ?
                        hide
                            ?
                            <Tooltip title="Show" placement='right' onMouseDown={() => setHide(false)} onClick={mobileShow}>
                                <VisibilityOffRoundedIcon
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                    fontSize='small'
                                />
                            </Tooltip>
                            :
                            <Tooltip title="Hide" placement='right' onMouseUp={() => setHide(true)} onClick={mobileShow}>
                                <VisibilityRoundedIcon
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                    fontSize='small'
                                />
                            </Tooltip>
                        :
                        hide
                            ?
                            <Tooltip title="Show" placement='right' onClick={mobileShow}>
                                <VisibilityOffRoundedIcon
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                    fontSize='small'
                                />
                            </Tooltip>
                            :
                            <Tooltip title="Hide" placement='right' onClick={mobileShow}>
                                <VisibilityRoundedIcon
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                    fontSize='small'
                                />
                            </Tooltip>
                }
            </PasswordShowContainer>
        </FlexBoxSB>
    )
}

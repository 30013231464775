import React from 'react'
import { Container, FlexBox, Sandwich } from '../../../global/global.styles'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import { MemberDetailsCard } from './memberDetailsCard';
import { SpacerLeftRightMedium } from '../../../design/spacer/spacer';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", transform: 'scale(2.5)', right: -40 }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", transform: 'scale(2.5)', left: -40 }}
            onClick={onClick}
        />
    );
}

export const MemberSlider = (props) => {

    const navigate = useNavigate()

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        arrows: true,
        speed: 500,
        slidesToShow: props.content?.length > 2 ? 2 : props.content?.length,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: props.content?.length > 2 ? 2 : props.content?.length,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: props.content?.length > 1 ? 1 : props.content?.length,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            }
        ]
    };


    return (
        <Sandwich>
            <FlexBox>
                {
                    props.width > 650
                    &&
                    <>
                        <MemberDetailsCard
                            width={props.width}
                            genderArr={props.genderArr}
                            allMembers={props.allMembers}
                            file={props.file}
                            onChangeFile={props.onChangeFile}
                            gender={props.gender}
                            onChangeGender={props.onChangeGender}
                            age={props.age}
                            onChangeAge={props.onChangeAge}
                            weight={props.weight}
                            onChangeWeight={props.onChangeWeight}
                            member={props.member}
                            onChangeMember={props.onChangeMember}
                            dietStyle={props.dietStyle}
                            onChangeDietStyle={props.onChangeDietStyle}
                            carbValue={props.carbValue}
                            onChangeCarbValue={props.onChangeCarbValue}
                            proteinValue={props.proteinValue}
                            onChangeProteinValue={props.onChangeProteinValue}
                            fatValue={props.fatValue}
                            onChangeFatValue={props.onChangeFatValue}
                            saveMember={props.saveMember}
                        />
                        <SpacerLeftRightMedium />
                        <Slider {...settings}>
                            {
                                props.content
                                &&
                                props.content?.map((item, index) => (
                                    <MemberDetailsCard
                                        key={index}
                                        width={props.width}
                                        users={props.users}
                                        content={props.content}
                                        genderArr={props.genderArr}
                                        allMembers={props.allMembers}
                                        resetAmbit={props.resetAmbit}
                                        file={props.file}
                                        onChangeFile={props.onChangeFile}
                                        gender={props.gender}
                                        onChangeGender={props.onChangeGender}
                                        age={props.age}
                                        onChangeAge={props.onChangeAge}
                                        weight={props.weight}
                                        onChangeWeight={props.onChangeWeight}
                                        member={props.member}
                                        onChangeMember={props.onChangeMember}
                                        dietStyle={props.dietStyle}
                                        onChangeDietStyle={props.onChangeDietStyle}
                                        carbValue={props.carbValue}
                                        onChangeCarbValue={props.onChangeCarbValue}
                                        proteinValue={props.proteinValue}
                                        onChangeProteinValue={props.onChangeProteinValue}
                                        fatValue={props.fatValue}
                                        onChangeFatValue={props.onChangeFatValue}
                                        specId={item.memberId}
                                        specGender={item.gender}
                                        specAge={item.age}
                                        specWeight={item.weight}
                                        specDietStyle={item.dietStyle}
                                        specNutrients={item.nutrientsRequired}
                                    />
                                ))
                            }
                        </Slider>
                    </>
                }
            </FlexBox>
        </Sandwich>
    )
}
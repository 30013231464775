// DEFAULT
import React, { useState } from 'react'
import Navbar from '../../global/components/navbar'
import { TimeHeader } from './components/timeHeader'
import { TimeSchedule } from './components/timeSchedule'
import { Backdrop, Box, Fade, Modal } from '@mui/material'

// MAIN
export const Time = (props) => {

    // ------------------------------ EVENTS ----------------------------------- //

    const [eventModal, setEventModal] = useState(false);

    const eventModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        borderRadius: '8px',
        backgroundColor: 'white',
        p: 4,
        overflowY: 'auto'
    };

    const openEventModal = () => {
        setEventModal(true)
    }

    return (
        <>
            <Navbar />
            <TimeHeader
                navigate={props.navigate}
            />
            <TimeSchedule
                eventClicked={openEventModal}
                taskClicked={() => props.navigate("/tasks")}
                routineClicked={() => props.navigate("/routine")}
            />
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={eventModal}
                onClose={() => setEventModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={eventModal}>
                    <Box sx={eventModalStyle}>

                    </Box>
                </Fade>
            </Modal>
        </>
    )
}
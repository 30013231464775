import React from 'react'
import { Container, Sandwich } from '../../../global/global.styles'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import { InvBlock } from './invBlock';


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", transform: 'scale(2.5)', right: -40 }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", transform: 'scale(2.5)', left: -40 }}
            onClick={onClick}
        />
    );
}



export const InvSlider = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        arrows: true,
        speed: 500,
        slidesToShow: Object.keys(props.content).length > 4 ? 4 : Object.keys(props.content).length,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: Object.keys(props.content).length > 3 ? 3 : Object.keys(props.content).length,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: Object.keys(props.content).length > 2 ? 2 : Object.keys(props.content).length,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    arrows: false,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: Object.keys(props.content).length > 1 ? 1 : Object.keys(props.content).length,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            }
        ]
    };


    return (
        <Sandwich>
            <Slider {...settings}>
                {
                    props.content
                    &&
                    props.content.map((item, index) => (
                        <InvBlock
                            key={index}
                            id={item.id}
                            location={props.location}
                            name={item.name}
                            dp={item.dp}
                            brand={item.brand}
                            price={item.price}
                            variantQty={item.variantQty}
                            variantUnit={item.variantUnit}
                            validity={item.validity}
                            itemType={item.itemType}
                            purchases={item.purchases}
                            width={props.width}
                        />
                    ))
                }
            </Slider>
        </Sandwich>
    )
}
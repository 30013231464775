import React from 'react'
import { NutrientBlockContainer } from '../style/home.styles'
import { FlexBoxSB, SubTitleBold, Title, TitleSemiBold } from '../../../global/global.styles'
import { TextField } from '@mui/material'

export const NutrientBlock = (props) => {
    return (
        <>
            <NutrientBlockContainer style={{ border: props.border, backgroundColor: props.bgColor }}>
                <FlexBoxSB>
                    <SubTitleBold style={{ color: props.color }}>
                        {props.label}
                    </SubTitleBold>
                    {
                        props.valuePresent
                            ?
                            <TitleSemiBold style={{color: props.color}}>
                                {props.valuePresent}
                            </TitleSemiBold>
                            :
                            <TextField
                                variant="standard"
                                id={props.value}
                                name={props.value}
                                type="number"
                                size='small'
                                autoComplete='off'
                                sx={{ width: 80 }}
                                value={props.value}
                                onChange={props.onChangeValue}
                                inputProps={{ style: { fontSize: '16px' } }}
                                placeholder={props.placeholderValue}
                            />
                    }
                </FlexBoxSB>
            </NutrientBlockContainer>
        </>
    )
}

import React from 'react'
import { SpacerTopBottomSmall } from '../../../design/spacer/spacer'
import { BodyMediumWhite, FlexBoxSA, SubBodyMediumWhite } from '../../../global/global.styles'
import { InspiriseLogo, InspiriseLogoHolder, LoginFooterContainer } from '../style/login.styles'
import inspirise from '../../../assets/images/Rectangle White.png'

export const LoginFooter = () => {
    return (
        <>
            <LoginFooterContainer>
                <FlexBoxSA>
                    <a href='http://www.inspirise.com/' target="_blank" style={{ textDecoration: 'none' }}>
                        <SubBodyMediumWhite style={{ color: '#EEDB00' }}>
                            Powered by:
                        </SubBodyMediumWhite>
                        <SpacerTopBottomSmall />
                        <InspiriseLogoHolder>
                            <InspiriseLogo src={inspirise} />
                        </InspiriseLogoHolder>
                    </a>
                </FlexBoxSA>
            </LoginFooterContainer>
        </>
    )
}

import React from 'react'
import { SearchResultsBlockContainer } from '../style/profile.styles'

export const SearchResultsBlock = (props) => {
    return (
        <>
            <SearchResultsBlockContainer>
                {props.familyId}
            </SearchResultsBlockContainer>
        </>
    )
}

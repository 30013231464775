import React from 'react'
import { FlexBoxSA } from '../../../global/global.styles'
import { Button } from '@mui/material'

export const MealPlanMenu = (props) => {
    return (
        <>
            <FlexBoxSA>
                <Button
                    variant='outlined'
                    onClick={props.createMealPlan}
                    sx={{
                        textTransform: "none"
                    }}>
                    Create meal plan
                </Button>
                <Button
                    variant='outlined'
                    onClick={props.memberDetails}
                    sx={{
                        textTransform: "none"
                    }}>
                    Member details
                </Button>
                <Button
                    variant='outlined'
                    onClick={props.addNewMeal}
                    sx={{
                        textTransform: "none"
                    }}>
                    Add new meal
                </Button>
                <Button
                    variant='outlined'
                    onClick={props.ingredientsTable}
                    sx={{
                        textTransform: "none"
                    }}>
                    Ingredients table
                </Button>
            </FlexBoxSA>
        </>
    )
}

import React from 'react'
import { FlexBox, FlexBoxSB } from '../../../global/global.styles'
import { Button } from '@mui/material'
import { SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerTopBottomLarge } from '../../../design/spacer/spacer'
import { MemberDetailsCard } from './memberDetailsCard'
import { MemberSlider } from './memberSlider'

export const MemberDetails = (props) => {
    return (
        <>
            {
                props.width < 650
                &&
                <>
                    <FlexBoxSB>
                        <Button
                            variant='outlined'
                            sx={{
                                textTransform: "none"
                            }}
                            size='small'>
                            Add new member
                        </Button>
                    </FlexBoxSB>
                    <SpacerTopBottomLarge />
                </>
            }
            {/* <FlexBox>
                {
                    props.mealMembers.map((item, index) => (
                        <MemberDetailsCard
                            key={index}
                            width={props.width}
                            users={props.users}
                            content={props.mealMembers}
                            genderArr={props.genderArr}
                            allMembers={props.allMembers}
                            resetAmbit={props.resetAmbit}
                            file={props.file}
                            onChangeFile={props.onChangeFile}
                            gender={props.gender}
                            onChangeGender={props.onChangeGender}
                            age={props.age}
                            onChangeAge={props.onChangeAge}
                            weight={props.weight}
                            onChangeWeight={props.onChangeWeight}
                            member={props.member}
                            onChangeMember={props.onChangeMember}
                            dietStyle={props.dietStyle}
                            onChangeDietStyle={props.onChangeDietStyle}
                            carbValue={props.carbValue}
                            onChangeCarbValue={props.onChangeCarbValue}
                            proteinValue={props.proteinValue}
                            onChangeProteinValue={props.onChangeProteinValue}
                            fatValue={props.fatValue}
                            onChangeFatValue={props.onChangeFatValue}
                        />
                    ))
                }
            </FlexBox> */}
            <MemberSlider
                width={props.width}
                content={props.mealMembers}
                users={props.users}
                genderArr={props.genderArr}
                allMembers={props.allMembers}
                resetAmbit={props.resetAmbit}
                file={props.file}
                onChangeFile={props.onChangeFile}
                gender={props.gender}
                onChangeGender={props.onChangeGender}
                age={props.age}
                onChangeAge={props.onChangeAge}
                weight={props.weight}
                onChangeWeight={props.onChangeWeight}
                member={props.member}
                onChangeMember={props.onChangeMember}
                dietStyle={props.dietStyle}
                onChangeDietStyle={props.onChangeDietStyle}
                carbValue={props.carbValue}
                onChangeCarbValue={props.onChangeCarbValue}
                proteinValue={props.proteinValue}
                onChangeProteinValue={props.onChangeProteinValue}
                fatValue={props.fatValue}
                onChangeFatValue={props.onChangeFatValue}
                saveMember={props.saveMember}
            />
        </>
    )
}

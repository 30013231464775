import React from 'react'
import { Container } from '../../../global/global.styles'

export const Schedule = (props) => {
  return (
    <>
        <Container>
            
        </Container>
    </>
  )
}

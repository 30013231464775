export const fonts = {
  // heading: "Oswald_400Regular",
  // body: "Lato_400Regular",
  // bodyBold: "Lato_700Bold",
  // monospace: "Oswald_400Regular",
  // number: "Lato_400Regular",
};

export const fontWeights = {
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

export const fontSizes = {
  headline: "72px",
  subHeadline: "56px",
  childHeadline: "48px",
  heading: "32px",
  subHeading: "24px",
  title: "20px",
  subTitle: "16px",
  body: "12px",
  subBody: "10px",
  caption: "8px",
  subCaption: "4px",
};
import React, { useContext, useState } from 'react'
import { AlignCenter, FlexBoxSA, FlexBoxSB, MagnifyIcon } from '../../../global/global.styles';
import { SpacerLeftRightMedium } from '../../../design/spacer/spacer';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import moment from 'moment/moment';
import { NumericFormat } from 'react-number-format';
import { Backdrop, Box, Fade, Modal, Tooltip } from '@mui/material';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import { AuthContext } from '../../../context/auth/auth.provider';
import { UpdateContext } from '../../../context/update/update.provider';
import { DeleteAlert } from '../../../global/components/deleteAlert';
import { NewPurchaseForm } from './newPurchaseForm';

const units = [
    {
        value: 'gm',
        label: 'gm',
    },
    {
        value: 'kg',
        label: 'kg',
    },
    {
        value: 'ml',
        label: 'ml',
    },
    {
        value: 'l',
        label: 'l',
    },
];

const medium = [
    {
        value: 'Online',
        label: 'Online',
    },
    {
        value: 'Retail',
        label: 'Retail',
    },
    {
        value: 'Distributor',
        label: 'Distributor',
    },
    {
        value: 'Manufacturer',
        label: 'Manufacturer',
    },
];

const paymentModeArr = [
    {
        value: 'UPI',
        label: 'UPI',
    },
    {
        value: 'Cash',
        label: 'Cash',
    },
    {
        value: 'Card',
        label: 'Card',
    },
    {
        value: 'Other',
        label: 'Other',
    },
];

export const InvItemDetailsTbody = (props) => {

    const { user } = useContext(AuthContext);
    const { refresh, setRefresh } = useContext(UpdateContext);

    const itemDetailsModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        borderRadius: '8px',
        backgroundColor: 'white',
        p: 4,
        // width: props.width > 450 ? '400px' : props.width > 350 ? '320px' : '280px',
        // height: props.width > 850 ? 'inherit' : props.width > 650 ? '40vh' : props.width > 370 ? '48vh' : '60vh',
        overflowY: 'auto',
        width: '70%',
        height: '70%'
    };

    const itemModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        borderRadius: '8px',
        backgroundColor: 'white',
        p: props.width > 450 ? 4 : 3,
        overflowY: 'auto'
    };

    const [itemDetailsModal, setItemDetailsModal] = useState(false);
    const [editPurchaseModal, setEditPurchaseModal] = useState(false);

    const handleEditPurchaseClicked = () => {
        setEditPurchaseModal(true)
    }

    const handleCancelEdit = () => {
        setEditPurchaseModal(false)
    }

    const [itemModal, setItemModal] = useState(false);
    const [price, setPrice] = useState("");
    const [sourceType, setSourceType] = useState("");
    const [sourceName, setSourceName] = useState("");
    const [paymentMode, setPaymentMode] = useState("");
    const [discount, setDiscount] = useState("");
    const [qty, setQty] = useState("");
    const [wQty, setWQty] = useState("");
    const [startDate, setStartDate] = useState("");
    const [mfdDate, setMfdDate] = useState("");
    const [file, setFile] = useState("");
    const [endDate, setEndDate] = useState("");
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [loading, setLoading] = useState("");
    const [progress, setProgress] = useState("");

    const handleUpdate = async () => {

        const purchaseObj = {
            price: props.price,
            sourceType: props.sourceType,
            sourceName: props.sourceName,
            paymentMode: props.paymentMode,
            discount: props.discount,
            qty: props.qty,
            wQty: props.wQty,
            startDate: props.startDate,
            endDate: endDate,
            mfdDate: props.mfdDate,
            createdAt: props.createdAt
        }

        const filteredPurchases = props.purchases?.filter((x) => x.createdAt !== props.createdAt)

        const updatedPurchases = [...filteredPurchases, purchaseObj]

        const updateData = {
            updatedAt: serverTimestamp(),
            purchases: updatedPurchases
        }

        const docRef = doc(db, "inventory", props.id);
        await setDoc(docRef, updateData, {
            merge: true
        }).then(() => console.log("Document updated"));

        setRefresh(!refresh);
        setLoading(false);
        setEndDate("")
    }

    const handleEdit = async () => {
        setLoading(true);

        const purchaseObj = {
            price: price ? price : props.price,
            sourceType: sourceType ? sourceType : props.sourceType,
            sourceName: sourceName ? sourceName : props.sourceName,
            paymentMode: paymentMode ? paymentMode : props.paymentMode,
            discount: discount ? discount : props.discount,
            qty: qty ? qty : props.qty,
            wQty: wQty ? wQty : props.wQty,
            startDate: startDate ? startDate : props.startDate,
            endDate: endDate ? endDate : props.endDate ? props.endDate : "",
            mfdDate: mfdDate ? mfdDate : props.mfdDate,
            createdAt: new Date()
        }

        const filteredPurchases = props.purchases?.filter((x) => x.createdAt !== props.createdAt)

        const updatedPurchases = [...filteredPurchases, purchaseObj]

        const updateData = {
            keywords: `${props.name},${props.brand},${props.itemType},${sourceName},${sourceType},${paymentMode}`,
            updatedAt: serverTimestamp(),
            purchases: updatedPurchases
        }

        const docRef = doc(db, "inventory", props.id);
        await setDoc(docRef, updateData, {
            merge: true
        }).then(() => console.log("Document updated"));

        setRefresh(!refresh);
        setPrice("");
        setSourceType("");
        setSourceName("");
        setPaymentMode("");
        setDiscount("");
        setQty("")
        setStartDate("")
        setEndDate("")
        setMfdDate("")
        setLoading(false);
        setEditPurchaseModal(false)
    }

    const handleDelete = async () => {
        const filteredPurchases = props.purchases?.filter((x) => x.createdAt !== props.createdAt)
        const updateData = {
            updatedAt: serverTimestamp(),
            purchases: filteredPurchases
        }

        const docRef = doc(db, "inventory", props.id);
        await setDoc(docRef, updateData, {
            merge: true
        }).then(() => console.log("Document updated"));

        setRefresh(!refresh);
        setDeleteAlert(false)
    }

    const handleSubmit = () => {
        handleEdit()
    }



    return (
        <>
            <tr>
                <td style={{
                    padding: '16px 8px',
                    fontSize: 14,
                    border: '1px solid grey'
                }}>
                    <AlignCenter>
                        {
                            props.createdAt
                                ?
                                moment(props.createdAt.toDate().toDateString()).format("DD/MM/YY")
                                :
                                "-"
                        }
                    </AlignCenter>
                </td>
                <td style={{
                    padding: '16px 8px',
                    fontSize: 14,
                    border: '1px solid grey'
                }}>
                    {props.sourceName ? props.sourceName : <AlignCenter> - </AlignCenter>}
                </td>
                <td style={{
                    padding: '16px 8px',
                    fontSize: 14,
                    border: '1px solid grey'
                }}>
                    <AlignCenter>
                        {props.qty ? props.qty : "-"}
                    </AlignCenter>
                </td>
                <td style={{
                    padding: '16px 8px',
                    fontSize: 14,
                    border: '1px solid grey'
                }}>
                    <AlignCenter>
                        {
                            props.price
                                ?
                                <NumericFormat
                                    value={props.price}
                                    decimalScale={3}
                                    prefix="₹"
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                                :
                                "-"
                        }
                    </AlignCenter>
                </td>
                <td style={{
                    padding: '16px 8px',
                    fontSize: 14,
                    border: '1px solid grey'
                }}>
                    <AlignCenter>
                        {
                            props.price
                                ?
                                <NumericFormat
                                    value={props.price*props.qty}
                                    decimalScale={3}
                                    prefix="₹"
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                                :
                                "-"
                        }
                    </AlignCenter>
                </td>
                <td style={{
                    padding: '16px 8px',
                    fontSize: 14,
                    border: '1px solid grey'
                }}>
                    <AlignCenter>
                        {
                            props.price
                                ?
                                props.itemType === "packaged"
                                    ?
                                    <NumericFormat
                                        value={parseFloat(props.price) / parseFloat(props.variantQty)}
                                        decimalScale={3}
                                        prefix="₹"
                                        displayType={'text'}
                                        thousandSeparator={true}
                                    />
                                    :
                                    props.itemType === "weighted"
                                    &&
                                    <NumericFormat
                                        value={parseFloat(props.price) / parseFloat(props.wQty)}
                                        decimalScale={3}
                                        prefix="₹"
                                        displayType={'text'}
                                        thousandSeparator={true}
                                    />
                                :
                                "-"
                        }
                    </AlignCenter>
                </td>
                <td style={{
                    padding: '16px 8px',
                    fontSize: 14,
                    border: '1px solid grey'
                }}>
                    <AlignCenter>
                        {
                            props.discount
                                ?
                                <NumericFormat
                                    value={props.discount}
                                    decimalScale={3}
                                    suffix="%"
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                                :
                                "-"
                        }
                    </AlignCenter>
                </td>
                <td style={{
                    padding: '16px 8px',
                    fontSize: 14,
                    border: '1px solid grey'
                }}>
                    <AlignCenter>
                        {
                            props.paymentMode
                                ?
                                props.paymentMode
                                :
                                "-"
                        }
                    </AlignCenter>
                </td>
                <td style={{
                    padding: '16px 8px',
                    fontSize: 14,
                    border: '1px solid grey'
                }}>
                    <AlignCenter>
                        {
                            props.startDate
                                ?
                                moment(props.startDate).format("DD/MM/YY")
                                :
                                "-"
                        }
                    </AlignCenter>
                </td>
                <td style={{
                    padding: '16px 8px',
                    fontSize: 14,
                    border: '1px solid grey'
                }}>
                    <AlignCenter>
                        {
                            props.endDate
                                ?
                                props.endDate && moment(props.endDate).format("DD/MM/YY")
                                :
                                <input
                                    type='date'
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    style={{
                                        maxWidth: 96
                                    }}
                                />
                        }
                    </AlignCenter>
                </td>
                <td style={{
                    padding: '16px 8px',
                    fontSize: 14,
                    border: '1px solid grey'
                }}>
                    <FlexBoxSB>
                        {
                            endDate
                                ?
                                <>
                                    <Tooltip title="Save" placement='right' onClick={handleUpdate}>
                                        <MagnifyIcon>
                                            <CheckCircleRoundedIcon fontSize='small' color="success" sx={{ cursor: "pointer" }} />
                                        </MagnifyIcon>
                                    </Tooltip>
                                    <SpacerLeftRightMedium />
                                </>
                                :
                                <>
                                    <CheckCircleOutlineRoundedIcon fontSize='small' color="disabled" sx={{ cursor: "pointer" }} />
                                    <SpacerLeftRightMedium />
                                </>
                        }
                        <Tooltip title="Edit" placement='right' onClick={handleEditPurchaseClicked}>
                            <MagnifyIcon>
                                <BorderColorRoundedIcon fontSize='small' color="secondary" sx={{ cursor: "pointer" }} />
                            </MagnifyIcon>
                        </Tooltip>
                        <SpacerLeftRightMedium />
                        <Tooltip title="Delete" placement='right' onClick={() => setDeleteAlert(true)}>
                            <MagnifyIcon>
                                <DeleteRoundedIcon fontSize='small' color="error" sx={{ cursor: "pointer" }} />
                            </MagnifyIcon>
                        </Tooltip>
                    </FlexBoxSB>
                </td>
            </tr>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={deleteAlert}
                onClose={() => setDeleteAlert(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={deleteAlert}>
                    <Box sx={itemModalStyle}>
                        <DeleteAlert
                            handleCancel={() => setDeleteAlert(false)}
                            handleDelete={handleDelete}
                        />
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={editPurchaseModal}
                // onClose={() => setEditPurchaseModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={editPurchaseModal}>
                    <Box sx={itemModalStyle}>
                        <NewPurchaseForm
                            scope="edit"
                            itemType={props.itemType}
                            units={units}
                            medium={medium}
                            paymentModeArr={paymentModeArr}
                            price={price}
                            onChangePrice={(e) => setPrice(e.target.value)}
                            placeholderPrice={props.price}
                            onChangeSourceType={(e) => setSourceType(e.target.value)}
                            placeholderSourceType={props.sourceType}
                            sourceName={sourceName}
                            onChangeSourceName={(e) => setSourceName(e.target.value)}
                            placeholderSourceName={props.sourceName}
                            paymentMode={paymentMode}
                            onChangePaymentMode={(e) => setPaymentMode(e.target.value)}
                            discount={discount}
                            placeholderDiscount={props.discount}
                            onChangeDiscount={(e) => setDiscount(e.target.value)}
                            qty={qty}
                            onChangeQty={(e) => setQty(e.target.value)}
                            placeholderQty={props.qty}
                            wQty={wQty}
                            onChangeWQty={(e) => setWQty(e.target.value)}
                            placeholderWQty={props.wQty}
                            startDate={startDate}
                            onChangeStartDate={(e) => setStartDate(e.target.value)}
                            mfdDate={mfdDate}
                            onChangeMfdDate={(e) => setMfdDate(e.target.value)}
                            endDate={endDate}
                            onChangeEndDate={(e) => setEndDate(e.target.value)}
                            file={file}
                            onChangeFile={(e) => setFile(e.target.files[0])}
                            deleteDP={() => setFile()}
                            btnColor={"success"}
                            loading={loading}
                            handleSave={handleSubmit}
                            handleCancel={handleCancelEdit}
                            btnTitle={"Save"}
                            width={props.width}
                        />
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

import React from 'react'
import { SourceBlockContainer, SourceBlockWrapper } from '../style/home.styles'
import { AlignCenter, BodySemiBold, FlexBoxSA, SubBodyBold, SubTitle, SubTitleSemiBold, TitleSemiBold } from '../../../global/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import retailIcon from '../../../assets/images/retail.png'
import { SpacerTopBottomLarge, SpacerTopBottomMedium } from '../../../design/spacer/spacer'
import { NumericFormat } from 'react-number-format'

export const SourceBlock = (props) => {

    return (
        <SourceBlockWrapper>
            <SourceBlockContainer>
                <FlexBoxSA>
                    <ImgHolder
                        img={retailIcon}
                        width={56}
                        height={56}
                    />
                </FlexBoxSA>
                <SpacerTopBottomLarge />
                <AlignCenter>
                    <SubBodyBold>
                        {props.sourceName}
                    </SubBodyBold>
                    <SpacerTopBottomMedium />
                    <TitleSemiBold>
                        <NumericFormat
                            value={
                                props.purchases.filter((x) => x.sourceName === props.sourceName).sort(function (a, b) {
                                    return new Date(b.date) - new Date(a.date);
                                }).slice(-1)[0].price
                            }
                            decimalScale={2}
                            prefix="₹"
                            displayType={'text'}
                            thousandSeparator={true}
                        />
                    </TitleSemiBold>
                </AlignCenter>
            </SourceBlockContainer>
        </SourceBlockWrapper>
    )
}

// DEFAULT
import React, { useState } from 'react'
import Navbar from '../../global/components/navbar'
import { Outlet } from 'react-router-dom'
import { FlexBoxSA } from '../../global/global.styles'
import { Button } from '@mui/material'
import { HomeMenu } from './components/homeMenu'

// INTERFACE


// MAIN
export const Home = (props) => {

    const [menuShow, setMenuShow] = useState(false)

    return (
        <>
            <Navbar />
            <FlexBoxSA>
                <Button
                    variant='contained'
                    onClick={() => setMenuShow(!menuShow)}>
                    Menu
                </Button>
            </FlexBoxSA>
            {
                menuShow
                &&
                <HomeMenu
                    navigate={props.navigate}
                    menuShow={menuShow}
                    setMenuShow={setMenuShow}
                />
            }
            <Outlet />
        </>
    )
}
import styled from 'styled-components'

// ----------------------------------------Containers----------------------------------------//

export const TemplateStyle = styled.div`
    @media only screen and (min-width: 1800px) {

    }
    @media only screen and (max-width: 1300px) {
        
    }
    @media only screen and (max-width: 1100px) {
        
    }
    @media only screen and (max-width: 850px) {
        
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 370px) {

    }
`;

export const ArchiveHeaderWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.bg.grey};
    position: relative;
`;

export const ArchiveHeaderContainer = styled.div`
    padding-top: ${(props) => props.theme.space[7]};
    padding-bottom: ${(props) => props.theme.space[7]};
    padding-left: ${(props) => props.theme.space[16]};
    padding-right: ${(props) => props.theme.space[16]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[30]};
        padding-right: ${(props) => props.theme.space[30]};
    }
    @media only screen and (max-width: 1300px) {

    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }
    @media only screen and (max-width: 850px) {

    }
    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }

    @media only screen and (max-width: 450px) {
        
    }

    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const ArchiveActionBtnContainer = styled.div`
    background-color: ${(props) => props.theme.colors.bg.white};
    border-radius: 16px;
    padding: 16px;
    width: 200px;
    box-shadow: 0px 0px 2px 1px rgba(184,184,184,0.75);
-webkit-box-shadow: 0px 0px 2px 1px rgba(184,184,184,0.75);
-moz-box-shadow: 0px 0px 2px 1px rgba(184,184,184,0.75);
    cursor: pointer;

    &:hover {
        transform: scale(1.02);
        transition: all 0.3s ease;
        box-shadow: 0px 0px 4px 2px rgba(184,184,184,0.75);
-webkit-box-shadow: 0px 0px 4px 2px rgba(184,184,184,0.75);
-moz-box-shadow: 0px 0px 4px 2px rgba(184,184,184,0.75);
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.3s ease;
    }

    @media only screen and (max-width: 450px) {
        padding: 16px 8px;
    }
`;

export const CredBlockContainer = styled.div`
    background-color: ${(props) => props.theme.colors.bg.white};
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 4px 8px 2px rgba(212,212,212,0.75);
-webkit-box-shadow: 0px 4px 8px 2px rgba(212,212,212,0.75);
-moz-box-shadow: 0px 4px 8px 2px rgba(212,212,212,0.75);
    position: relative;
    min-width: 256px; 
`;

export const ArchiveBlockContainer = styled.div`
    float: left;
    padding: 16px 32px 16px 0;

    @media only screen and (max-width: 850px) {
        float: none;
        margin: 0 auto;
        padding: 16px;
    }

    @media only screen and (max-width: 650px) {
        padding: 16px 0;
    }
`;

export const UploadButton = styled.div`
    border: 1px solid #2998FF;
    padding: 10px 0;
    border-radius: 4px;
    cursor: pointer;
    
    &:hover {
        background-color: #D3EAFF;
    }
    &:not(:hover) {
        transition: all 0.3s ease;
    }
`;

export const DocBlockContainer = styled.div`
    background-color: ${(props) => props.theme.colors.bg.white};
    border-radius: 8px;
    position: relative;
    box-shadow: 0px 4px 8px 2px rgba(212,212,212,0.75);
-webkit-box-shadow: 0px 4px 8px 2px rgba(212,212,212,0.75);
-moz-box-shadow: 0px 4px 8px 2px rgba(212,212,212,0.75);
    min-height: 260px;
`;

export const DocImgHolder = styled.div`
    border-radius: 8px;
    width: 200px;
`;

export const DocImg = styled.img`
    border-radius: 8px;
    width: 100%;
    height: 100%;
`;

export const InfoSlotContainer = styled.div`
    padding: 16px;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: absolute;
    bottom: 0;
    right: 0;
    box-shadow: 0px 4px 8px 2px rgba(212,212,212,0.75);
-webkit-box-shadow: 0px 4px 8px 2px rgba(212,212,212,0.75);
-moz-box-shadow: 0px 4px 8px 2px rgba(212,212,212,0.75);
`;

export const CategoryContainer = styled.div`
    padding-top: ${(props) => props.theme.space[1]};
    padding-bottom: ${(props) => props.theme.space[1]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    border-radius: 16px;
    background-color: #3B9C8B;
`;

export const FileDisplayHolder = styled.div`
    width: 200px;
`;

export const FileDisplay = styled.img`
    width: 100%;
    height: 100%;
`;
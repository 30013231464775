import React from 'react'
import { TaskCorrelateBlockContainer } from '../style/dashboard.styles'
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall } from '../../../design/spacer/spacer'
import { Divider } from '@mui/material'

export const TaskCorrelateBlock = (props) => {
    return (
        <>
            <TaskCorrelateBlockContainer>
                <h3>
                    {props.heading}
                </h3>
                <SpacerTopBottomMedium>
                    <Divider />
                </SpacerTopBottomMedium>
                {
                    props.corrArr.filter((x) => x.correlate === props.heading).map((item, index) => (
                        <div key={index}>
                            {item.title}
                            <SpacerTopBottomMedium />
                        </div>
                    ))
                }
            </TaskCorrelateBlockContainer>
        </>
    )
}

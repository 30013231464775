import React from 'react'
import styled from 'styled-components';

export const IconHolder = (props) => {
    return (
        <>
            <IconHolderContainer
                style={{ width: props.width, height: props.height }}
                onClick={props.clicked}>
                <Icon src={props.icon} />
            </IconHolderContainer>
        </>
    )
}

const IconHolderContainer = styled.div`
    cursor: pointer;
    &:hover {
        transform: scale(1.06);
        transition: all 0.3s ease;
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.3s ease;
    }
`;

const Icon = styled.img`
    width: 100%;
    height: 100%;
`;

import React from 'react'
import { NoteBlockContainer } from '../style/time.styles'
import { BodySemiBold, FlexBoxSB } from '../../../global/global.styles'
import { SpacerTopBottomSmall } from '../../../design/spacer/spacer'
import { Divider } from '../../../global/components/divider'
import { Tooltip } from '@mui/material'
import { IconHolder } from '../../../global/components/iconHolder'
import expandIcon from '../../../assets/images/expand.png'

export const NoteBlock = (props) => {
    return (
        <>
            <NoteBlockContainer style={{ backgroundColor: props.bgColor }}>
                <FlexBoxSB>
                <BodySemiBold>
                    {props.noteTitle}
                </BodySemiBold>
                    <Tooltip>
                        <IconHolder 
                            icon={expandIcon}
                            width={16}
                            height={16}
                            clicked={props.iconClicked}
                        />
                    </Tooltip>
                </FlexBoxSB>
                <SpacerTopBottomSmall>
                    <Divider 
                        width={"100%"}
                        height={1}
                        color={"#C4C4C4"}
                    />
                </SpacerTopBottomSmall>
            </NoteBlockContainer>
        </>
    )
}

import React from 'react'
import { AlignCenter, FlexBox, FlexBoxSA, FlexColumnSB, Heading, Headline, Title } from '../../../global/global.styles'
import { SpacerBottomSmall, SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerLeftRightXXL, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import { TimeframeBlock } from './timeframeBlock'
import { Button, Grid } from '@mui/material'
import moment from 'moment'
import { DayMealEntry } from './dayMealEntry'
import { useState } from 'react'

export const CreateMealPlan = (props) => {

    const today = new Date()

    const nextDay = (x) => {
        const now = new Date()
        now.setDate(now.getDate() + (x + (7 - now.getDay())) % 7);
        return now;
    }

    const handleNextAfterTimeFrame = (o) => {
        props.setTimeframe(o)
        props.setFormPart(2)
    }

    const numOfDays = (ref) => {

        if (ref === "today" || ref === "tomorrow") {
            return 1
        } else if (ref === "thisWeek") {
            var date1 = new Date();
            var date2 = new Date(nextDay(0));

            var Difference_In_Time = date2.getTime() - date1.getTime();
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

            return Difference_In_Days
        } else if (ref === "nextWeek") {
            return 7
        }
    }

    return (
        <>
            {
                props.formPart === 1
                    ?
                    <>
                        <FlexBoxSA>
                            <Title>
                                Choose timeframe
                            </Title>
                        </FlexBoxSA>
                        <SpacerTopBottomXXXL />
                        <FlexBoxSA>
                            <Grid container spacing={2}>
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FlexBox style={{ justifyContent: "end" }}>
                                        <TimeframeBlock
                                            orientation="vertical"
                                            title="Today"
                                            dateNum={moment(today).format("DD")}
                                            dateMon={moment(today).format("MMM")}
                                            timeframe={props.timeframe}
                                            timeframeClicked={() => handleNextAfterTimeFrame("today")}
                                            active={props.timeframe === "today"}
                                        />
                                        <SpacerLeftRightMedium />
                                        <TimeframeBlock
                                            orientation="vertical"
                                            title="Tomorrow"
                                            dateNum={moment(today.setDate(today.getDate() + 1)).format("DD")}
                                            dateMon={moment(today.setDate(today.getDate() + 1)).format("MMM")}
                                            timeframe={props.timeframe}
                                            timeframeClicked={() => handleNextAfterTimeFrame("tomorrow")}
                                            active={props.timeframe === "tomorrow"}
                                        />
                                    </FlexBox>
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <TimeframeBlock
                                        orientation="horizontal"
                                        title="Till end of week"
                                        dateFromNum={moment(new Date()).format("DD")}
                                        dateFromMon={moment(new Date()).format("MMM")}
                                        dateToNum={moment(nextDay(0)).format("DD")}
                                        dateToMon={moment(nextDay(0)).format("MMM")}
                                        timeframe={props.timeframe}
                                        timeframeClicked={() => handleNextAfterTimeFrame("thisWeek")}
                                        active={props.timeframe === "thisWeek"}
                                    />
                                    <SpacerTopBottomLarge />
                                    <TimeframeBlock
                                        orientation="horizontal"
                                        title="Next week"
                                        dateFromNum={moment().day(1 + 7).format("DD")}
                                        dateFromMon={moment().day(1 + 7).format("MMM")}
                                        dateToNum={moment().day(7 + 7).format("DD")}
                                        dateToMon={moment().day(7 + 7).format("MMM")}
                                        timeframe={props.timeframe}
                                        timeframeClicked={() => handleNextAfterTimeFrame("nextWeek")}
                                        active={props.timeframe === "nextWeek"}
                                    />
                                </Grid>
                            </Grid>
                        </FlexBoxSA>
                        <SpacerTopBottomXXXL />
                        <FlexBoxSA>
                            <FlexBox>
                                <Button
                                    variant='outlined'
                                    color='error'
                                    sx={{ textTransform: "none", width: 80 }}
                                    onClick={props.cancelCreateMealPlanModal}>
                                    Cancel
                                </Button>
                                {/* <SpacerLeftRightMedium />
                                <Button
                                    variant='contained'
                                    sx={{ textTransform: "none", width: 80 }}
                                    disabled={props.timeframe === "" ? true : false}
                                    onClick={() => props.setFormPart(2)}>
                                    Next
                                </Button> */}
                            </FlexBox>
                        </FlexBoxSA>
                    </>
                    :
                    props.formPart === 2
                        ?
                        <>
                            <FlexBoxSA>
                                <Title>
                                    Choose Meals
                                </Title>
                            </FlexBoxSA>
                            <SpacerTopBottomXXXL />
                            <Grid container spacing={3}>
                                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                                    <FlexColumnSB style={{ height: "100%" }}>
                                        <FlexBoxSA>
                                            {
                                                props.timeframe === "today"
                                                    ?
                                                    <>
                                                        <AlignCenter>
                                                            <Headline>
                                                                {moment(today).format("DD")}
                                                            </Headline>
                                                            <SpacerBottomSmall />
                                                            <Heading>
                                                                {moment(today).format("MMM")}
                                                            </Heading>
                                                        </AlignCenter>
                                                    </>
                                                    :
                                                    props.timeframe === "tomorrow"
                                                        ?
                                                        <>
                                                            <AlignCenter>
                                                                <Headline>
                                                                    {moment(today.setDate(today.getDate() + 1)).format("DD")}
                                                                </Headline>
                                                                <SpacerBottomSmall />
                                                                <Heading>
                                                                    {moment(today.setDate(today.getDate() + 1)).format("MMM")}
                                                                </Heading>
                                                            </AlignCenter>
                                                        </>
                                                        :
                                                        props.timeframe === "thisWeek"
                                                            ?
                                                            <FlexBox>
                                                                <AlignCenter>
                                                                    <Headline>
                                                                        {moment(new Date()).format("DD")}
                                                                    </Headline>
                                                                    <SpacerBottomSmall />
                                                                    <Heading>
                                                                        {moment(new Date()).format("MMM")}
                                                                    </Heading>
                                                                </AlignCenter>
                                                                <SpacerLeftRightXXL>
                                                                    <AlignCenter>
                                                                        <Headline>
                                                                            -
                                                                        </Headline>
                                                                    </AlignCenter>
                                                                </SpacerLeftRightXXL>
                                                                <AlignCenter>
                                                                    <Headline>
                                                                        {moment(nextDay(0)).format("DD")}
                                                                    </Headline>
                                                                    <SpacerBottomSmall />
                                                                    <Heading>
                                                                        {moment(nextDay(0)).format("MMM")}
                                                                    </Heading>
                                                                </AlignCenter>
                                                            </FlexBox>
                                                            :
                                                            props.timeframe === "nextWeek"
                                                            &&
                                                            <FlexBox>
                                                                <AlignCenter>
                                                                    <Headline>
                                                                        {moment().day(1 + 7).format("DD")}
                                                                    </Headline>
                                                                    <SpacerBottomSmall />
                                                                    <Heading>
                                                                        {moment().day(1 + 7).format("MMM")}
                                                                    </Heading>
                                                                </AlignCenter>
                                                                <SpacerLeftRightXXL>
                                                                    <AlignCenter>
                                                                        <Headline>
                                                                            -
                                                                        </Headline>
                                                                    </AlignCenter>
                                                                </SpacerLeftRightXXL>
                                                                <AlignCenter>
                                                                    <Headline>
                                                                        {moment().day(7 + 7).format("DD")}
                                                                    </Headline>
                                                                    <SpacerBottomSmall />
                                                                    <Heading>
                                                                        {moment().day(7 + 7).format("MMM")}
                                                                    </Heading>
                                                                </AlignCenter>
                                                            </FlexBox>
                                            }
                                        </FlexBoxSA>
                                        <div>
                                            <FlexBoxSA>
                                                <FlexBox>
                                                    <Button
                                                        variant='outlined'
                                                        sx={{ textTransform: "none", width: 96 }}
                                                        onClick={() => props.setFormPart(1)}>
                                                        Back
                                                    </Button>
                                                    <SpacerLeftRightSmall />
                                                    {/* <Button
                                                        variant='contained'
                                                        color='info'
                                                        sx={{ textTransform: "none", width: 96 }}
                                                        disabled={props.timeframe === "" ? true : false}
                                                        onClick={() => props.setFormPart(3)}>
                                                        Next
                                                    </Button> */}
                                                    <Button
                                                        variant='contained'
                                                        color='info'
                                                        sx={{ textTransform: "none", width: 96 }}
                                                        disabled={props.finishValidation}
                                                        onClick={props.handleSubmit}>
                                                        Finish
                                                    </Button>
                                                </FlexBox>
                                            </FlexBoxSA>
                                            <SpacerTopBottomMedium />
                                            <FlexBoxSA>
                                                <FlexBox>
                                                    <Button
                                                        variant='outlined'
                                                        color="error"
                                                        sx={{ textTransform: "none", width: 96 }}
                                                        onClick={props.cancelCreateMealPlanModal}>
                                                        Cancel
                                                    </Button>
                                                    <SpacerLeftRightSmall />
                                                    <Button
                                                        variant='outlined'
                                                        color='warning'
                                                        sx={{ textTransform: "none", width: 96 }}
                                                        disabled={props.timeframe === "" ? true : false}
                                                        onClick={() => props.setFormPart(3)}
                                                        disabled>
                                                        Save
                                                    </Button>
                                                </FlexBox>
                                            </FlexBoxSA>
                                        </div>
                                    </FlexColumnSB>
                                </Grid>
                                <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                                    <div style={{ height: 500, overflowY: "auto", padding: "16px 16px 0 16px", backgroundColor: "white" }}>
                                        {new Array(numOfDays(props.timeframe)).fill("").map((item, index) => (
                                            <div key={index}>
                                                <DayMealEntry
                                                    index={index}
                                                    mealMembers={props.mealMembers}
                                                    meals={props.meals}
                                                    users={props.users}
                                                    day={
                                                        props.timeframe === "today"
                                                            ?
                                                            moment(new Date()).add(index, 'days').format("DD-MMM-YY")
                                                            :
                                                            props.timeframe === "nextWeek"
                                                                ?
                                                                moment().day(1 + 7).add(index, 'days').format("DD-MMM-YY")
                                                                :
                                                                moment(new Date()).add(index + 1, 'days').format("DD-MMM-YY")
                                                    }
                                                    mealLog={props.mealLog}
                                                    setMealLog={props.setMealLog}
                                                    mealsList={props.mealsList}
                                                    setMealsList={props.setMealsList}
                                                    finishValidation={props.finishValidation}
                                                    setFinishValidation={props.setFinishValidation}
                                                />
                                                <SpacerTopBottomLarge />
                                            </div>
                                        ))}
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                        :
                        props.formPart === 3
                        &&
                        <>
                            <FlexBoxSA>
                                <FlexBox>
                                    <Button
                                        variant='outlined'
                                        color="error"
                                        sx={{ textTransform: "none", width: 96 }}
                                        onClick={props.cancelCreateMealPlanModal}>
                                        Cancel
                                    </Button>
                                    <SpacerLeftRightSmall />
                                    <Button
                                        variant='outlined'
                                        sx={{ textTransform: "none", width: 96 }}
                                        onClick={() => props.setFormPart(2)}>
                                        Back
                                    </Button>
                                    <SpacerLeftRightSmall />
                                    <Button
                                        variant='outlined'
                                        color='warning'
                                        sx={{ textTransform: "none", width: 96 }}
                                        disabled={props.timeframe === "" ? true : false}
                                        onClick={() => props.setFormPart(3)}
                                        disabled>
                                        Save
                                    </Button>
                                    <SpacerLeftRightSmall />
                                    <Button
                                        variant='contained'
                                        color='info'
                                        sx={{ textTransform: "none", width: 96 }}
                                        disabled={props.timeframe === "" ? true : false}
                                        onClick={props.handleSubmit}>
                                        Finish
                                    </Button>
                                </FlexBox>
                            </FlexBoxSA>
                        </>
            }
        </>
    )
}

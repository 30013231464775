import React from 'react'
import mainLogo from '../../../assets/images/mainLogo.png'
import { SpacerLeftRightMedium } from '../../../design/spacer/spacer'
import useWindowDimensions from '../../../global/components/screenSize'
import { FlexBox, FlexBoxSA, Headline, HeadlineMontMedium, SubHeading, SubHeadline, SubHeadlineMontMedium } from '../../../global/global.styles'
import { Logo, LogoHolder } from '../style/login.styles'

export const AppLogo = () => {

    const { width } = useWindowDimensions()

    return (
        <>
            <FlexBoxSA>
                <FlexBox>
                    <LogoHolder>
                        <Logo src={mainLogo} />
                    </LogoHolder>
                    {
                        width > 650
                        &&
                        <>
                            <SpacerLeftRightMedium />
                            <SubHeadlineMontMedium style={{ color: 'white' }}>
                                Growth
                            </SubHeadlineMontMedium>
                        </>
                    }
                </FlexBox>
            </FlexBoxSA>
        </>
    )
}

import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerLeftRightXL, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL } from '../../design/spacer/spacer'
import { BodyBoldG60, FlexBox, FlexColumn, FlexColumnSB, HamburgerContainer, MenuContainer, SubTitleItalics } from '../global.styles';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { AuthContext } from '../../context/auth/auth.provider';
import { Avatar, IconButton } from '@mui/material';
import { ApiContext } from '../../context/api/api.provider';
import userPH from '../../assets/images/user.png'

export default function SideBar() {

    const { user, logout } = useContext(AuthContext);
    const { profile } = useContext(ApiContext);

    const navigate = useNavigate();

    let activeStyle = {
        color: "#307EF3",
        textDecoration: "none",
        fontWeight: 600,
        fontSize: 24,
        lineHeight: "100%",
        letterSpacing: "-0.02em"
    };

    let inActiveStyle = {
        color: "black",
        textDecoration: "none",
        fontWeight: 600,
        fontSize: 24,
        lineHeight: "100%",
        letterSpacing: "-0.02em"
    };

    const [state, setState] = useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <MenuContainer>
                    <FlexColumnSB style={{ height: '60%' }}>
                        <FlexBox onClick={() => navigate("/profile")}>
                            <Avatar alt="Profile Picture" src={profile?.dp ? profile?.dp : userPH} />
                            <SpacerLeftRightMedium />
                            <BodyBoldG60>
                                {user.email}
                            </BodyBoldG60>
                        </FlexBox>
                        <NavLink
                            to="/dashboard"
                            style={({ isActive }) =>
                                isActive ? activeStyle : inActiveStyle
                            }>
                            Dashboard
                        </NavLink>
                        <NavLink
                            to="/time"
                            style={({ isActive }) =>
                                isActive ? activeStyle : inActiveStyle
                            }>
                            Time
                        </NavLink>
                        <NavLink
                            to="/health"
                            style={({ isActive }) =>
                                isActive ? activeStyle : inActiveStyle
                            }>
                            Health
                        </NavLink>
                        <NavLink
                            to="/home"
                            style={({ isActive }) =>
                                isActive ? activeStyle : inActiveStyle
                            }>
                            Home
                        </NavLink>
                        <NavLink
                            to="/portfolio"
                            style={({ isActive }) =>
                                isActive ? activeStyle : inActiveStyle
                            }>
                            Portfolio
                        </NavLink>
                        <NavLink
                            to="/recreation"
                            style={({ isActive }) =>
                                isActive ? activeStyle : inActiveStyle
                            }>
                            Recreation
                        </NavLink>
                        {/* <NavLink
                            to="/archive"
                            style={({ isActive }) =>
                                isActive ? activeStyle : inActiveStyle
                            }>
                            Archive
                        </NavLink> */}
                    </FlexColumnSB>
                    <div>
                        <Button
                            sx={{
                                textTransform: 'none',
                                fontSize: 24
                            }}
                            color="error"
                            startIcon={<LogoutRoundedIcon />}
                            size="large"
                            onClick={logout}>
                            Logout
                        </Button>
                    </div>
                </MenuContainer>
            </List>
        </Box>
    );

    return (
        <div>
            {['right'].map((anchor, index) => (
                <React.Fragment key={index}>
                    <HamburgerContainer onClick={toggleDrawer(anchor, true)}>
                        <MenuRoundedIcon
                            size="large"
                            sx={{ color: 'white' }}
                        />
                    </HamburgerContainer>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
import React from 'react'
import { AlignCenter, FlexBox, FlexBoxSB, Heading, Headline, MagnifyIcon } from '../../../global/global.styles'
import { SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerTopBottomLarge } from '../../../design/spacer/spacer'
import { MealTable } from './mealTable'
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import { Button, IconButton, Tooltip } from '@mui/material';

export const MealDay = (props) => {

    return (
        <>
            <FlexBoxSB>
                <div>
                    <Headline>
                        {props.date}
                    </Headline>
                    <AlignCenter>
                        <Heading>
                            {props.month}
                        </Heading>
                    </AlignCenter>
                </div>
                <FlexBox>
                    <Button
                        variant='outlined'
                        sx={{
                            textTransform: "none"
                        }}
                        disabled={props.mealTablePage === 0}
                        onClick={props.todayClicked}>
                        Today
                    </Button>
                    <SpacerLeftRightLarge />
                    <Tooltip title="Previous">
                        <IconButton aria-label="previous" disabled={props.mealTablePage === 0} onClick={props.previous}>
                            <ArrowCircleLeftRoundedIcon fontSize='large' />
                        </IconButton>
                    </Tooltip>
                    <SpacerLeftRightMedium />
                    <Tooltip title="Next">
                        <IconButton aria-label="next" onClick={props.next}>
                            <ArrowCircleRightRoundedIcon fontSize='large' />
                        </IconButton>
                    </Tooltip>
                </FlexBox>
            </FlexBoxSB>
            <SpacerTopBottomLarge />
            <MealTable
                width={props.width}
                data={props.data}
            />
        </>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import { BodyMediumG60, Container, FlexBoxSA, FlexBoxSB, Headline, Sandwich, TitleItalics } from '../../../global/global.styles'
import { Backdrop, Box, Button, Fade, Grid, Modal } from '@mui/material'
import SearchBar from '../../../global/components/searchBar'
import { ApiContext } from '../../../context/api/api.provider'
import { UpdateContext } from '../../../context/update/update.provider'
import { InvMenu } from '../components/invMenu'
import { NewItemForm } from '../components/newItemForm'
import { addDoc, collection, doc, serverTimestamp } from 'firebase/firestore'
import { db, storage } from '../../../firebase/firebase'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { AuthContext } from '../../../context/auth/auth.provider'
import defaultDp from '../../../assets/images/basket.png'
import { InvSlab } from '../components/invSlab'
import { SpacerTopBottomLarge, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import axios from 'axios'
import { InvBlock } from '../components/invBlock'

const units = [
    {
        value: 'gm',
        label: 'gm',
    },
    {
        value: 'ml',
        label: 'ml',
    },
    {
        value: 'l',
        label: 'l',
    },
];

const scopeArr = [
    {
        value: 'kitchen',
        label: 'Kitchen',
    },
    {
        value: 'bathroom',
        label: 'Bathroom',
    },
    {
        value: 'dining',
        label: 'Dining',
    },
    {
        value: 'bedroom',
        label: 'Bedroom',
    },
    {
        value: 'study',
        label: 'Study',
    },
    {
        value: 'utility',
        label: 'Utility',
    },
    {
        value: 'garage',
        label: 'Garage',
    },
    {
        value: 'balcony',
        label: 'Balcony',
    },
];

// const medium = [
//     {
//         value: 'Online',
//         label: 'Online',
//     },
//     {
//         value: 'Retail',
//         label: 'Retail',
//     },
//     {
//         value: 'Distributor',
//         label: 'Distributor',
//     },
//     {
//         value: 'Manufacturer',
//         label: 'Manufacturer',
//     },
// ];

// const paymentModeArr = [
//     {
//         value: 'UPI',
//         label: 'UPI',
//     },
//     {
//         value: 'Cash',
//         label: 'Cash',
//     },
//     {
//         value: 'Card',
//         label: 'Card',
//     },
//     {
//         value: 'Other',
//         label: 'Other',
//     },
// ];

export const Inventory = (props) => {

    const { user } = useContext(AuthContext);
    const { profile, inventory, inventoryCollectionRef } = useContext(ApiContext);
    const { refresh, setRefresh } = useContext(UpdateContext);

    const itemModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        borderRadius: '8px',
        backgroundColor: 'white',
        p: 4,
        width: props.width > 450 ? '500px' : props.width > 350 ? '320px' : '280px',
        height: props.width > 850 ? 'inherit' : props.width > 650 ? '40vh' : props.width > 370 ? '56vh' : '60vh',
        overflowY: 'auto'
    };

    const [search, setSearch] = useState();

    const [filteredData, setFilteredData] = useState(inventory);

    useEffect(() => {
        setFilteredData(inventory)
    }, [inventory])

    const searchFilter = (text) => {
        if (text) {
            const newData = inventory?.filter((item) => {
                const itemData = item.keywords
                    ? item.keywords.toLowerCase()
                    : ''.toLowerCase();
                const textData = text.toLowerCase();
                return itemData.indexOf(textData) > -1;
            });
            setFilteredData(newData);
            setSearch(text);
        } else {
            setFilteredData(inventory);
            setSearch(text);
        }
    }

    const [itemModal, setItemModal] = useState(false);
    const [name, setName] = useState("");
    const [scope, setScope] = useState("");
    const [brand, setBrand] = useState("");
    const [variantQty, setVariantQty] = useState("");
    const [variantUnit, setVariantUnit] = useState("");
    const [validity, setValidity] = useState("");
    const [file, setFile] = useState("");
    const [loading, setLoading] = useState("");
    const [progress, setProgress] = useState("");
    const [itemType, setItemType] = useState("none");

    const handleSavePackaged = async () => {
        setLoading(true);

        if (file) {

            const data = new FormData();
            data.append("file", file)
            data.append("upload_preset", "growth")
            data.append("cloud_name", "ddmaoqwfd")

            axios.post("https://api.cloudinary.com/v1_1/ddmaoqwfd/image/upload", data)
                .then((response) => {
                    const data = response.data
                    // console.log(data)
                    const imageUrl = data.url;
                    // console.log(imageUrl)
                    const docData = {
                        name: name,
                        brand: brand,
                        variantQty: variantQty,
                        variantUnit: variantUnit,
                        validity: validity,
                        scope: scope,
                        dp: imageUrl,
                        itemType: itemType,
                        keywords: `${name},${brand},${scope},${itemType}`,
                        userId: user.uid,
                        familyId: profile?.familyId,
                        createdAt: new Date()
                    }

                    addDoc(inventoryCollectionRef, docData);

                    setRefresh(!refresh);
                    setItemModal(false);
                    setName("");
                    setBrand("");
                    setVariantQty("");
                    setVariantUnit("");
                    setValidity("");
                    setScope("")
                    setFile("");
                    setLoading(false);

                }).catch((err) => {
                    console.log(err)
                })


        } else {
            const docData = {
                name: name,
                brand: brand,
                variantQty: variantQty,
                variantUnit: variantUnit,
                validity: validity,
                scope: scope,
                itemType: itemType,
                keywords: `${name},${brand},${scope},${itemType}`,
                userId: user.uid,
                familyId: profile?.familyId,
                createdAt: new Date()
            }

            await addDoc(inventoryCollectionRef, docData);

            setRefresh(!refresh);
            setItemModal(false);
            setName("");
            setBrand("");
            setVariantQty("");
            setVariantUnit("");
            setValidity("");
            setScope("")
            setFile("");
            setLoading(false);
        }
    }

    // const handleSavePackaged = async () => {

    //     setLoading(true);

    //     if (file) {
    //         const ext = file?.name
    //         const randomId = doc(collection(db, "temp")).id
    //         const storageRef = ref(storage, `Documents/${randomId}${ext?.substring(ext?.lastIndexOf("."))}`);
    //         const uploadTask = uploadBytesResumable(storageRef, file);

    //         uploadTask.on(
    //             "state_changed",
    //             (snapshot) => {
    //                 const prog = Math.round(
    //                     (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    //                 );
    //                 setProgress(prog);
    //             },
    //             (error) => console.log(error),
    //             () => {
    //                 getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
    //                     const data = {
    //                         name: name,
    //                         brand: brand,
    //                         variantQty: variantQty,
    //                         variantUnit: variantUnit,
    //                         validity: validity,
    //                         dp: downloadURL,
    //                         itemType: itemType,
    //                         ext: ext.split(".").pop(),
    //                         keywords: `${name},${brand},${itemType}`,
    //                         userId: user.uid,
    //                         familyId: profile?.familyId,
    //                         createdAt: serverTimestamp()
    //                     }

    //                     await addDoc(inventoryCollectionRef, data);

    //                     setRefresh(!refresh);
    //                     setItemModal(false);
    //                     setName("");
    //                     setBrand("");
    //                     setVariantQty("");
    //                     setVariantUnit("");
    //                     setValidity("");
    //                     setFile("");
    //                     setLoading(false);
    //                 });
    //             }
    //         );

    //     } else {
    //         const data = {
    //             name: name,
    //             brand: brand,
    //             variantQty: variantQty,
    //             variantUnit: variantUnit,
    //             validity: validity,
    //             itemType: itemType,
    //             keywords: `${name},${brand},${itemType}`,
    //             userId: user.uid,
    //             familyId: profile?.familyId,
    //             createdAt: serverTimestamp()
    //         }

    //         await addDoc(inventoryCollectionRef, data);

    //         setRefresh(!refresh);
    //         setItemModal(false);
    //         setName("");
    //         setBrand("");
    //         setVariantQty("");
    //         setVariantUnit("");
    //         setValidity("");
    //         setFile("");
    //         setLoading(false);
    //     }
    // }

    const handleSubmitPackaged = () => {

        if (name && variantQty && variantUnit) {
            handleSavePackaged()
        } else {
            alert("Some field values are missing in the form")
        }
    }

    const handleSaveWeighted = async () => {
        setLoading(true);

        if (file) {

            const data = new FormData();
            data.append("file", file)
            data.append("upload_preset", "growth")
            data.append("cloud_name", "ddmaoqwfd")

            axios.post("https://api.cloudinary.com/v1_1/ddmaoqwfd/image/upload", data)
                .then((response) => {
                    const data = response.data
                    // console.log(data)
                    const imageUrl = data.url;
                    // console.log(imageUrl)
                    const docData = {
                        name: name,
                        brand: brand,
                        variantQty: variantQty,
                        variantUnit: variantUnit,
                        validity: validity,
                        scope: scope,
                        dp: imageUrl,
                        itemType: itemType,
                        keywords: `${name},${brand},${scope},${itemType}`,
                        userId: user.uid,
                        familyId: profile?.familyId,
                        createdAt: new Date()
                    }

                    addDoc(inventoryCollectionRef, docData);

                    setRefresh(!refresh);
                    setItemModal(false);
                    setName("");
                    setBrand("");
                    setVariantQty("");
                    setVariantUnit("");
                    setValidity("");
                    setScope("")
                    setFile("");
                    setLoading(false);

                }).catch((err) => {
                    console.log(err)
                })


        } else {
            const docData = {
                name: name,
                brand: brand,
                variantQty: variantQty,
                variantUnit: variantUnit,
                validity: validity,
                scope: scope,
                itemType: itemType,
                keywords: `${name},${brand},${scope},${itemType}`,
                userId: user.uid,
                familyId: profile?.familyId,
                createdAt: new Date()
            }

            await addDoc(inventoryCollectionRef, docData);

            setRefresh(!refresh);
            setItemModal(false);
            setName("");
            setBrand("");
            setVariantQty("");
            setVariantUnit("");
            setValidity("");
            setScope("")
            setFile("");
            setLoading(false);
        }
    }

    // const handleSaveWeighted = async () => {

    //     setLoading(true);

    //     if (file) {
    //         const ext = file?.name
    //         const randomId = doc(collection(db, "temp")).id
    //         const storageRef = ref(storage, `Documents/${randomId}${ext?.substring(ext?.lastIndexOf("."))}`);
    //         const uploadTask = uploadBytesResumable(storageRef, file);

    //         uploadTask.on(
    //             "state_changed",
    //             (snapshot) => {
    //                 const prog = Math.round(
    //                     (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    //                 );
    //                 setProgress(prog);
    //             },
    //             (error) => console.log(error),
    //             () => {
    //                 getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
    //                     const data = {
    //                         name: name,
    //                         validity: validity,
    //                         dp: downloadURL,
    //                         itemType: itemType,
    //                         ext: ext.split(".").pop(),
    //                         keywords: `${name},${itemType}`,
    //                         userId: user.uid,
    //                         familyId: profile?.familyId,
    //                         createdAt: serverTimestamp()
    //                     }

    //                     await addDoc(inventoryCollectionRef, data);

    //                     setRefresh(!refresh);
    //                     setItemModal(false);
    //                     setName("");
    //                     setValidity("");
    //                     setFile("");
    //                     setLoading(false);
    //                 });
    //             }
    //         );

    //     } else {
    //         const data = {
    //             name: name,
    //             validity: validity,
    //             itemType: itemType,
    //             keywords: `${name},${itemType}`,
    //             userId: user.uid,
    //             familyId: profile?.familyId,
    //             createdAt: serverTimestamp()
    //         }

    //         await addDoc(inventoryCollectionRef, data);

    //         setRefresh(!refresh);
    //         setItemModal(false);
    //         setName("");
    //         setValidity("");
    //         setFile("");
    //         setLoading(false);
    //     }
    // }

    const handleSubmitWeighted = () => {

        if (name) {
            handleSaveWeighted()
        } else {
            alert("Some field values are missing in the form")
        }
    }

    const handleSavePiece = async () => {
        setLoading(true);

        if (file) {

            const data = new FormData();
            data.append("file", file)
            data.append("upload_preset", "growth")
            data.append("cloud_name", "ddmaoqwfd")

            axios.post("https://api.cloudinary.com/v1_1/ddmaoqwfd/image/upload", data)
                .then((response) => {
                    const data = response.data
                    // console.log(data)
                    const imageUrl = data.url;
                    // console.log(imageUrl)
                    const docData = {
                        name: name,
                        brand: brand,
                        variantQty: variantQty,
                        variantUnit: variantUnit,
                        validity: validity,
                        scope: scope,
                        dp: imageUrl,
                        itemType: itemType,
                        keywords: `${name},${brand},${scope},${itemType}`,
                        userId: user.uid,
                        familyId: profile?.familyId,
                        createdAt: new Date()
                    }

                    addDoc(inventoryCollectionRef, docData);

                    setRefresh(!refresh);
                    setItemModal(false);
                    setName("");
                    setBrand("");
                    setVariantQty("");
                    setVariantUnit("");
                    setValidity("");
                    setScope("")
                    setFile("");
                    setLoading(false);

                }).catch((err) => {
                    console.log(err)
                })


        } else {
            const docData = {
                name: name,
                brand: brand,
                variantQty: variantQty,
                variantUnit: variantUnit,
                validity: validity,
                scope: scope,
                itemType: itemType,
                keywords: `${name},${brand},${scope},${itemType}`,
                userId: user.uid,
                familyId: profile?.familyId,
                createdAt: new Date()
            }

            await addDoc(inventoryCollectionRef, docData);

            setRefresh(!refresh);
            setItemModal(false);
            setName("");
            setBrand("");
            setVariantQty("");
            setVariantUnit("");
            setValidity("");
            setScope("")
            setFile("");
            setLoading(false);
        }
    }

    // const handleSaveWeighted = async () => {

    //     setLoading(true);

    //     if (file) {
    //         const ext = file?.name
    //         const randomId = doc(collection(db, "temp")).id
    //         const storageRef = ref(storage, `Documents/${randomId}${ext?.substring(ext?.lastIndexOf("."))}`);
    //         const uploadTask = uploadBytesResumable(storageRef, file);

    //         uploadTask.on(
    //             "state_changed",
    //             (snapshot) => {
    //                 const prog = Math.round(
    //                     (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    //                 );
    //                 setProgress(prog);
    //             },
    //             (error) => console.log(error),
    //             () => {
    //                 getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
    //                     const data = {
    //                         name: name,
    //                         validity: validity,
    //                         dp: downloadURL,
    //                         itemType: itemType,
    //                         ext: ext.split(".").pop(),
    //                         keywords: `${name},${itemType}`,
    //                         userId: user.uid,
    //                         familyId: profile?.familyId,
    //                         createdAt: serverTimestamp()
    //                     }

    //                     await addDoc(inventoryCollectionRef, data);

    //                     setRefresh(!refresh);
    //                     setItemModal(false);
    //                     setName("");
    //                     setValidity("");
    //                     setFile("");
    //                     setLoading(false);
    //                 });
    //             }
    //         );

    //     } else {
    //         const data = {
    //             name: name,
    //             validity: validity,
    //             itemType: itemType,
    //             keywords: `${name},${itemType}`,
    //             userId: user.uid,
    //             familyId: profile?.familyId,
    //             createdAt: serverTimestamp()
    //         }

    //         await addDoc(inventoryCollectionRef, data);

    //         setRefresh(!refresh);
    //         setItemModal(false);
    //         setName("");
    //         setValidity("");
    //         setFile("");
    //         setLoading(false);
    //     }
    // }

    const handleSubmitPiece = () => {

        if (name) {
            handleSavePiece()
        } else {
            alert("Some field values are missing in the form")
        }
    }

    const today = new Date()

    const openNewItemForm = () => {
        setItemModal(true)
        setItemType("none")
    }

    return (
        <>
            <Container>
                <InvMenu
                    search={search}
                    searchFilter={searchFilter}
                    newItemClicked={openNewItemForm}
                    familyId={profile?.familyId}
                />
                {/* Expiring soon */}
                {
                    filteredData?.filter((x) => x.familyId === profile?.familyId).filter((x) => x.endDate <= today - 3).length > 0
                    &&
                    <Sandwich>
                        <InvSlab
                            width={props.width}
                            location={props.location}
                            content={filteredData?.filter((x) => x.familyId === profile?.familyId).filter((x) => x.endDate <= today - 3)}
                            title={"Expiring soon"}
                        />
                    </Sandwich>
                }
                {/* Recent Purchase */}
                {/* {
                    filteredData?.filter((x) => x.familyId === profile?.familyId).filter((x) => x.createdAt <= today - 3)
                        ?
                        <Sandwich>
                            <InvSlab
                                width={props.width}
                                location={props.location}
                                content={filteredData?.filter((x) => x.familyId === profile?.familyId).filter((x) => x.createdAt <= today - 3)}
                                title={"Recent Purchases"}
                            />
                        </Sandwich>
                        :
                        <Sandwich>
                            <FlexBoxSA>
                                <TitleItalics>
                                    Click on "New Item" to add your first Inventory item!
                                </TitleItalics>
                            </FlexBoxSA>
                        </Sandwich>
                } */}
                {/* All Inventory */}
                <Sandwich>
                    {
                        filteredData?.filter((x) => x.familyId === profile?.familyId).length > 0
                            ?
                            <>
                                <BodyMediumG60>
                                    All Inventory
                                </BodyMediumG60>
                                <SpacerTopBottomXXXL />
                                <Grid container spacing={3}>
                                    {
                                        filteredData?.filter((x) => x.familyId === profile?.familyId).map((item, index) => (
                                            <Grid item xl={3} lg={3} md={4} sm={6} xs={12} key={index}>
                                                <InvBlock
                                                    id={item.id}
                                                    location={props.location}
                                                    name={item.name}
                                                    scope={item.scope}
                                                    dp={item.dp}
                                                    brand={item.brand}
                                                    price={item.price}
                                                    variantQty={item.variantQty}
                                                    variantUnit={item.variantUnit}
                                                    validity={item.validity}
                                                    itemType={item.itemType}
                                                    purchases={item.purchases}
                                                    width={props.width}
                                                />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </>
                            :
                            <FlexBoxSA>
                                <TitleItalics>
                                    {
                                        profile?.familyId
                                            ?
                                            `Click on "New Item" to add your first Inventory item!`
                                            :
                                            `Register a Family in Profile to add a "New Item"`
                                    }
                                </TitleItalics>
                            </FlexBoxSA>
                    }
                </Sandwich>
            </Container>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={itemModal}
                onClose={() => setItemModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={itemModal}>
                    <Box sx={itemModalStyle}>
                        <NewItemForm
                            itemType={itemType}
                            pieceClicked={() => setItemType("piece")}
                            packagedClicked={() => setItemType("packaged")}
                            weightedClicked={() => setItemType("weighted")}
                            backClicked={() => setItemType("none")}
                            name={name}
                            onChangeName={(e) => setName(e.target.value)}
                            scope={scope}
                            onChangeScope={(e) => setScope(e.target.value)}
                            scopeArr={scopeArr}
                            brand={brand}
                            onChangeBrand={(e) => setBrand(e.target.value)}
                            variantQty={variantQty}
                            onChangeVariantQty={(e) => setVariantQty(e.target.value)}
                            units={units}
                            onChangeVariantUnit={(e) => setVariantUnit(e.target.value)}
                            validity={validity}
                            onChangeValidity={(e) => setValidity(e.target.value)}
                            file={file}
                            onChangeFile={(e) => setFile(e.target.files[0])}
                            deleteDP={() => setFile()}
                            btnColor={"success"}
                            loading={loading}
                            handleSavePackaged={handleSubmitPackaged}
                            handleSaveWeighted={handleSubmitWeighted}
                            handleSavePiece={handleSubmitPiece}
                            btnTitle={"Save"}
                            disabledPackaged={
                                name && variantQty && variantUnit
                                    ?
                                    false
                                    :
                                    true
                            }
                            disabledWeighted={
                                name
                                    ?
                                    false
                                    :
                                    true
                            }
                            disabledPiece={
                                name
                                    ?
                                    false
                                    :
                                    true
                            }
                        />
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

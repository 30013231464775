import React from 'react'
import { FlexColumnSB, SubTitleSemiBoldG60, TitleSemiBoldG40 } from '../../../global/global.styles'
import moment from 'moment'
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer'
import { NoteBlock } from './noteBlock'
import { Button } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const TimeLeft = (props) => {

    const today = new Date()

    return (
        <FlexColumnSB style={{ height: 320 }}>
            <div>
                <TitleSemiBoldG40>
                    {moment(today).format("DD MMM YYYY")}
                </TitleSemiBoldG40>
                <SpacerTopBottomLarge />
                <NoteBlock
                    bgColor={"#FAF4CC"}
                    noteTitle="Thought of the day"
                />
            </div>
            <Button
                variant='contained'
                color='warning'
                sx={{
                    textTransform: "none", 
                    backgroundColor: '#CCFF00',
                    color: '#313232',
                    ':hover': {
                        bgcolor: '#C0F000',
                        color: '#313232',
                    },
                }}
                size='small'
                endIcon={<InfoOutlinedIcon />}
                onClick={() => props.navigate("/archive")}>
                Archive
            </Button>
        </FlexColumnSB>
    )
}

import React, { createContext, useState, useEffect, useContext } from 'react'
import { db } from '../../firebase/firebase';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { AuthContext } from '../auth/auth.provider';
import { UpdateContext } from '../update/update.provider';

export const ApiContext = createContext()

export const ApiProvider = ({ children }) => {

    const { user } = useContext(AuthContext);
    const { refresh, profileRefresh } = useContext(UpdateContext);

    const [users, setUsers] = useState([]);
    const [creds, setCreds] = useState([]);
    const [docs, setDocs] = useState([]);
    const [profile, setProfile] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [meals, setMeals] = useState([]);
    const [mealMembers, setMealMembers] = useState([]);
    const [mealPlans, setMealPlans] = useState([]);
    const [taskBoards, setTaskBoards] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);

    const [loadingUsers, setLoadingUsers] = useState(true);
    const [loadingCreds, setLoadingCreds] = useState(true);
    const [loadingDocs, setLoadingDocs] = useState(true);
    const [loadingProfile, setLoadingProfile] = useState(true);
    const [loadingInventory, setLoadingInventory] = useState(true);
    const [loadingIngredients, setLoadingIngredients] = useState(true);
    const [loadingMeals, setLoadingMeals] = useState(true);
    const [loadingMealMembers, setLoadingMealMembers] = useState(true);
    const [loadingMealPlans, setLoadingMealPlans] = useState(true);
    const [loadingTaskBoards, setLoadingTaskBoards] = useState(true);
    const [loadingCompletedTasks, setLoadingCompletedTasks] = useState(true);

    const usersCollectionRef = collection(db, "users");
    const credsCollectionRef = collection(db, "credentials");
    const docsCollectionRef = collection(db, "documents");
    const inventoryCollectionRef = collection(db, "inventory");
    const ingredientsCollectionRef = collection(db, "ingredients");
    const mealsCollectionRef = collection(db, "meals");
    const mealMembersCollectionRef = collection(db, "mealMembers");
    const mealPlansCollectionRef = collection(db, "mealPlans");
    const taskBoardsCollectionRef = collection(db, "taskBoards");
    const completedTasksCollectionRef = collection(db, "completedTasks");

    useEffect(() => {

        try {

            const getUsers = async () => {
                const users = await getDocs(usersCollectionRef);
                setUsers(users.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingUsers(false);
            }
            getUsers();

            const getCreds = async () => {
                const creds = await getDocs(credsCollectionRef);
                setCreds(creds.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingCreds(false);
            }
            getCreds();

            const getDocuments = async () => {
                const docs = await getDocs(docsCollectionRef);
                setDocs(docs.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingDocs(false);
            }
            getDocuments();

            const getInventory = async () => {
                const inventory = await getDocs(inventoryCollectionRef);
                setInventory(inventory.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingInventory(false);
            }
            getInventory();

            const getIngredients = async () => {
                const ingredients = await getDocs(ingredientsCollectionRef);
                setIngredients(ingredients.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingIngredients(false);
            }
            getIngredients();

            const getMeals = async () => {
                const meals = await getDocs(mealsCollectionRef);
                setMeals(meals.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingMeals(false);
            }
            getMeals();

            const getMealMembers = async () => {
                const mealMembers = await getDocs(mealMembersCollectionRef);
                setMealMembers(mealMembers.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingMealMembers(false);
            }
            getMealMembers();

            const getMealPlans = async () => {
                const mealPlans = await getDocs(mealPlansCollectionRef);
                setMealPlans(mealPlans.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingMealPlans(false);
            }
            getMealPlans();

            const getTaskBoards = async () => {
                const taskBoards = await getDocs(taskBoardsCollectionRef);
                setTaskBoards(taskBoards.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingTaskBoards(false);
            }
            getTaskBoards();

            const getCompletedTasks = async () => {
                const completedTasks = await getDocs(completedTasksCollectionRef);
                setCompletedTasks(completedTasks.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingCompletedTasks(false);
            }
            getCompletedTasks();

        } catch (error) {
            const { code, message } = error;
            console.log(error);
        }

    }, [user])

    useEffect(() => {

        try {

            const getUsers = async () => {
                const users = await getDocs(usersCollectionRef);
                setUsers(users.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingUsers(false);
            }
            getUsers();

            const getCreds = async () => {
                const creds = await getDocs(credsCollectionRef);
                setCreds(creds.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingCreds(false);
            }
            getCreds();

            const getDocuments = async () => {
                const docs = await getDocs(docsCollectionRef);
                setDocs(docs.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingDocs(false);
            }
            getDocuments();

            const getInventory = async () => {
                const inventory = await getDocs(inventoryCollectionRef);
                setInventory(inventory.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingInventory(false);
            }
            getInventory();

            const getIngredients = async () => {
                const ingredients = await getDocs(ingredientsCollectionRef);
                setIngredients(ingredients.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingIngredients(false);
            }
            getIngredients();

            const getMeals = async () => {
                const meals = await getDocs(mealsCollectionRef);
                setMeals(meals.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingMeals(false);
            }
            getMeals();

            const getMealMembers = async () => {
                const mealMembers = await getDocs(mealMembersCollectionRef);
                setMealMembers(mealMembers.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingMealMembers(false);
            }
            getMealMembers();

            const getMealPlans = async () => {
                const mealPlans = await getDocs(mealPlansCollectionRef);
                setMealPlans(mealPlans.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingMealPlans(false);
            }
            getMealPlans();

            const getTaskBoards = async () => {
                const taskBoards = await getDocs(taskBoardsCollectionRef);
                setTaskBoards(taskBoards.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingTaskBoards(false);
            }
            getTaskBoards();

            const getCompletedTasks = async () => {
                const completedTasks = await getDocs(completedTasksCollectionRef);
                setCompletedTasks(completedTasks.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLoadingCompletedTasks(false);
            }
            getCompletedTasks();

        } catch (error) {
            const { code, message } = error;
            console.log(error);
        }

    }, [refresh])

    useEffect(() => {

        if (user) {
            const { email, uid } = user;
            try {
                const userDocRef = doc(db, "users", user.uid)
                const getUserProfile = async () => {
                    const data = await getDoc(userDocRef)
                        .then((doc) => {
                            setProfile(doc.data())
                        })
                };
                getUserProfile();

            } catch (error) {
                const { code, message } = error;
                console.log(error);
            }
        }

        setLoadingProfile(false);

    }, [user, profileRefresh])


    return (
        <ApiContext.Provider
            value={{
                users,
                setUsers,
                usersCollectionRef,
                loadingUsers,
                creds,
                setCreds,
                credsCollectionRef,
                docs,
                setDocs,
                docsCollectionRef,
                profile,
                setProfile,
                loadingProfile,
                setLoadingProfile,
                inventory,
                setInventory,
                inventoryCollectionRef,
                loadingInventory,
                setLoadingInventory,
                ingredients,
                setIngredients,
                ingredientsCollectionRef,
                loadingIngredients,
                setLoadingIngredients,
                meals,
                setMeals,
                mealsCollectionRef,
                loadingMeals,
                setLoadingMeals,
                mealMembers,
                setMealMembers,
                mealMembersCollectionRef,
                loadingMealMembers,
                setLoadingMealMembers,
                mealPlans,
                setMealPlans,
                mealPlansCollectionRef,
                loadingMealPlans,
                setLoadingMealPlans,
                taskBoards,
                setTaskBoards,
                taskBoardsCollectionRef,
                loadingTaskBoards,
                setLoadingTaskBoards,
                completedTasks,
                completedTasksCollectionRef,
                loadingCompletedTasks
            }}
        >
            {children}
        </ApiContext.Provider>
    )
}
import { Button, TextField } from '@mui/material'
import React from 'react'
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall, SpacerTopBottomXL } from '../../../design/spacer/spacer'
import { AlignCenter, BodyBoldG60, BodySemiBold, Title } from '../../../global/global.styles'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { UploadButton } from '../style/archive.styles';

export const NewDocForm = (props) => {
    return (
        <>
            <Title>
                Log a new Document
            </Title>
            <SpacerTopBottomXL />
            <BodySemiBold>
                Title
            </BodySemiBold>
            <SpacerTopBottomSmall />
            <TextField
                id="title"
                name="title"
                type="text"
                size='small'
                autoComplete='off'
                fullWidth
                value={props.title}
                onChange={props.onChangeTitle}
                inputProps={{ style: { fontSize: '16px' } }}
            />
            <SpacerTopBottomLarge />
            <BodySemiBold>
                Description
            </BodySemiBold>
            <SpacerTopBottomSmall />
            <TextField
                id="description"
                name="description"
                type="text"
                size='small'
                autoComplete='off'
                fullWidth
                value={props.description}
                onChange={props.onChangedescription}
                inputProps={{ style: { fontSize: '16px' } }}
            />
            <SpacerTopBottomLarge />
            <BodySemiBold>
                Category
            </BodySemiBold>
            <SpacerTopBottomSmall />
            <TextField
                id="category"
                name="category"
                type="text"
                size='small'
                autoComplete='off'
                fullWidth
                value={props.category}
                onChange={props.onChangeCategory}
                inputProps={{ style: { fontSize: '16px' } }}
            />
            <SpacerTopBottomLarge />
            <label htmlFor='file'>
                <UploadButton>
                    <AlignCenter>
                        <BodySemiBold style={{ color: '#0077E5' }}>
                            Upload Document
                        </BodySemiBold>
                    </AlignCenter>
                </UploadButton>
                <input
                    id="file"
                    type='file'
                    style={{ display: 'none' }}
                    onChange={props.onChangeFile}
                />
            </label>
            <SpacerTopBottomSmall />
            {
                props.file
                &&
                <AlignCenter>
                    <BodyBoldG60>{props.fileName}</BodyBoldG60>
                </AlignCenter>
            }
            <SpacerTopBottomLarge />
            <Button
                variant='contained'
                color={props.btnColor}
                fullWidth
                sx={{
                    textTransform: 'none'
                }}
                onClick={props.handleSave}>
                {props.btnTitle}
            </Button>
        </>
    )
}

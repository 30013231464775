import React from 'react'
import { BodySemiBold, FlexBoxSB, Title } from '../../../global/global.styles'
import { SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall, SpacerTopBottomXL } from '../../../design/spacer/spacer'
import { Autocomplete, Button, FormControl, MenuItem, Select, TextField } from '@mui/material'
import { IngredientsArea } from '../style/home.styles'
import { IngredientTag } from './ingredientTag'
import { IngredientComposition } from './ingredientComposition'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save';

export const AddNewMealForm = (props) => {

  // props.ingredientsArr.length > 0
  //   ?
  //   props.mockIngredients.filter((el) => {
  //     return props.ingredientsArr.some((f) => {
  //       return f.ingredientName !== el.value
  //     })
  //   }).map((option) => (
  //     <MenuItem key={option.value} value={option.value}>
  //       {option.label}
  //     </MenuItem>
  //   ))
  //   :
  //   props.mockIngredients.map((option) => (
  //     <MenuItem key={option.value} value={option.value}>
  //       {option.label}
  //     </MenuItem>
  //   ))

  return (
    <>
      <Title>
        Add a new meal
      </Title>
      <SpacerTopBottomXL />
      <FlexBoxSB>
        <div>
          <BodySemiBold>
            Name*
          </BodySemiBold>
          <SpacerTopBottomSmall />
          <TextField
            id="mealName"
            name="mealName"
            type="text"
            size='small'
            autoComplete='off'
            fullWidth
            value={props.mealName}
            onChange={props.onChangeMealName}
            inputProps={{ style: { fontSize: '16px' }, maxLength: 20 }}
            placeholder={props.placeholderMealName}
          />
        </div>
        <SpacerLeftRightMedium />
        <div>
          <BodySemiBold>
            Preferred time (range)
          </BodySemiBold>
          <SpacerTopBottomSmall />
          <FlexBoxSB>
            <TextField
              id="mealTimeFrom"
              name="mealTimeFrom"
              label="From"
              type="time"
              size='small'
              autoComplete='off'
              value={props.mealTimeFrom}
              onChange={props.onChangeMealTimeFrom}
              inputProps={{ style: { fontSize: '16px' } }}
              placeholder={props.placeholderMealTimeFrom}
            />
            <SpacerLeftRightSmall />
            <TextField
              id="mealTimeTo"
              name="mealTimeTo"
              label="To"
              type="time"
              size='small'
              autoComplete='off'
              value={props.mealTimeTo}
              onChange={props.onChangeMealTimeTo}
              inputProps={{ style: { fontSize: '16px' } }}
              placeholder={props.placeholderMealTimeTo}
            />
          </FlexBoxSB>
        </div>
      </FlexBoxSB>
      <SpacerTopBottomLarge />
      <FlexBoxSB>
        <div>
          <BodySemiBold>
            Add ingredient
          </BodySemiBold>
          <SpacerTopBottomSmall />
          <FormControl sx={{ width: props.width > 650 ? 210 : 120 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.mealIngredients}
              onChange={props.onChangeMealIngredients}
              size='small'
              fullWidth
            >
              {props.mockIngredients.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <SpacerLeftRightMedium />
        <div>
          <BodySemiBold>
            Average weight (gm/l)
          </BodySemiBold>
          <SpacerTopBottomSmall />
          <TextField
            id="mealAvgWeight"
            name="mealAvgWeight"
            type="number"
            size='small'
            autoComplete='off'
            fullWidth
            value={props.mealAvgWeight}
            onChange={props.onChangeMealAvgWeight}
            inputProps={{ style: { fontSize: '16px' } }}
            placeholder={props.placeholderMealAvgWeight}
          />
        </div>
      </FlexBoxSB>
      <SpacerTopBottomLarge />
      <IngredientsArea>
        {
          props.ingredientsArr
          &&
          props.ingredientsArr?.map((item, index) => (
            <div key={index} style={{ float: "left" }}>
              <IngredientTag
                name={item.ingredientName}
                ingredientsArr={props.ingredientsArr}
                setIngredientsArr={props.setIngredientsArr}
              />
            </div>
          ))
        }
      </IngredientsArea>
      {
        props.ingredientsArr.length > 0
        &&
        <>
          <SpacerTopBottomLarge />
          <BodySemiBold>
            Specify composition
          </BodySemiBold>
          <SpacerTopBottomMedium />
          <IngredientComposition
            ingredientsArr={props.ingredientsArr}
            setIngredientsArr={props.setIngredientsArr}
          />
        </>
      }
      <SpacerTopBottomLarge />
      {
        props.loading
          ?
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            fullWidth
            variant="outlined"
            sx={{
              textTransform: 'none'
            }}
            size='large'
          >
            Saving
          </LoadingButton>
          :
          <Button
            variant='contained'
            color="success"
            fullWidth
            sx={{
              textTransform: 'none'
            }}
            onClick={props.handleSubmit}>
            Save
          </Button>
      }
    </>
  )
}

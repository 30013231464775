import React from 'react'
import { FamilyGroupInterfaceContainer } from '../style/profile.styles'
import SearchBar from '../../../global/components/searchBar'
import { SearchResultsBlock } from './searchResultsBlock'

export const FamilyGroupInterface = (props) => {
    return (
        <>
            <FamilyGroupInterfaceContainer>
                <SearchBar
                    placeholder={"Search Groups"}
                    search={props.search}
                    onChange={(event) => props.searchFilter(event.target.value)}
                    width={300}
                    xsWidth={200}
                    xxsWidth={120}
                />
                {
                    props.search
                        &&
                        props.searchResults.map((item, index) => (
                            <SearchResultsBlock
                                key={index}
                                familyId={item.familyId}
                            />
                        ))
                }
            </FamilyGroupInterfaceContainer>
        </>
    )
}

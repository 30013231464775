import { Backdrop, Box, Button, Fade, Grid, Modal, TextField } from '@mui/material'
import React, { useContext, useEffect, useId, useState } from 'react'
import { ApiContext } from '../../../context/api/api.provider'
import { AuthContext } from '../../../context/auth/auth.provider'
import { UpdateContext } from '../../../context/update/update.provider'
import useWindowDimensions from '../../../global/components/screenSize'
import { AbsoluteBottomRight, BodyBoldG40, BodyMediumG60, Container, FlexBox, FlexBoxSA, FlexBoxSB, MagnifyIcon, Sandwich, Squeezer, SubHeadingSemiBold, SubTitleG40, SubTitleSemiBoldG40 } from '../../../global/global.styles'
import { AbsoluteEditIcon, DpHolder, DpImg, EditIconHolder, EditIconImg, ThumbnailImg } from '../style/profile.styles'
import userPH from '../../../assets/images/user.png'
import { SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall, SpacerTopBottomXL, SpacerTopBottomXXXL } from '../../../design/spacer/spacer'
import editIcon from '../../../assets/images/edit.png'
import { collection, deleteDoc, doc, serverTimestamp, setDoc } from 'firebase/firestore'
import { db, storage } from '../../../firebase/firebase'
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { FamilyGroupInterface } from '../components/familyGroupInterface'

export const ProfileDetails = () => {

    const { width } = useWindowDimensions();

    const { profile, users } = useContext(ApiContext);
    const { user } = useContext(AuthContext);
    const { profileRefresh, setProfileRefresh } = useContext(UpdateContext);

    const familyModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        borderRadius: '8px',
        backgroundColor: 'white',
        p: 4,
        // width: props.width > 450 ? '400px' : props.width > 350 ? '320px' : '280px',
        // height: props.width > 850 ? 'inherit' : props.width > 650 ? '40vh' : props.width > 370 ? '56vh' : '60vh',
        overflowY: 'auto'
    };

    const [editProfile, setEditProfile] = useState(false)

    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [dp, setDp] = useState("")
    const [familyId, setFamilyId] = useState("")
    const [familyModal, setFamilyModal] = useState("")

    const [file, setFile] = useState("");
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState();

    const [filteredData, setFilteredData] = useState(users);

    useEffect(() => {
        setFilteredData(users)
    }, [users])

    const searchFilter = (text) => {
        if (text) {
            const newData = users?.filter((item) => {
                const itemData = item.familyId
                    ? item.familyId.toLowerCase()
                    : ''.toLowerCase();
                const textData = text.toLowerCase();
                return itemData.indexOf(textData) > -1;
            });
            setFilteredData(newData);
            setSearch(text);
        } else {
            setFilteredData(users);
            setSearch(text);
        }
    }

    const handleUpdate = async () => {
        setLoading(true);

        if (file) {
            const ext = file?.name
            const randomId = doc(collection(db, "temp")).id
            const storageRef = ref(storage, `DP/${randomId}${ext?.substring(ext?.lastIndexOf("."))}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(prog);
                },
                (error) => console.log(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

                        await setDoc(doc(db, "users", user.uid), {
                            firstname: firstname,
                            lastname: lastname,
                            familyId: profile?.familyId ? profile?.familyId : "",
                            dp: downloadURL,
                            userId: user.uid,
                            updatedAt: serverTimestamp()
                        })

                        setLoading(false)
                        setFile("")
                        setProfileRefresh(!profileRefresh);
                        setEditProfile(false);

                        if (profile?.dp) {
                            // Create a reference to the file to delete
                            const fileRef = ref(storage, profile?.dp);

                            // Delete the file
                            deleteObject(fileRef).then(() => {
                                // File deleted successfully
                            }).catch((error) => {
                                // Uh-oh, an error occurred!
                            });
                        }
                    });
                }
            );


        } else {
            await setDoc(doc(db, "users", user.uid), {
                firstname: firstname,
                lastname: lastname,
                familyId: profile?.familyId ? profile?.familyId : "",
                userId: user.uid,
                updatedAt: serverTimestamp()
            })

            setLoading(false)
            setFile("")
            setProfileRefresh(!profileRefresh);
            setEditProfile(false);
        }
    }

    const cancelEdit = () => {
        setEditProfile(false);
        setFile("")
    }

    const familyIdGen = useId()

    const handleCreateFamilyID = async () => {
        setLoading(true)

        await setDoc(doc(db, "users", user.uid), {
            firstname: profile?.firstname ? profile?.firstname : "",
            lastname: profile?.lastname ? profile?.lastname : "",
            familyId: familyIdGen,
            userId: user.uid,
            dp: profile?.dp ? profile?.dp : "",
            updatedAt: serverTimestamp()
        })

        setLoading(false)
        setProfileRefresh(!profileRefresh);
    }

    return (
        <>
            <FlexBoxSB>
                <SubHeadingSemiBold>
                    Profile
                </SubHeadingSemiBold>
                {
                    editProfile
                        ?
                        <Button
                            variant='outlined'
                            fullWidth={width > 850 ? false : true}
                            color='error'
                            onClick={cancelEdit}>
                            Cancel edit
                        </Button>
                        :
                        <Button
                            variant='outlined'
                            color='secondary'
                            onClick={() => setEditProfile(true)}>
                            Edit Profile
                        </Button>
                }
            </FlexBoxSB>
            <Sandwich>
                <Grid container>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <FlexBoxSA>
                            <DpHolder>
                                {
                                    file
                                        ?
                                        <ThumbnailImg src={file && URL.createObjectURL(file)} />
                                        :
                                        <DpImg src={profile?.dp ? profile?.dp : userPH} />
                                }
                                {
                                    !profile?.dp
                                        ?
                                        <AbsoluteEditIcon>
                                            <label htmlFor='file'>
                                                <MagnifyIcon>
                                                    <EditIconHolder>
                                                        <EditIconImg src={editIcon} />
                                                    </EditIconHolder>
                                                </MagnifyIcon>
                                                <input
                                                    id="file"
                                                    type='file'
                                                    style={{ display: 'none' }}
                                                    onChange={e => setFile(e.target.files[0])}
                                                />
                                            </label>
                                        </AbsoluteEditIcon>
                                        :
                                        editProfile
                                        &&
                                        <AbsoluteEditIcon>
                                            <label htmlFor='file'>
                                                <MagnifyIcon>
                                                    <EditIconHolder>
                                                        <EditIconImg src={editIcon} />
                                                    </EditIconHolder>
                                                </MagnifyIcon>
                                                <input
                                                    id="file"
                                                    type='file'
                                                    style={{ display: 'none' }}
                                                    onChange={e => setFile(e.target.files[0])}
                                                />
                                            </label>
                                        </AbsoluteEditIcon>
                                }
                            </DpHolder>
                        </FlexBoxSA>
                        <SpacerTopBottomMedium />
                        {
                            file
                            &&
                            <FlexBoxSA>
                                <BodyMediumG60>
                                    File selected: {file?.name}
                                </BodyMediumG60>
                            </FlexBoxSA>
                        }
                    </Grid>
                    {
                        width < 850
                        &&
                        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                            <Sandwich />
                        </Grid>
                    }
                    <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <SubTitleG40>
                                    First name
                                </SubTitleG40>
                                <SpacerTopBottomSmall />
                                {
                                    profile?.firstname
                                        ?
                                        editProfile
                                            ?
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                size='small'
                                                fullWidth
                                                value={firstname}
                                                onChange={(e) => setFirstname(e.target.value)}
                                                placeholder={profile?.firstname}
                                            />
                                            :
                                            <SubHeadingSemiBold>
                                                {profile?.firstname}
                                            </SubHeadingSemiBold>
                                        :
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={firstname}
                                            onChange={(e) => setFirstname(e.target.value)}
                                            placeholder={profile?.firstname}
                                        />
                                }
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <SubTitleG40>
                                    Last name
                                </SubTitleG40>
                                <SpacerTopBottomSmall />
                                {
                                    profile?.lastname
                                        ?
                                        editProfile
                                            ?
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                size='small'
                                                fullWidth
                                                value={lastname}
                                                onChange={(e) => setLastname(e.target.value)}
                                                placeholder={profile?.lastname}
                                            />
                                            :
                                            <SubHeadingSemiBold>
                                                {profile?.lastname}
                                            </SubHeadingSemiBold>
                                        :
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={lastname}
                                            onChange={(e) => setLastname(e.target.value)}
                                            placeholder={profile?.lastname}
                                            
                                        />
                                }
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <SubTitleG40>
                                    Username / Email address
                                </SubTitleG40>
                                <SpacerTopBottomSmall />
                                <SubHeadingSemiBold>
                                    {user?.email}
                                </SubHeadingSemiBold>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <SubTitleG40>
                                    User ID
                                </SubTitleG40>
                                <SpacerTopBottomSmall />
                                {
                                    <SubHeadingSemiBold>
                                        {profile?.userId}
                                    </SubHeadingSemiBold>
                                }
                            </Grid>
                            {/* <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <SubTitleG40>
                                    Family ID
                                </SubTitleG40>
                                <SpacerTopBottomSmall />
                                {
                                    profile?.familyId
                                        ?
                                        editProfile
                                            ?
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                size='small'
                                                fullWidth
                                                value={familyId}
                                                onChange={(e) => setFamilyId(e.target.value)}
                                            />
                                            :
                                            <SubHeadingSemiBold>
                                                {profile?.familyId}
                                            </SubHeadingSemiBold>
                                        :
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size='small'
                                            fullWidth
                                            value={familyId}
                                            onChange={(e) => setFamilyId(e.target.value)}
                                        />
                                }
                            </Grid> */}
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                {
                                    profile?.familyId
                                        ?
                                        <>
                                            <SubTitleG40>
                                                Family ID
                                            </SubTitleG40>
                                            <SpacerTopBottomSmall />
                                            <SubHeadingSemiBold>
                                                {profile?.familyId}
                                            </SubHeadingSemiBold>
                                            {
                                                editProfile
                                                &&
                                                <>
                                                    <SpacerTopBottomMedium />
                                                    <Button
                                                        variant='contained'
                                                        sx={{
                                                            textTransform: "none",
                                                            width: 120
                                                        }}
                                                        color="secondary"
                                                        onClick={() => setFamilyModal(true)}>
                                                        Join
                                                    </Button>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <SubTitleG40>
                                                Family Group
                                            </SubTitleG40>
                                            <SpacerTopBottomSmall />
                                            <FlexBox>
                                                <Button
                                                    variant='contained'
                                                    sx={{
                                                        textTransform: "none",
                                                        width: 120
                                                    }}
                                                    color="success"
                                                    onClick={handleCreateFamilyID}>
                                                    Create
                                                </Button>
                                                <SpacerLeftRightMedium />
                                                <Button
                                                    variant='contained'
                                                    sx={{
                                                        textTransform: "none",
                                                        width: 120
                                                    }}
                                                    color="secondary"
                                                    onClick={() => setFamilyModal(true)}>
                                                    Join
                                                </Button>
                                            </FlexBox>
                                        </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Sandwich />
                <FlexBox style={{ justifyContent: 'end' }}>
                    {
                        loading ?
                            <LoadingButton
                                loading
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="outlined"
                                fullWidth={width > 850 ? false : true}
                            >
                                Updating
                            </LoadingButton>
                            :
                            <Button
                                variant='contained'
                                fullWidth={width > 850 ? false : true}
                                color='warning'
                                onClick={handleUpdate}>
                                Update
                            </Button>
                    }
                </FlexBox>
            </Sandwich>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={familyModal}
                onClose={() => setFamilyModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={familyModal}>
                    <Box sx={familyModalStyle}>
                        <FamilyGroupInterface 
                            search={search}
                            searchFilter={searchFilter}
                            searchResults={filteredData.filter((x) => x.familyId !== profile?.familyId)}
                        />
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

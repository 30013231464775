import { Button, Grid } from '@mui/material'
import React from 'react'
import { TimeLeft } from './timeLeft'
import { TimePeriods } from './timePeriods'
import { TimeRight } from './timeRight'
import { Container, FlexBox, FlexBoxSA } from '../../../global/global.styles'
import { SpacerTopBottomLarge, SpacerTopBottomXXL } from '../../../design/spacer/spacer'

export const TimeHeader = (props) => {
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <TimeLeft
                        navigate={props.navigate}
                    />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <TimePeriods />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <TimeRight
                        navigate={props.navigate}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

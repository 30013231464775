import React, { useState } from 'react'
import { InvItemDetailsContainer } from '../style/home.styles'
import { ChildHeadline, FlexBox, FlexBoxSA, FlexBoxSB, FlexColumnSB, Heading, Headline, MagnifyIcon, Sandwich, SubBodyMediumG40, SubBodySemiBoldG40, SubHeadingSemiBold, SubTitle, SubTitleG40, SubTitleG60, SubTitleMediumG60, SubTitleSemiBold, SubTitleSemiBoldG60, TitleItalics, TitleSemiBold } from '../../../global/global.styles'
import { SpacerBottomXXL, SpacerBottomXXXL, SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerLeftRightXXXL, SpacerTopBottomLarge, SpacerTopBottomMedium } from '../../../design/spacer/spacer'
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import { ImgHolder } from '../../../global/components/imgHolder';
import { NumericFormat } from 'react-number-format';
import { Divider } from '../../../global/components/divider';
import { Button, Grid } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { SourceBlock } from './sourceBlock';
import { InvItemDetailsTbody } from './invItemDetailsTbody';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import dpPlaceholder from '../../../assets/images/basket.png'
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

export const InvItemDetails = (props) => {

    const today = new Date()

    const unique = [...new Set(props.purchases?.map(item => item.sourceName))];

    const uniqueLatestArr = props.purchases && Object.values(
        props.purchases.filter((x) => x.createdAt <= today - 30).reduce((acc, obj) => ({ ...acc, [obj.sourceName]: obj }), {})
    )

    return (
        <>
            <InvItemDetailsContainer>
                <div style={{ position: 'sticky', top: 8, backgroundColor: 'white', zIndex: 1 }}>
                    <FlexBoxSB>
                        <FlexBox>
                            <div>
                                <Heading>
                                    {props.name}
                                </Heading>
                                <SpacerTopBottomLarge />
                                <SubTitleSemiBoldG60>
                                    {props.brand}
                                </SubTitleSemiBoldG60>
                                <SpacerTopBottomMedium />
                                <FlexBox>
                                    <SubTitleMediumG60>
                                        {props.variantQty}
                                    </SubTitleMediumG60>
                                    <SubTitleMediumG60>
                                        {props.variantUnit}
                                    </SubTitleMediumG60>
                                    {
                                        props.validity
                                        &&
                                        !props.validity === 0
                                        &&
                                        <>
                                            <SpacerLeftRightLarge>
                                                <FiberManualRecordRoundedIcon
                                                    sx={{
                                                        width: 10,
                                                        height: 10,
                                                        color: '#AAA9AE'
                                                    }}
                                                />
                                            </SpacerLeftRightLarge>
                                            <SubTitleMediumG60>
                                                {`${props.validity} D`}
                                            </SubTitleMediumG60>
                                        </>
                                    }
                                </FlexBox>
                            </div>
                            {
                                props.width > 850
                                &&
                                <>
                                    <SpacerLeftRightXXXL />
                                    <ImgHolder
                                        img={props.dp ? props.dp : dpPlaceholder}
                                        width={100}
                                        height={100}
                                    />
                                </>
                            }
                        </FlexBox>
                        {
                            props.width < 850
                            &&
                            <ImgHolder
                                img={props.dp ? props.dp : dpPlaceholder}
                                width={72}
                                height={72}
                            />
                        }
                        {
                            props.width > 850
                            &&
                            <FlexColumnSB style={{ height: '100px' }}>
                                <FlexBox style={{ justifyContent: 'end' }}>
                                    <SubTitleG40 style={{ width: 80, textAlign: 'right' }}>
                                        Average unit price
                                    </SubTitleG40>
                                </FlexBox>
                                <FlexBox style={{ justifyContent: 'end' }}>
                                    <ChildHeadline style={{ textAlign: 'right' }}>
                                        {
                                            props.purchases
                                                ?
                                                props.itemType === "packaged"
                                                    ?
                                                    <NumericFormat
                                                        value={
                                                            uniqueLatestArr.reduce((total, next) => total + parseFloat(next.price), 0) / uniqueLatestArr.length / parseFloat(props.variantQty)
                                                        }
                                                        decimalScale={3}
                                                        prefix="₹"
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                    />
                                                    :
                                                    props.itemType === "weighted"
                                                    &&
                                                    <NumericFormat
                                                        value={
                                                            uniqueLatestArr.reduce((total, next) => total + parseFloat(next.price), 0) / uniqueLatestArr.length / uniqueLatestArr.reduce((total, next) => total + parseFloat(next.wQty), 0) / uniqueLatestArr.length
                                                        }
                                                        decimalScale={3}
                                                        prefix="₹"
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                    />
                                                :
                                                "-"
                                        }
                                    </ChildHeadline>
                                </FlexBox>
                            </FlexColumnSB>
                        }
                    </FlexBoxSB>
                    {
                        props.width < 850
                        &&
                        <>
                            <SpacerTopBottomMedium />
                            <FlexBox>
                                <SubTitleG40>
                                    Average unit price:
                                </SubTitleG40>
                                <SpacerLeftRightMedium />
                                <ChildHeadline>
                                    {
                                        props.purchases
                                            ?
                                            props.itemType === "packaged"
                                                ?
                                                <NumericFormat
                                                    value={
                                                        uniqueLatestArr.reduce((total, next) => total + parseFloat(next.price), 0) / uniqueLatestArr.length / parseFloat(props.variantQty)
                                                    }
                                                    decimalScale={3}
                                                    prefix="₹"
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                />
                                                :
                                                props.itemType === "weighted"
                                                &&
                                                <NumericFormat
                                                    value={
                                                        uniqueLatestArr.reduce((total, next) => total + parseFloat(next.price), 0) / uniqueLatestArr.length / uniqueLatestArr.reduce((total, next) => total + parseFloat(next.wQty), 0) / uniqueLatestArr.length
                                                    }
                                                    decimalScale={3}
                                                    prefix="₹"
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                />
                                            :
                                            "-"
                                    }
                                </ChildHeadline>
                            </FlexBox>
                        </>
                    }
                    <SpacerTopBottomLarge>
                        <Divider
                            width={"100%"}
                            height={1}
                            color={"#AAA9AE"}
                        />
                    </SpacerTopBottomLarge>
                    <SpacerTopBottomLarge />
                    {
                        props.width > 850
                            ?
                            <FlexBoxSB>
                                <Button
                                    variant='outlined'
                                    size='small'
                                    color='error'
                                    sx={{
                                        textTransform: 'none'
                                    }}
                                    startIcon={<DeleteOutlineRoundedIcon />}
                                    onClick={props.deleteAlertClicked}>
                                    Delete Item
                                </Button>
                                <FlexBox>
                                    <Button
                                        variant='outlined'
                                        size='small'
                                        color='info'
                                        sx={{
                                            textTransform: 'none'
                                        }}
                                        startIcon={<LinkRoundedIcon />}
                                        disabled>
                                        Link Items
                                    </Button>
                                    <SpacerLeftRightSmall />
                                    <Button
                                        variant='outlined'
                                        size='small'
                                        color='secondary'
                                        sx={{
                                            textTransform: 'none'
                                        }}
                                        startIcon={<DescriptionOutlinedIcon />}
                                        onClick={props.changeDpClicked}>
                                        Edit details
                                    </Button>
                                    <SpacerLeftRightSmall />
                                    <Button
                                        variant='outlined'
                                        size='small'
                                        color='success'
                                        sx={{
                                            textTransform: 'none'
                                        }}
                                        startIcon={<AddRoundedIcon />}
                                        onClick={props.newPurchaseClicked}>
                                        Add Purchase
                                    </Button>
                                </FlexBox>
                            </FlexBoxSB>
                            :
                            <Grid container spacing={2} style={{ paddingBottom: 12 }}>
                                <Grid item sm={6} xs={6}>
                                    <FlexBoxSA>
                                        <Button
                                            variant='outlined'
                                            size='small'
                                            color='error'
                                            sx={{
                                                textTransform: 'none'
                                            }}
                                            startIcon={<DeleteOutlineRoundedIcon />}
                                            onClick={props.deleteAlertClicked}>
                                            Delete Item
                                        </Button>
                                    </FlexBoxSA>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <FlexBoxSA>
                                        <Button
                                            variant='outlined'
                                            size='small'
                                            color='info'
                                            sx={{
                                                textTransform: 'none'
                                            }}
                                            startIcon={<LinkRoundedIcon />}
                                            disabled>
                                            Link Items
                                        </Button>
                                    </FlexBoxSA>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <FlexBoxSA>
                                        <Button
                                            variant='outlined'
                                            size='small'
                                            color='secondary'
                                            sx={{
                                                textTransform: 'none'
                                            }}
                                            startIcon={<DescriptionOutlinedIcon />}
                                            onClick={props.changeDpClicked}>
                                            Edit details
                                        </Button>
                                    </FlexBoxSA>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <FlexBoxSA>
                                        <Button
                                            variant='outlined'
                                            size='small'
                                            color='success'
                                            sx={{
                                                textTransform: 'none'
                                            }}
                                            startIcon={<AddRoundedIcon />}
                                            onClick={props.newPurchaseClicked}>
                                            Add Purchase
                                        </Button>
                                    </FlexBoxSA>
                                </Grid>
                            </Grid>
                    }
                </div>
                {
                    !props.purchases
                    &&
                    <Sandwich>
                        <FlexBoxSA>
                            <TitleItalics>
                                Click on "Add Purchase" to add your first transaction!
                            </TitleItalics>
                        </FlexBoxSA>
                    </Sandwich>
                }
                {
                    props.purchases
                    &&
                    props.purchases.length > 0
                    &&
                    <>
                        <SpacerTopBottomLarge />
                        <FlexBox style={{ overflow: "auto" }}>
                            {
                                props.purchases
                                &&
                                unique?.map((item, index) => (
                                    <SourceBlock
                                        sourceName={item}
                                        purchases={props.purchases}
                                    />
                                ))
                            }
                        </FlexBox>
                    </>
                }
                {
                    props.analytics
                    &&
                    props?.analytics.length > 0
                    &&
                    <>
                        <SpacerBottomXXL />
                        <SubBodyMediumG40>
                            Analytics
                        </SubBodyMediumG40>
                        <SpacerTopBottomLarge />
                    </>
                }
                {
                    props.purchases
                    &&
                    props.purchases.length > 0
                    &&
                    <>
                        <SpacerBottomXXL />
                        <SubBodyMediumG40>
                            Order History
                        </SubBodyMediumG40>
                        <SpacerTopBottomLarge />
                        <div style={{ overflow: 'auto' }}>
                            <table width='100%' frame="void" rules="columns" border="1px solid grey">
                                <thead>
                                    <tr>
                                        <th style={{
                                            padding: 8,
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#AAA9AE',
                                            border: '1px solid grey'
                                        }}>
                                            Date
                                        </th>
                                        <th style={{
                                            padding: 8,
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#AAA9AE',
                                            border: '1px solid grey'
                                        }}>
                                            Source
                                        </th>
                                        <th style={{
                                            padding: 8,
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#AAA9AE',
                                            border: '1px solid grey'
                                        }}>
                                            Qty
                                        </th>
                                        <th style={{
                                            padding: 8,
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#AAA9AE',
                                            border: '1px solid grey'
                                        }}>
                                            Price
                                        </th>
                                        <th style={{
                                            padding: 8,
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#AAA9AE',
                                            border: '1px solid grey'
                                        }}>
                                            Total Price
                                        </th>
                                        <th style={{
                                            padding: 8,
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#AAA9AE',
                                            border: '1px solid grey'
                                        }}>
                                            Unit Price
                                        </th>
                                        <th style={{
                                            padding: 8,
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#AAA9AE',
                                            border: '1px solid grey'
                                        }}>
                                            Discount
                                        </th>
                                        <th style={{
                                            padding: 8,
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#AAA9AE',
                                            border: '1px solid grey'
                                        }}>
                                            Payment
                                        </th>
                                        <th style={{
                                            padding: 8,
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#AAA9AE',
                                            border: '1px solid grey'
                                        }}>
                                            Start Date
                                        </th>
                                        <th style={{
                                            padding: 8,
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#AAA9AE',
                                            border: '1px solid grey'
                                        }}>
                                            End Date
                                        </th>
                                        <th style={{
                                            padding: 8,
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#AAA9AE',
                                            border: '1px solid grey'
                                        }}>
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.purchases.sort(function (a, b) {
                                            return new Date(b.createdAt.toDate().toDateString()) - new Date(a.createdAt.toDate().toDateString());
                                        }).map((item, index) => (
                                            <InvItemDetailsTbody
                                                key={index}
                                                id={props.id}
                                                itemType={props.itemType}
                                                name={props.name}
                                                brand={props.brand}
                                                variantQty={props.variantQty}
                                                variantUnit={props.variantUnit}
                                                createdAt={item.createdAt}
                                                sourceName={item.sourceName}
                                                sourceType={item.sourceType}
                                                price={item.price}
                                                discount={item.discount}
                                                paymentMode={item.paymentMode}
                                                startDate={item.startDate}
                                                endDate={item.endDate}
                                                mfdDate={item.mfdDate}
                                                qty={item.qty}
                                                wQty={item.wQty}
                                                mfdDate={item.mfdDate}
                                                createdAt={item.createdAt}
                                                purchases={props.purchases}
                                                width={props.width}
                                            />
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>
                }
            </InvItemDetailsContainer>
        </>
    )
}

import React from 'react'
import { IngredientTagContainer } from '../style/home.styles'
import { BodySemiBoldWhite, FlexBox, MagnifyIcon } from '../../../global/global.styles'
import { SpacerLeftRightMedium } from '../../../design/spacer/spacer'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Tooltip } from '@mui/material';

export const IngredientTag = (props) => {

    const removeIngredient = () => {
        props.setIngredientsArr(props.ingredientsArr.filter((x) => x.ingredientName !== props.name))
    }

    return (
        <>
            <IngredientTagContainer>
                <FlexBox>
                    <BodySemiBoldWhite>
                        {props.name.charAt(0).toUpperCase() + props.name.slice(1)}
                    </BodySemiBoldWhite>
                    <SpacerLeftRightMedium />
                    <Tooltip title="Remove" onClick={removeIngredient}>
                        <MagnifyIcon>
                            <CloseRoundedIcon
                                fontSize='small'
                                sx={{
                                    color: "white",
                                    cursor: "pointer"
                                }}
                            />
                        </MagnifyIcon>
                    </Tooltip>
                </FlexBox>
            </IngredientTagContainer>
        </>
    )
}

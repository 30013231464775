import React from 'react'
import { MealTableContainer } from '../style/home.styles'
import { MealTile } from './mealTile'
import { SpacerTopBottomLarge, SpacerTopBottomXL } from '../../../design/spacer/spacer'

export const MealTable = (props) => {

    console.log(props.data?.mealEntries)

    return (
        <>
            <MealTableContainer>
                {
                    props.data?.mealEntries?.map((item, index) => (
                        <div key={index}>
                            <MealTile
                                startTime={item.startTime}
                                endTime={item.endtime}
                                mealId={item.mealId}
                                meals={item.meals}
                            />
                            <SpacerTopBottomXL />
                        </div>
                    ))
                }
            </MealTableContainer>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { MealEntryCardContainer } from '../style/home.styles'
import { AbsoluteTopRight, BodySemiBold, FlexBox, FlexBoxSB, SubTitle } from '../../../global/global.styles'
import { SpacerLeftRightMedium, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall } from '../../../design/spacer/spacer'
import { FormControl, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { ObjectTile } from './objectTile'
import { IngCompTile } from './ingCompTile'

export const MealEntryCard = (props) => {

    useEffect(() => {
        props.onChangeMealId()
    }, [])

    const [specifiedMembers, setSpecifiedMembers] = useState([])
    const [addedMeals, setAddedMeals] = useState([])

    const handleSpecifyMembers = (m) => {
        setSpecifiedMembers([...specifiedMembers, m])
    }

    const handleAddMeal = (id, nm) => {
        setAddedMeals([...addedMeals, { id: id, mealName: nm }])
    }

    useEffect(() => {
        props.handleChangeMembers([...new Set(specifiedMembers)], props.index)
        props.handleChangeMeals(Object.values(
            addedMeals?.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
        ), props.index)
    }, [specifiedMembers, addedMeals])
    

    return (
        <>
            <MealEntryCardContainer>
                {
                    props.index > 0
                    &&
                    props.index + 1 === props.mealEntries.length
                    &&
                    <AbsoluteTopRight style={{ padding: 8 }}>
                        <Tooltip title="Remove meal" onClick={props.mealEntriesSubtractRow}>
                            <RemoveCircleOutlineOutlinedIcon
                                sx={{
                                    cursor: "pointer"
                                }}
                                color='error'
                            />
                        </Tooltip>
                    </AbsoluteTopRight>
                }
                <FlexBox>
                    <SubTitle style={{ textDecoration: "underline" }}>
                        {`Meal ${props.index + 1}`}
                    </SubTitle>
                </FlexBox>
                <SpacerTopBottomLarge />
                <FlexBoxSB>
                    <div>
                        <BodySemiBold>
                            Meal start time*
                        </BodySemiBold>
                        <SpacerTopBottomSmall />
                        <TextField
                            id="startTime"
                            name="startTime"
                            type="time"
                            size='small'
                            autoComplete='off'
                            sx={{
                                width: props.width > 450 ? 160 : 120
                            }}
                            value={props.startTime}
                            onChange={props.onChangeStartTime}
                            inputProps={{ style: { fontSize: '16px' } }}
                            placeholder={props.placeholderStartTime}
                        />
                    </div>
                    <div>
                        <BodySemiBold>
                            Meal end time*
                        </BodySemiBold>
                        <SpacerTopBottomSmall />
                        <TextField
                            id="endTime"
                            name="endTime"
                            type="time"
                            size='small'
                            autoComplete='off'
                            sx={{
                                width: props.width > 450 ? 160 : 120
                            }}
                            value={props.endTime}
                            onChange={props.onChangeEndTime}
                            inputProps={{ style: { fontSize: '16px' } }}
                            placeholder={props.placeholderEndTime}
                        />
                    </div>
                </FlexBoxSB>
                <SpacerTopBottomLarge />
                <div>
                    <BodySemiBold>
                        Specify members*
                    </BodySemiBold>
                    <SpacerTopBottomSmall />
                    <FormControl sx={{ width: props.width > 450 ? 120 : 160 }}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.scope}
                            onChange={props.onChangeScope}
                            size='small'
                            fullWidth
                            defaultValue=""
                        >
                            {props.mealMembers.map((option) => (
                                <MenuItem key={option.memberId} value={option.memberId} onClick={() => handleSpecifyMembers(option.memberId)}>
                                    <FlexBoxSB style={{ width: 180 }}>
                                        <div>
                                            {
                                                `${props.users.filter((x) => x.userId === option.memberId)[0].firstname} ${props.users.filter((x) => x.userId === option.memberId)[0].lastname}`
                                            }
                                        </div>
                                        <div>
                                            {
                                                `${props.users.filter((x) => x.userId === option.memberId)[0].familyId}`
                                            }
                                        </div>
                                    </FlexBoxSB>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <SpacerTopBottomMedium />
                <FlexBox>
                    {
                        [...new Set(specifiedMembers)].map((item, index) => (
                            <div key={index}>
                                <ObjectTile
                                    memberId={item}
                                    label={`${props.users.filter((x) => x.userId === item)[0].firstname} ${props.users.filter((x) => x.userId === item)[0].lastname}`}
                                    specifiedMembers={specifiedMembers}
                                    setSpecifiedMembers={setSpecifiedMembers}
                                />
                                <SpacerLeftRightMedium />
                            </div>
                        ))
                    }
                </FlexBox>
                <SpacerTopBottomLarge />
                <div>
                    <BodySemiBold>
                        Add meal*
                    </BodySemiBold>
                    <SpacerTopBottomSmall />
                    <FormControl sx={{ width: props.width > 450 ? 120 : 160 }}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.scope}
                            onChange={props.onChangeScope}
                            size='small'
                            fullWidth
                            defaultValue=""
                        >
                            {props.meals?.map((option) => (
                                <MenuItem key={option.id} value={option.id} onClick={() => handleAddMeal(option.id, option.mealName)}>
                                    {option.mealName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <SpacerTopBottomMedium />
                <FlexBox>
                    {
                        addedMeals
                        &&
                        Object.values(
                            addedMeals?.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
                        ).map((item, index) => (
                            <div key={index}>
                                <IngCompTile
                                    mealId={item.id}
                                    label={item.mealName}
                                    addedMeals={addedMeals}
                                    setAddedMeals={setAddedMeals}
                                    specifiedMembers={[...new Set(specifiedMembers)]}
                                    users={props.users}
                                    addedMealIndex={index}
                                />
                                <SpacerLeftRightMedium />
                            </div>
                        ))
                    }
                </FlexBox>
            </MealEntryCardContainer>
        </>
    )
}

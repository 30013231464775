import styled from 'styled-components'

// ----------------------------------------Containers----------------------------------------//

export const NoteBlockContainer = styled.div`
    padding-top: ${(props) => props.theme.space[2]};
    padding-bottom: ${(props) => props.theme.space[2]};
    padding-left: ${(props) => props.theme.space[2]};
    padding-right: ${(props) => props.theme.space[2]};
    border-radius: 8px;
`;

export const TimeTopBar = styled.div`
    padding: 30px;
    box-shadow: 0 1px 20px rgba(56, 40, 40, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 3;
`;

export const RoutineActBarContainer = styled.div`
    padding-top: ${(props) => props.theme.space[3]};
    padding-bottom: ${(props) => props.theme.space[3]};
    padding-left: ${(props) => props.theme.space[16]};
    padding-right: ${(props) => props.theme.space[16]};

    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[30]};
        padding-right: ${(props) => props.theme.space[30]};
    }
    @media only screen and (max-width: 1300px) {

    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }
    @media only screen and (max-width: 850px) {

    }
    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }

    @media only screen and (max-width: 450px) {
        
    }

    @media only screen and (max-width: 370px) {
        padding-top: ${(props) => props.theme.space[2]};
        padding-bottom: ${(props) => props.theme.space[2]};
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const RoutineBodyHeadBlock = styled.div`
    padding: 16px;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    width: 168px;
    height: 128px;
    background-color: white;
`;

export const RoutineBodyHeadText = styled.div`
    font-size: ${(props) => props.theme.fontSizes.childHeadline};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: 600;
`;

export const RoutineBodyTileBlockContainer = styled.div`
    border: 1px solid #E2E2E3;
    width: 168px;
    height: 56px;
    cursor: pointer;

    &:hover {
        transform: scale(1.03);
        transition: all 0.3s ease;
        box-shadow: 1px 1px 4px 0px rgba(107,107,107,0.75);
-webkit-box-shadow: 1px 1px 4px 0px rgba(107,107,107,0.75);
-moz-box-shadow: 1px 1px 4px 0px rgba(107,107,107,0.75);
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }  
`;

export const RoutineBodyHeadBlockDefault = styled.div`
    padding: 16px;
    border: 1px solid #AFCC00;
    border-radius: 2px;
    width: 220px;
    height: 128px;
    background-color: white;
`;

export const RoutineBodyTileBlockDefaultContainer = styled.div`
    border: 1px solid #AFCC00;
    width: 220px;
    height: 56px;
    cursor: pointer;
    position: relative;

    &:hover {
        transform: scale(1.03);
        transition: all 0.3s ease;
        box-shadow: 1px 1px 4px 0px rgba(107,107,107,0.75);
-webkit-box-shadow: 1px 1px 4px 0px rgba(107,107,107,0.75);
-moz-box-shadow: 1px 1px 4px 0px rgba(107,107,107,0.75);
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }  
`;

export const TimeIndicatorContainer = styled.div`
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 1;
    width: ${(props) => props.theme.space[16]};

    @media only screen and (min-width: 1800px) {
        width: ${(props) => props.theme.space[30]};
    }
    @media only screen and (max-width: 1300px) {

    }
    @media only screen and (max-width: 1100px) {
        width: ${(props) => props.theme.space[5]};
    }
    @media only screen and (max-width: 850px) {

    }
    @media only screen and (max-width: 650px) {
        width: ${(props) => props.theme.space[5]};
    }

    @media only screen and (max-width: 450px) {
        
    }

    @media only screen and (max-width: 370px) {
        width: ${(props) => props.theme.space[3]};
    }
`;

export const ToTimePosition = styled.div`
    position: absolute;
    left: -48px;
    top: -7px;
`;
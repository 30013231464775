import { ErrorMessage, Field } from 'formik';
import styled from 'styled-components'

// ----------------------------------------CONTAINERS----------------------------------------//

export const Container = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[16]};
    padding-right: ${(props) => props.theme.space[16]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[30]};
        padding-right: ${(props) => props.theme.space[30]};
    }
    @media only screen and (max-width: 1300px) {

    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }
    @media only screen and (max-width: 850px) {

    }
    @media only screen and (max-width: 650px) {
        padding-top: ${(props) => props.theme.space[3]};
        padding-bottom: ${(props) => props.theme.space[3]};
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }

    @media only screen and (max-width: 450px) {
        
    }

    @media only screen and (max-width: 370px) {
        padding-top: ${(props) => props.theme.space[2]};
        padding-bottom: ${(props) => props.theme.space[2]};
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const Squeezer = styled.div`
    padding-left: ${(props) => props.theme.space[16]};
    padding-right: ${(props) => props.theme.space[16]};
    
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[30]};
        padding-right: ${(props) => props.theme.space[30]};
    }
    @media only screen and (max-width: 1300px) {

    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }
    @media only screen and (max-width: 850px) {

    }
    @media only screen and (max-width: 650px) {

    }

    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }

    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const SqueezerHalf = styled.div`
    padding-left: ${(props) => props.theme.space[10]};
    padding-right: ${(props) => props.theme.space[10]};
`;

export const Sandwich = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    
    @media only screen and (min-width: 1800px) {
        
    }
    @media only screen and (max-width: 1300px) {

    }
    @media only screen and (max-width: 1100px) {

    }
    @media only screen and (max-width: 850px) {
        
    }
    @media only screen and (max-width: 450px) {
        padding-top: ${(props) => props.theme.space[3]};
        padding-bottom: ${(props) => props.theme.space[3]};
    }
`;

// ----------------------------------------TEXT----------------------------------------//

export const Headline = styled.div`
    font-size: ${(props) => props.theme.fontSizes.headline};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 600;
        
    @media only screen and (min-width: 1800px) {
        
    }
    @media only screen and (max-width: 1300px) {
        font-size: ${(props) => props.theme.fontSizes.heading}; 
    }
    @media only screen and (max-width: 1100px) {
        font-size: ${(props) => props.theme.fontSizes.subHeading};
    }
    @media only screen and (max-width: 850px) {
        
    }
    @media only screen and (max-width: 650px) {

    }
`;

export const HeadlineWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.headline};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: 600;

    @media only screen and (min-width: 1800px) {
        
    }
    @media only screen and (max-width: 1300px) {
        font-size: ${(props) => props.theme.fontSizes.heading}; 
    }
    @media only screen and (max-width: 1100px) {
        font-size: ${(props) => props.theme.fontSizes.subHeading};
    }
    @media only screen and (max-width: 850px) {

    }
    @media only screen and (max-width: 650px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }
`;

export const HeadlineMontMedium = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.headline};
    color: #013535;
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const SubHeadlineMontMedium = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: ${(props) => props.theme.fontSizes.subHeadline};
    color: #013535;
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const SubHeadline = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeadline};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 600;
`;

export const ChildHeadline = styled.div`
    font-size: ${(props) => props.theme.fontSizes.childHeadline};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 600;

    @media only screen and (max-width: 650px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }
`;

export const Heading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 600;

    @media only screen and (max-width: 650px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }
`;

export const HeadingWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.heading};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: 600;
`;

export const SubHeading = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 600;

    @media only screen and (max-width: 650px) {
        font-size: ${(props) => props.theme.fontSizes.title};
    }
`;

export const SubHeadingSemiBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export const SubHeadingPrimary = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subHeading};
    color: ${(props) => props.theme.colors.primary.blue};
    font-weight: 600;
`;

export const Title = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 400;
`;

export const TitleSemiBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 600;
`;

export const TitleSemiBoldG40 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: 600;
`;

export const TitleItalics = styled.div`
    font-size: ${(props) => props.theme.fontSizes.title};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 400;
    font-style: italic;
`;

export const SubTitle = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 400;
`;

export const SubTitleSemiBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 600;
`;

export const SubTitleSemiBoldG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: 600;
`;

export const SubTitleBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 700;
`;

export const SubTitleMediumG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: 500;
`;

export const SubTitleG40 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const SubTitleG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

export const SubTitleItalics = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 400;
    font-style: italic;
`;

export const SubTitleWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: 400;
`;

export const Body = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 400;
`;

export const BodyBoldG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: 700;
`;

export const BodyBoldWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: 700;
`;

export const BodySemiBoldWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: 600;
`;

export const BodySemiBoldG40 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: 600;
`;

export const BodySemiBoldG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: 600;
`;

export const BodyMediumG60 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey60};
    font-weight: ${(props) => props.theme.fontWeights.medium};
    font-weight: 500;
`;

export const SubBody = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 400;
`;

export const SubBodyBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 600;
`;

export const SubBodyMediumWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

export const BodySemiBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 600;
`;

export const SubBodyMediumG40 = styled.div`
    font-size: ${(props) => props.theme.fontSizes.body};
    color: ${(props) => props.theme.colors.text.grey40};
    font-weight: 500;
`;

export const Caption = styled.div`
    font-size: ${(props) => props.theme.fontSizes.caption};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 400;
`;

export const CaptionWhite = styled.div`
    font-size: ${(props) => props.theme.fontSizes.caption};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: 400;
`;

export const CaptionWhiteBold = styled.div`
    font-size: ${(props) => props.theme.fontSizes.caption};
    color: ${(props) => props.theme.colors.text.white};
    font-weight: 700;
`;

// ----------------------------------------FLEX----------------------------------------//

export const FlexBoxSB = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const FlexBoxSA = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const FlexBoxSE = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`;

export const FlexBox = styled.div`
    display: flex;
    align-items: center;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexColumnSA = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

export const FlexColumnSB = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const FlexColumnSE = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`;

export const FlexPageCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;
`;

export const FlexRowEnd = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

export const AbsoluteLogo = styled.div`
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%,-50%);
`;

// ----------------------------------------MAGNIFY----------------------------------------//

export const MagnifyIcon = styled.div`
    &:hover {
        transform: scale(1.08);
        transition: all 0.3s ease;
    }
    &:not(:hover) {
        transform: scale(1);
        transition: all 0.5s ease;
    }
`;

// ----------------------------------------ALIGNMENT----------------------------------------//

export const AlignCenter = styled.div`
    text-align: center;
`;

export const AbsoluteCenter = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
`;

export const AbsoluteBottomRight = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
`;

export const AbsoluteTopCenter = styled.div`
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%,0);
`;

export const AbsoluteTopRight = styled.div`
    position: absolute;
    right: 0;
    top: 0;
`;

// ----------------------------------------NAVBAR----------------------------------------//

export const NavBarContainer = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[12]};
    padding-right: ${(props) => props.theme.space[12]};
    ${'' /* position: fixed;
    top: 0;
    width: 100vw; */}

    @media only screen and (max-width: 850px) {
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }

    @media only screen and (max-width: 450px) {
        padding-top: ${(props) => props.theme.space[3]};
        padding-bottom: ${(props) => props.theme.space[3]};
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }

    @media only screen and (max-width: 370px) {
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const HamburgerContainer = styled.div`
    background-color: ${(props) => props.theme.colors.bg.black};
    border-radius: 4px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
`;

export const MenuContainer = styled.div`
    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

    @media only screen and (max-width: 400px) {
        
    }

    @media only screen and (max-width: 350px) {
        
    }
`;


// ----------------------------------------FOOTER----------------------------------------//

export const FooterContainer = styled.div`
    background-color: ${(props) => props.theme.colors.bg.black};
`;

// ----------------------------------------PASSWORDBLOCK----------------------------------------//

export const PasswordContainer = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subTitle};
    color: ${(props) => props.theme.colors.text.grey80};
    font-weight: 500;
`;

export const PasswordShowContainer = styled.div`
    
`;

// ----------------------------------------FORM----------------------------------------//

export const FormLabel = styled.div`
    font-size: ${(props) => props.theme.fontSizes.subBody};
    font-weight: 600;
    letter-spacing: 0.01em;
    color: #2A4D92;
`;

export const FormInput = styled(Field)`
    height: 32px;
    border: 1px solid #D8D9D9;
    padding: 2px 4px;
    width: 100%;
    border-radius: 4px;
`;

export const FormInputTextarea = styled.textarea`
    border: 1px solid #B1B7B7;
    width: 100%;
    padding: 4px 8px;
    border-radius: 4px;
    resize: none;
    vertical-align: top
`;

export const FormError = styled(ErrorMessage)`
    color: red;
    font-size: ${(props) => props.theme.fontSizes.caption};
`;

export const FormCurrency = styled.div`
    width: 32px;
    height: 32px;
    border: 1px solid #D8D9D9;
    position: relative;
    border-radius: 4px;
    color: #BBBDBE;
`;

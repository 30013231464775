import { CircularProgress, TextField, Tooltip } from '@mui/material'
import React from 'react'
import { AbsoluteTopRight, AlignCenter, Body, BodyMediumG60, BodySemiBold, BodySemiBoldG40, FlexBox, FlexBoxSB, MagnifyIcon } from '../../../global/global.styles'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerTopBottomMedium } from '../../../design/spacer/spacer';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { IngredientTableTbody } from './ingredientTableTbody';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

export const IngredientsTable = (props) => {

    return (
        <>
            <AbsoluteTopRight style={{ padding: 12 }}>
                <div onClick={props.handleClose}>
                    <CancelRoundedIcon color='error' sx={{ cursor: 'pointer' }} />
                </div>
            </AbsoluteTopRight>
            <FlexBoxSB>
                <BodySemiBoldG40 style={{ fontStyle: 'italic' }}>
                    All figures are considered in gm/l for every 100gm/l of ingredient in consideration
                </BodySemiBoldG40>
            </FlexBoxSB>
            <SpacerTopBottomMedium />
            <table width='100%'>
                <thead>
                    <tr>
                        <th style={{
                            padding: 8,
                            fontSize: 12,
                            fontWeight: 500,
                            color: '#373738',
                            border: '1px solid grey'
                        }}>
                            Sl no.
                        </th>
                        <th style={{
                            padding: 8,
                            fontSize: 12,
                            fontWeight: 500,
                            color: '#373738',
                            border: '1px solid grey'
                        }}>
                            Updated
                        </th>
                        <th style={{
                            padding: 8,
                            fontSize: 12,
                            fontWeight: 500,
                            color: '#373738',
                            border: '1px solid grey'
                        }}>
                            Ingredient
                        </th>
                        <th style={{
                            padding: 8,
                            fontSize: 12,
                            fontWeight: 500,
                            color: '#373738',
                            border: '1px solid grey'
                        }}>
                            Calories
                        </th>
                        <th style={{
                            padding: 8,
                            fontSize: 12,
                            fontWeight: 500,
                            color: '#373738',
                            border: '1px solid grey'
                        }}>
                            Carbohydrates
                        </th>
                        <th style={{
                            padding: 8,
                            fontSize: 12,
                            fontWeight: 500,
                            color: '#373738',
                            border: '1px solid grey'
                        }}>
                            Protein
                        </th>
                        <th style={{
                            padding: 8,
                            fontSize: 12,
                            fontWeight: 500,
                            color: '#373738',
                            border: '1px solid grey'
                        }}>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <AlignCenter>
                                <BodyMediumG60>
                                    {props.ingredients.length + 1}
                                </BodyMediumG60>
                            </AlignCenter>
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <BodyMediumG60>
                                {props.updated}
                            </BodyMediumG60>
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <TextField
                                id="ingredientName"
                                name="ingredientName"
                                type="text"
                                size='small'
                                autoComplete='off'
                                value={props.ingredientName}
                                onChange={props.onChangeIngredientName}
                                inputProps={{ style: { fontSize: '16px' } }}
                                placeholder={props.placeholderIngredientName}
                            />
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <TextField
                                id="calories"
                                name="calories"
                                type="number"
                                size='small'
                                autoComplete='off'
                                value={props.calories}
                                onChange={props.onChangeCalories}
                                inputProps={{ style: { fontSize: '16px' } }}
                                placeholder={props.placeholderCalories}
                            />
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <TextField
                                id="carbs"
                                name="carbs"
                                type="number"
                                size='small'
                                autoComplete='off'
                                value={props.carbs}
                                onChange={props.onChangeCarbs}
                                inputProps={{ style: { fontSize: '16px' } }}
                                placeholder={props.placeholderCarbs}
                            />
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <TextField
                                id="protein"
                                name="protein"
                                type="number"
                                size='small'
                                autoComplete='off'
                                value={props.protein}
                                onChange={props.onChangeProtein}
                                inputProps={{ style: { fontSize: '16px' } }}
                                placeholder={props.placeholderProtein}
                            />
                        </td>
                        <td style={{
                            padding: '8px 16px',
                            border: '1px solid grey'
                        }}>
                            <FlexBox>
                                {
                                    props.loading
                                        ?
                                        <Tooltip title="Saving" >
                                            <CircularProgress size="1rem" />
                                        </Tooltip>
                                        :
                                        <Tooltip title="Save" onClick={props.handleSubmit}>
                                            <MagnifyIcon>
                                                <CheckCircleRoundedIcon fontSize='small' color='success' sx={{ cursor: 'pointer' }} />
                                            </MagnifyIcon>
                                        </Tooltip>
                                }
                                <SpacerLeftRightMedium />
                                <Tooltip title="Clear" onClick={props.handleClear}>
                                    <MagnifyIcon>
                                        <DeleteForeverRoundedIcon fontSize='small' color='error' sx={{ cursor: 'pointer' }} />
                                    </MagnifyIcon>
                                </Tooltip>
                            </FlexBox>
                        </td>
                    </tr>
                    {
                        props.ingredients.map((item, index) => (
                            <IngredientTableTbody
                                key={index}
                                index={index}
                                width={props.width}
                                id={item.id}
                                updated={item.updated}
                                ingredientName={item.ingredientName}
                                calories={item.calories}
                                carbs={item.carbs}
                                protein={item.protein}
                            />
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}

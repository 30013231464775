import React, { useEffect, useState } from 'react'
import { ConsumInputContainer } from '../style/home.styles'
import { BodySemiBold, FlexBoxSA } from '../../../global/global.styles'
import { SpacerTopBottomSmall } from '../../../design/spacer/spacer'
import { Button, IconButton, TextField } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

export const ConsumInput = (props) => {

  const [consumValue, setConsumValue] = useState("")

  const handleAddConsum = () => {

    const consumObject = {
      memberId: props.memberId,
      member: props.member,
      consumValue: consumValue
    }

    props.setConsumption([...props.consumption, consumObject])

    setConsumValue("")
  }

  useEffect(() => {
    props.addedMeals[props.addedMealIndex].consumption = props.consumption
  }, [props.consumption])

  return (
    <>
      <ConsumInputContainer>
        <BodySemiBold>
          {props.member}
        </BodySemiBold>
        <SpacerTopBottomSmall />
        <TextField
          id="comsumValue"
          name="comsumValue"
          type="number"
          size='small'
          autoComplete='off'
          value={consumValue}
          onChange={(e) => setConsumValue(e.target.value)}
          inputProps={{ style: { fontSize: '16px' } }}
          placeholder={props.consumption.length > 0 && props.consumption?.filter((x) => x.memberId === props.memberId).length > 0 ? props.consumption?.filter((x) => x.memberId === props.memberId)[0].consumValue : ""}
          sx={{
            backgroundColor: "white",
            borderRadius: 1
          }}
        />
        <SpacerTopBottomSmall />
        <FlexBoxSA>
          <IconButton
            color="success"
            aria-label="Confirm"
            size='small'
            disabled={!consumValue}
            onClick={handleAddConsum}>
            <CheckCircleRoundedIcon
              fontSize='small' />
          </IconButton>
        </FlexBoxSA>
      </ConsumInputContainer>
    </>
  )
}

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Copyright from "./pages/others/copyright/copyright";
import Eula from "./pages/others/eula/eula";
import NotFound from "./pages/others/notFound/notFound";
import PrivacyPolicy from "./pages/others/privacyPolicy/privacyPolicy";
import TermsOfUse from "./pages/others/termsOfUse/termsOfUse";

import { Login } from "./pages/login/login";
import { Time } from "./pages/time/time";
import { Profile } from "./pages/profile/profile";
import { Archive } from "./pages/archive/archive";
import { Health } from "./pages/health/health";
import { Home } from "./pages/home/home";
import { Recreation } from "./pages/recreation/recreation";
import { Portfolio } from "./pages/portfolio/portfolio";

import ProtectedRoute from "./secure/protectedRoute";
import useWindowDimensions from "./global/components/screenSize";
import { Inventory } from "./pages/home/pages/inventory";
import { MealPlan } from "./pages/home/pages/mealPlan";
import { Dashboard } from "./pages/dashboard/dashboard";
import { Tasks } from "./pages/time/components/tasks";
import { Routine } from "./pages/time/components/routine";

function App() {

  const { width, height } = useWindowDimensions();

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
      <Route path="/health" element={<ProtectedRoute><Health /></ProtectedRoute>} />
      <Route path="home" element={<ProtectedRoute>
        <Home
          navigate={navigate}
        />
      </ProtectedRoute>}>
        <Route path="inventory" element={
          <Inventory
            width={width}
            location={location}
          />} />
        <Route path="meal-plan" element={
          <MealPlan
            width={width}
            location={location}
          />} />
      </Route>
      <Route path="/time" element={<ProtectedRoute>
        <Time
          navigate={navigate}
          width={width}
        /></ProtectedRoute>} />
      <Route path="/tasks" element={<ProtectedRoute>
        <Tasks
          navigate={navigate}
          width={width}
        /></ProtectedRoute>} />
        <Route path="/archive" element={<ProtectedRoute>
        <Archive
          navigate={navigate}
          width={width}
        /></ProtectedRoute>} />
        <Route path="/routine" element={<ProtectedRoute>
        <Routine
          navigate={navigate}
          width={width}
        /></ProtectedRoute>} />
      <Route path="/recreation" element={<ProtectedRoute><Recreation /></ProtectedRoute>} />
      <Route path="/portfolio" element={<ProtectedRoute><Portfolio /></ProtectedRoute>} />
      <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/termsOfUse" element={<TermsOfUse />} />
      <Route path="/eula" element={<Eula />} />
      <Route path="/copyright" element={<Copyright />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;

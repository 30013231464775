import React, { useEffect, useId } from 'react'
import { AlignCenter, Container, FlexBox, Heading, Headline } from '../../../global/global.styles'
import { MealPlanMenu } from '../components/mealPlanMenu'
import { AuthContext } from '../../../context/auth/auth.provider';
import { ApiContext } from '../../../context/api/api.provider';
import { UpdateContext } from '../../../context/update/update.provider';
import { useContext } from 'react';
import { Backdrop, Box, Fade, Grid, Modal } from '@mui/material';
import { useState } from 'react';
import { IngredientsTable } from '../components/ingredientsTable';
import moment from 'moment';
import { addDoc } from 'firebase/firestore';
import { GenericAlert } from '../../../global/components/genericAlert';
import { AddNewMealForm } from '../components/addNewMealForm';
import { MemberDetails } from '../components/memberDetails';
import axios from 'axios';
import { CreateMealPlan } from '../components/createMealPlan';
import { SpacerBottomSmall, SpacerTopBottomLarge, SpacerTopBottomXXXL } from '../../../design/spacer/spacer';
import { MealTable } from '../components/mealTable';
import { MealDay } from '../components/mealDay';

const mockIngredients = [
    {
        label: "Ragi",
        value: "ragi"
    },
    {
        label: "Apple",
        value: "apple"
    },
    {
        label: "Onion",
        value: "onion"
    },
    {
        label: "Cashew",
        value: "cashew"
    },
    {
        label: "Salt",
        value: "salt"
    },
]

const genderArr = [
    {
        label: "Male",
        value: "male"
    },
    {
        label: "Female",
        value: "female"
    },
    {
        label: "Other",
        value: "other"
    }
]

export const MealPlan = (props) => {

    const { user } = useContext(AuthContext);
    const {
        users,
        profile,
        ingredients,
        ingredientsCollectionRef,
        meals,
        mealsCollectionRef,
        mealMembers,
        mealMembersCollectionRef,
        mealPlans,
        mealPlansCollectionRef,
    } = useContext(ApiContext);
    const { refresh, setRefresh } = useContext(UpdateContext);

    const today = new Date()

    const largeModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        borderRadius: '8px',
        backgroundColor: 'white',
        p: props.width > 650 ? 4 : 3,
        overflowY: 'auto',
        width: props.width > 650 ? "80%" : "90%",
        height: props.width > 650 ? "inherit" : "60%"
    };

    const formModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        borderRadius: '8px',
        backgroundColor: 'white',
        p: props.width > 650 ? 4 : 3,
        overflowY: 'auto',
    };

    const largeModalStyleGrey = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        borderRadius: '8px',
        backgroundColor: '#F8F8F8',
        p: props.width > 650 ? 4 : 3,
        overflowY: 'auto',
        width: props.width > 650 ? "80%" : "90%",
        height: props.width > 650 ? "inherit" : "60%"
    };

    //--------------------------------------------------------------------------------------------------------- Ingredients

    const [ingredientsTableModal, setIngredientsTableModal] = useState(false)
    const [loadingIngredients, setLoadingIngredients] = useState("");

    const [ingredientName, setIngredientName] = useState("")
    const [updated, setUpdated] = useState(moment(today).format("DD/MM/YY, h:mm:ss a"))
    const [calories, setCalories] = useState("")
    const [carbs, setCarbs] = useState("")
    const [protein, setProtein] = useState("")

    const handleSubmitIngredients = async () => {
        setLoadingIngredients(false);

        const docData = {
            ingredientName: ingredientName,
            calories: calories,
            carbs: carbs,
            protein: protein,
            keywords: `${ingredientName},`,
            updated: updated,
            userId: user.uid,
            familyId: profile?.familyId,
            createdAt: new Date()
        }

        await addDoc(ingredientsCollectionRef, docData);

        setIngredientName("")
        setCalories("")
        setCarbs("")
        setProtein("")
        setRefresh(!refresh);
        setLoadingIngredients(false);
    }

    const handleClearIngredients = () => {
        setIngredientName("")
        setCarbs("")
        setCalories("")
        setProtein("")
    }

    //--------------------------------------------------------------------------------------------------------- AddNewMeal

    const [addNewMealModal, setAddNewMealModal] = useState(false)
    const [loadingAddNewMeal, setLoadingAddNewMeal] = useState("");

    const [mealName, setMealName] = useState("")
    const [mealTimeFrom, setMealTimeFrom] = useState("")
    const [mealTimeTo, setMealTimeTo] = useState("")
    const [mealAvgWeight, setMealAvgWeight] = useState("")
    const [mealIngredients, setMealIngredients] = useState("")
    const [ingredientsArr, setIngredientsArr] = useState([])

    const addIngredientsArr = (e) => {
        setMealIngredients(e.target.value)
        setIngredientsArr([...ingredientsArr, { ingredientName: e.target.value }])
    }

    const closeAddNewMealModal = () => {
        setAddNewMealModal(false)
        setMealIngredients("")
        setIngredientsArr([])
    }

    const handleSubmitMeals = async () => {
        setLoadingAddNewMeal(false);

        const docData = {
            mealName: mealName,
            mealTimeFrom: mealTimeFrom,
            mealTimeTo: mealTimeTo,
            mealAvgWeight: mealAvgWeight,
            ingredients: ingredientsArr,
            keywords: `${mealName}`,
            userId: user.uid,
            familyId: profile?.familyId,
            createdAt: new Date()
        }

        await addDoc(mealsCollectionRef, docData);

        setMealName("")
        setMealTimeFrom("")
        setMealTimeTo("")
        setMealIngredients("")
        setIngredientsArr([])
        setMealAvgWeight("")
        setRefresh(!refresh);
        setLoadingAddNewMeal(false);
        setAddNewMealModal(false)
    }

    //--------------------------------------------------------------------------------------------------------- MemberDetails

    const [memberDetailsModal, setMemberDetailsModal] = useState(false)
    const [loadingMemberDetails, setLoadingMemberDetails] = useState("");

    const [resetAmbit, setResetAmbit] = useState("")
    const [file, setFile] = useState("")
    const [gender, setGender] = useState("")
    const [age, setAge] = useState("")
    const [weight, setWeight] = useState("")
    const [member, setMember] = useState("")
    const [dietStyle, setDietStyle] = useState("")
    const [carbValue, setCarbValue] = useState("")
    const [proteinValue, setProteinValue] = useState("")
    const [fatValue, setFatValue] = useState("")

    const openMemberDetailsModal = () => {
        setMemberDetailsModal(true)
        setResetAmbit("")
    }

    const closeMemberDetailsModal = () => {
        setMemberDetailsModal(false)
        setResetAmbit("empty")
    }

    const mealGroupId = useId()

    const handleSubmitMembers = async () => {
        setLoadingMemberDetails(false);

        if (file) {

            const data = new FormData();
            data.append("file", file)
            data.append("upload_preset", "growth")
            data.append("cloud_name", "ddmaoqwfd")

            axios.post("https://api.cloudinary.com/v1_1/ddmaoqwfd/image/upload", data)
                .then((response) => {
                    const data = response.data
                    // console.log(data)
                    const imageUrl = data.url;
                    // console.log(imageUrl)
                    const docData = {
                        memberId: member,
                        gender: gender,
                        age: age,
                        weight: weight,
                        mealGroupId: mealGroupId,
                        dp: imageUrl,
                        dietStyle: dietStyle,
                        nutrientsRequired: {
                            carbohydrate: carbValue,
                            protein: proteinValue,
                            fats: fatValue
                        },
                        userId: user.uid,
                        familyId: profile?.familyId,
                        createdAt: new Date()
                    }

                    addDoc(mealMembersCollectionRef, docData);

                    setMember("")
                    setGender("")
                    setAge("")
                    setWeight("")
                    setDietStyle("")
                    setCarbValue("")
                    setProteinValue("")
                    setFatValue("")
                    setResetAmbit("empty")
                    setRefresh(!refresh);
                    setLoadingMemberDetails(false);

                }).catch((err) => {
                    console.log(err)
                })

        } else {
            const docData = {
                memberId: member,
                gender: gender,
                age: age,
                weight: weight,
                mealGroupId: mealGroupId,
                dietStyle: dietStyle,
                nutrientsRequired: {
                    carbohydrate: carbValue,
                    protein: proteinValue,
                    fats: fatValue
                },
                userId: user.uid,
                familyId: profile?.familyId,
                createdAt: new Date()
            }

            await addDoc(mealMembersCollectionRef, docData);

            setMember("")
            setGender("")
            setAge("")
            setWeight("")
            setDietStyle("")
            setCarbValue("")
            setProteinValue("")
            setFatValue("")
            setResetAmbit("empty")
            setRefresh(!refresh);
            setLoadingMemberDetails(false);
        }
    }

    //--------------------------------------------------------------------------------------------------------- MealPlan

    const [createMealPlanModal, setCreateMealPlanModal] = useState(false)
    const [loadingCreateMealPlan, setLoadingCreateMealPlan] = useState("");

    const [formPart, setFormPart] = useState(1)
    const [timeframe, setTimeframe] = useState("")
    const [mealsList, setMealsList] = useState([])

    const [finishValidation, setFinishValidation] = useState(true)

    const openCreateMealPlanModal = () => {
        setCreateMealPlanModal(true)
        setFormPart(1)
    }

    const closeCreateMealPlanModal = () => {
        setCreateMealPlanModal(false)
        setTimeframe("")
        setMealsList([])
        setFinishValidation(true)
    }

    const handleSubmitMealPlan = () => {
        setLoadingCreateMealPlan(true)

        mealsList.map((item) => {
            addDoc(mealPlansCollectionRef, item)
        })

        setTimeframe("")
        setMealsList([])
        setFinishValidation(true)
        setLoadingCreateMealPlan(false)
        setRefresh(!refresh);
        closeCreateMealPlanModal()
    }

    useEffect(() => {
        if (mealsList.every((i) => i.valid === true)) {
            setFinishValidation(false)
        } else setFinishValidation(true)
    }, [mealsList])

    //--------------------------------------------------------------------------------------------------------- Main page

    const [mealTablePage, setmealTablePage] = useState(0)

    return (
        <>
            <Container>
                <MealPlanMenu
                    familyId={profile?.familyId}
                    ingredientsTable={() => setIngredientsTableModal(true)}
                    addNewMeal={() => setAddNewMealModal(true)}
                    memberDetails={openMemberDetailsModal}
                    createMealPlan={openCreateMealPlanModal}
                />
                <SpacerTopBottomXXXL />
                <Grid container>
                    <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <MealDay
                            width={props.width}
                            mealTablePage={mealTablePage}
                            previous={() => setmealTablePage(mealTablePage - 1)}
                            next={() => setmealTablePage(mealTablePage + 1)}
                            todayClicked={() => setmealTablePage(0)}
                            data={mealPlans.filter((x) => x.date === moment(today).add(mealTablePage, 'days').format("DD-MMM-YY"))[0]}
                            date={moment(today).add(mealTablePage, 'days').format("DD")}
                            month={moment(today).format("MMM")}
                        />
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>

                    </Grid>
                </Grid>
            </Container>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={ingredientsTableModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={ingredientsTableModal}>
                    <Box sx={largeModalStyle}>
                        <div style={{ overflow: 'auto' }}>
                            <IngredientsTable
                                width={props.width}
                                ingredients={ingredients}
                                ingredientName={ingredientName}
                                onChangeIngredientName={(e) => setIngredientName(e.target.value)}
                                updated={updated}
                                onChangeUpdated={(e) => setUpdated(e.target.value)}
                                calories={calories}
                                onChangeCalories={(e) => setCalories(e.target.value)}
                                carbs={carbs}
                                onChangeCarbs={(e) => setCarbs(e.target.value)}
                                protein={protein}
                                onChangeProtein={(e) => setProtein(e.target.value)}
                                loadingIngredients={loadingIngredients}
                                handleSubmitIngredients={handleSubmitIngredients}
                                handleClearIngredients={handleClearIngredients}
                                handleClose={() => setIngredientsTableModal(false)}
                            />
                        </div>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={addNewMealModal}
                onClose={closeAddNewMealModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={addNewMealModal}>
                    <Box sx={formModalStyle}>
                        <AddNewMealForm
                            width={props.width}
                            mealName={mealName}
                            onChangeMealName={(e) => setMealName(e.target.value)}
                            mealTimeFrom={mealTimeFrom}
                            onChangeMealTimeFrom={(e) => setMealTimeFrom(e.target.value)}
                            mealTimeTo={mealTimeTo}
                            onChangeMealTimeTo={(e) => setMealTimeTo(e.target.value)}
                            mockIngredients={mockIngredients}
                            mealAvgWeight={mealAvgWeight}
                            onChangeMealAvgWeight={(e) => setMealAvgWeight(e.target.value)}
                            mealIngredients={mealIngredients}
                            onChangeMealIngredients={(e) => addIngredientsArr(e)}
                            ingredientsArr={ingredientsArr}
                            setIngredientsArr={setIngredientsArr}
                            handleSubmit={handleSubmitMeals}
                            loading={loadingAddNewMeal}
                        />
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={memberDetailsModal}
                onClose={closeMemberDetailsModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={memberDetailsModal}>
                    <Box sx={largeModalStyle}>
                        <MemberDetails
                            width={props.width}
                            users={users}
                            mealMembers={mealMembers}
                            genderArr={genderArr}
                            allMembers={users.filter((x) => x.userId !== user.uid)}
                            resetAmbit={resetAmbit}
                            file={file}
                            onChangeFile={(e) => setFile(e.target.files[0])}
                            gender={gender}
                            onChangeGender={(e) => setGender(e.target.value)}
                            age={age}
                            onChangeAge={(e) => setAge(e.target.value)}
                            weight={weight}
                            onChangeWeight={(e) => setWeight(e.target.value)}
                            member={member}
                            onChangeMember={(e) => setMember(e.target.value)}
                            dietStyle={dietStyle}
                            onChangeDietStyle={(e) => setDietStyle(e.target.value)}
                            carbValue={carbValue}
                            onChangeCarbValue={(e) => setCarbValue(e.target.value)}
                            proteinValue={proteinValue}
                            onChangeProteinValue={(e) => setProteinValue(e.target.value)}
                            fatValue={fatValue}
                            onChangeFatValue={(e) => setFatValue(e.target.value)}
                            saveMember={handleSubmitMembers}
                        />
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={createMealPlanModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={createMealPlanModal}>
                    <Box sx={largeModalStyleGrey}>
                        <CreateMealPlan
                            width={props.width}
                            mealMembers={mealMembers}
                            meals={meals}
                            users={users}
                            timeframe={timeframe}
                            setTimeframe={setTimeframe}
                            cancelCreateMealPlanModal={closeCreateMealPlanModal}
                            formPart={formPart}
                            setFormPart={setFormPart}
                            handleSubmit={handleSubmitMealPlan}
                            mealsList={mealsList}
                            setMealsList={setMealsList}
                            finishValidation={finishValidation}
                            setFinishValidation={setFinishValidation}
                        />
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

export const colors = {
  accents: {
    blue: "#307EF3",
  },
  bg: {
    white: "#FFFFFF",
    black: "#000000",
    grey: "#F4F6F6"
  },
  text: {
    white: "#FFFFFF",
    black: "#000000",
    grey80: "#373738",
    grey60: "#6E6D70",
    grey40: "#AAA9AE",
    grey20: "#E2E2E3"
  }
};
import React, { useState } from 'react'
import { RoutineBodyTileBlockContainer, RoutineBodyTileBlockDefaultContainer, ToTimePosition } from '../style/time.styles'
import { SubBody } from '../../../global/global.styles'
import { Backdrop, Box, Fade, Modal } from '@mui/material';

export const RoutineBodyTileBlock = (props) => {

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        borderRadius: '8px',
        backgroundColor: 'white',
        p: 4,
        overflowY: 'auto'
    };

    const [routineModal, setRoutineModal] = useState(false)

    return (
        <>
            {
                props.ambit === "default"
                    ?
                    <RoutineBodyTileBlockDefaultContainer onClick={() => setRoutineModal(true)}>
                        <ToTimePosition>
                            <SubBody>
                                {`${1 + props.index}00 hrs`}
                            </SubBody>
                        </ToTimePosition>
                    </RoutineBodyTileBlockDefaultContainer>
                    :
                    <RoutineBodyTileBlockContainer onClick={() => setRoutineModal(true)}>

                    </RoutineBodyTileBlockContainer>
            }
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={routineModal}
                onClose={() => setRoutineModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={routineModal}>
                    <Box sx={modalStyle}>

                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

// DEFAULT
import React from 'react'
import Navbar from '../../global/components/navbar'
import { Container } from '../../global/global.styles'
import { TaskCorrelateWidget } from './components/taskCorrelateWidget'
import { AuthContext } from '../../context/auth/auth.provider'
import { useContext } from 'react'
import { ApiContext } from '../../context/api/api.provider'

// INTERFACE


// MAIN
export const Dashboard = (props) => {

    const { user } = useContext(AuthContext);
    const { taskBoards } = useContext(ApiContext);

    return (
        <>
            <Navbar />
            <Container>
                {
                    taskBoards &&
                    <TaskCorrelateWidget
                        user={user}
                        taskBoards={taskBoards}
                    />
                }
            </Container>
        </>
    )
}
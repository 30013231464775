import { Button, TextField } from '@mui/material'
import React from 'react'
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall, SpacerTopBottomXL } from '../../../design/spacer/spacer'
import { BodySemiBold, Title } from '../../../global/global.styles'

export const NewCredForm = (props) => {
    return (
        <>
            <Title>
                Enter new credentials
            </Title>
            <SpacerTopBottomXL />
            <BodySemiBold>
                Portal
            </BodySemiBold>
            <SpacerTopBottomSmall />
            <TextField
                id="portal"
                name="portal"
                type="text"
                size='small'
                autoComplete='off'
                fullWidth
                value={props.portal}
                onChange={props.onChangePortal}
                inputProps={{ style: { fontSize: '16px'} }}
                placeholder={props.placeholderPortal}
            />
            <SpacerTopBottomLarge />
            <BodySemiBold>
                Username/email
            </BodySemiBold>
            <SpacerTopBottomSmall />
            <TextField
                id="username"
                name="username"
                type="text"
                size='small'
                autoComplete='off'
                fullWidth
                value={props.username}
                onChange={props.onChangeUsername}
                inputProps={{ style: { fontSize: '16px'} }}
                placeholder={props.placeholderUsername}
            />
            <SpacerTopBottomLarge />
            <BodySemiBold>
                Password
            </BodySemiBold>
            <SpacerTopBottomSmall />
            <TextField
                id="password"
                name="password"
                type="text"
                size='small'
                autoComplete='off'
                fullWidth
                value={props.password}
                onChange={props.onChangePassword}
                inputProps={{ style: { fontSize: '16px'} }}
                placeholder={props.placeholderPassword}
            />
            <SpacerTopBottomLarge />
            <BodySemiBold>
                Category
            </BodySemiBold>
            <SpacerTopBottomSmall />
            <TextField
                id="category"
                name="category"
                type="text"
                size='small'
                autoComplete='off'
                fullWidth
                value={props.category}
                onChange={props.onChangeCategory}
                inputProps={{ style: { fontSize: '16px'} }}
                placeholder={props.placeholderCategory}
            />
            <SpacerTopBottomLarge />
            <BodySemiBold>
                Other Info
            </BodySemiBold>
            <SpacerTopBottomSmall />
            <TextField
                id="other"
                name="other"
                type="text"
                size='small'
                autoComplete='off'
                fullWidth
                value={props.other}
                onChange={props.onChangeOther}
                inputProps={{ style: { fontSize: '16px'} }}
                placeholder={props.placeholderOther}
            />
            <SpacerTopBottomLarge />
            <Button
                variant='contained'
                color={props.btnColor}
                fullWidth
                sx={{
                    textTransform: 'none'
                }}
                onClick={props.handleSave}>
                {props.btnTitle}
            </Button>
        </>
    )
}

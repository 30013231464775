import React, { useEffect, useState } from 'react'
import { BodyMediumG60, FlexBox } from '../../../global/global.styles'
import { RoutineActBarContainer, RoutineBodyHeadBlock, RoutineBodyHeadBlockDefault, RoutineBodyHeadText } from '../style/time.styles'
import { SpacerTopBottomSmall } from '../../../design/spacer/spacer'
import moment from 'moment'
import { RoutineBodyTileBlock } from './routineBodyTileBlock'

export const RoutineBody = (props) => {

    const [scrolled, setScrolled] = useState(0);

    useEffect(() => {
        const onScroll = () => setScrolled(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);


    return (
        <>
            <RoutineActBarContainer style={{ overflowX: "auto", paddingTop: 0 }}>
                <FlexBox>
                    <div style={
                        scrolled > 0
                            ?
                            { position: "sticky", left: 0, zIndex: 1, backgroundColor: "white" }
                            :
                            { position: "fixed", zIndex: 1, backgroundColor: "white" }
                    }>
                        <RoutineBodyHeadBlockDefault>
                            Default
                        </RoutineBodyHeadBlockDefault>
                        {
                            Array(24).fill().map((nos, index) => (
                                <RoutineBodyTileBlock
                                    key={index}
                                    index={index}
                                    ambit="default"

                                />
                            ))
                        }
                    </div>
                    {
                        scrolled === 0
                        &&
                        <div
                            style={{ marginRight: 220 }}
                        />
                    }
                    {
                        Array(90).fill().map((item, index) => (
                            <div key={index}>
                                <RoutineBodyHeadBlock>
                                    <BodyMediumG60>
                                        {moment(props.today, "DD").add(index, 'days').format('ddd')}
                                    </BodyMediumG60>
                                    <SpacerTopBottomSmall />
                                    <RoutineBodyHeadText>
                                        {moment(props.today, "DD").add(index, 'days').format('DD')}
                                    </RoutineBodyHeadText>
                                </RoutineBodyHeadBlock>
                                {
                                    Array(24).fill().map((nos) => (
                                        <RoutineBodyTileBlock />
                                    ))
                                }
                            </div>
                        ))
                    }
                </FlexBox>
            </RoutineActBarContainer>
        </>
    )
}

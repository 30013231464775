// DEFAULT
import React from 'react'
import Navbar from '../../global/components/navbar'

// INTERFACE


// MAIN
export const Portfolio = () => {
    return (
        <>
        <Navbar />
        </>
    )
}
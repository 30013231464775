import React, { useContext, useState } from 'react'
import { AddImageContainer, InnerCircle, InnerRectangle, InvBlockContainer, InvBlockQtyCircle, InvBlockQtyRectangle, InvBlockWrapper } from '../style/home.styles'
import { AbsoluteCenter, Body, BodyBoldG60, BodySemiBold, FlexBox, FlexBoxSA, FlexBoxSB, FlexColumnSB, MagnifyIcon, SubBody, SubBodyBold, SubTitleSemiBold } from '../../../global/global.styles'
import { ImgHolder } from '../../../global/components/imgHolder'
import { SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall, SpacerTopBottomXXL } from '../../../design/spacer/spacer'
import { NumericFormat } from 'react-number-format'
import { Backdrop, Box, Button, Fade, Modal, Tooltip } from '@mui/material'
import { InvItemDetails } from './invItemDetails'
import { NewPurchaseForm } from './newPurchaseForm'
import { addDoc, collection, deleteDoc, doc, endBefore, serverTimestamp, setDoc } from 'firebase/firestore'
import { db, storage } from '../../../firebase/firebase'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { AuthContext } from '../../../context/auth/auth.provider'
import { ApiContext } from '../../../context/api/api.provider'
import { UpdateContext } from '../../../context/update/update.provider'
import { DeleteAlert } from '../../../global/components/deleteAlert'
import dpPlaceholder from '../../../assets/images/basket.png'
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save';
import { NewItemForm } from './newItemForm'
import axios from 'axios'


const units = [
    {
        value: 'gm',
        label: 'gm',
    },
    {
        value: 'ml',
        label: 'ml',
    },
    {
        value: 'l',
        label: 'l',
    },
];

const medium = [
    {
        value: 'Online',
        label: 'Online',
    },
    {
        value: 'Retail',
        label: 'Retail',
    },
    {
        value: 'Distributor',
        label: 'Distributor',
    },
    {
        value: 'Manufacturer',
        label: 'Manufacturer',
    },
];

const paymentModeArr = [
    {
        value: 'UPI',
        label: 'UPI',
    },
    {
        value: 'Cash',
        label: 'Cash',
    },
    {
        value: 'Card',
        label: 'Card',
    },
    {
        value: 'Other',
        label: 'Other',
    },
];

const scopeArr = [
    {
        value: 'kitchen',
        label: 'Kitchen',
    },
    {
        value: 'bathroom',
        label: 'Bathroom',
    },
    {
        value: 'dining',
        label: 'Dining',
    },
    {
        value: 'bedroom',
        label: 'Bedroom',
    },
    {
        value: 'study',
        label: 'Study',
    },
    {
        value: 'utility',
        label: 'Utility',
    },
    {
        value: 'garage',
        label: 'Garage',
    },
    {
        value: 'balcony',
        label: 'Balcony',
    },
];

export const InvBlock = (props) => {

    const { user } = useContext(AuthContext);
    const { inventory, inventoryCollectionRef, profile } = useContext(ApiContext);
    const { refresh, setRefresh } = useContext(UpdateContext);

    const itemDetailsModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        borderRadius: '8px',
        backgroundColor: 'white',
        p: props.width > 450 ? 4 : 2,
        // width: props.width > 450 ? '400px' : props.width > 350 ? '320px' : '280px',
        // height: props.width > 850 ? 'inherit' : props.width > 650 ? '40vh' : props.width > 370 ? '48vh' : '60vh',
        overflowY: 'auto',
        width: props.width > 450 ? '70%' : '90%',
        height: props.purchases ? '70%' : "50%"
    };

    const itemModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        borderRadius: '8px',
        backgroundColor: 'white',
        p: 4,
        width: props.width > 450 ? '400px' : props.width > 350 ? '320px' : '280px',
        height: props.width > 850 ? 'inherit' : props.width > 650 ? '40vh' : props.width > 370 ? '48vh' : '60vh',
        overflowY: 'auto'
    };

    const [itemDetailsModal, setItemDetailsModal] = useState(false);
    const [newPurchaseModal, setNewPurchaseModal] = useState(false);

    const handleNewPurchaseClicked = () => {

        setItemDetailsModal(false)
        setNewPurchaseModal(true)
    }

    const handleCancel = () => {

        setNewPurchaseModal(false)
        setItemDetailsModal(true)
    }

    const [itemModal, setItemModal] = useState(false);
    const [name, setName] = useState("");
    const [scope, setScope] = useState("");
    const [brand, setBrand] = useState("");
    const [variantQty, setVariantQty] = useState("");
    const [variantUnit, setVariantUnit] = useState("");
    const [validity, setValidity] = useState("");
    const [price, setPrice] = useState("");
    const [sourceType, setSourceType] = useState("");
    const [sourceName, setSourceName] = useState("");
    const [paymentMode, setPaymentMode] = useState("");
    const [discount, setDiscount] = useState("");
    const [qty, setQty] = useState("");
    const [wQty, setWQty] = useState("");
    const [startDate, setStartDate] = useState("");
    const [mfdDate, setMfdDate] = useState("");
    const [file, setFile] = useState("");
    const [loading, setLoading] = useState("");
    const [progress, setProgress] = useState("");
    const [editDetailsModal, setEditDetailsModal] = useState(false);

    const handleUpdate = async () => {

        setLoading(true);

        const purchaseObj = {
            price: price,
            sourceType: sourceType,
            sourceName: sourceName,
            paymentMode: paymentMode,
            discount: discount,
            qty: qty,
            wQty: wQty,
            startDate: startDate,
            mfdDate: mfdDate,
            createdAt: new Date()
        }

        const firstPurchase = [
            {
                price: price,
                sourceType: sourceType,
                sourceName: sourceName,
                paymentMode: paymentMode,
                discount: discount,
                qty: qty,
                wQty: wQty,
                startDate: startDate,
                mfdDate: mfdDate,
                createdAt: new Date()
            }
        ]

        const addPurchase = props.purchases && [...props.purchases, purchaseObj]

        const updateData = {
            keywords: `${props.name},${props.brand},${sourceName},${sourceType},${paymentMode}`,
            updatedAt: serverTimestamp(),
            purchases: props.purchases ? addPurchase : firstPurchase
        }

        const docRef = doc(db, "inventory", props.id);
        await setDoc(docRef, updateData, {
            merge: true
        }).then(() => console.log("Document updated"));

        setRefresh(!refresh);
        setPrice("");
        setSourceType("");
        setSourceName("");
        setPaymentMode("");
        setDiscount("");
        setQty("")
        setWQty("")
        setStartDate("")
        setMfdDate("")
        setLoading(false);
        setNewPurchaseModal(false)
        setItemDetailsModal(false)
    }

    const handleSubmit = () => {

        if (qty || wQty && price && sourceName) {
            handleUpdate()
        } else {
            alert("Some field values are missing in the form")
        }
    }

    const handleSubmitUpdatedPiece = () => {
        setLoading(true);

        if (file) {

            const data = new FormData();
            data.append("file", file)
            data.append("upload_preset", "growth")
            data.append("cloud_name", "ddmaoqwfd")

            axios.post("https://api.cloudinary.com/v1_1/ddmaoqwfd/image/upload", data)
                .then((response) => {
                    const data = response.data
                    // console.log(data)
                    const imageUrl = data.url;
                    // console.log(imageUrl)
                    const docDataUpdate = {
                        name: name ? name : props.name,
                        validity: validity ? validity : props.validity,
                        scope: scope ? scope : props.scope,
                        dp: imageUrl,
                        keywords: `${name ? name : props.name},${brand ? brand : props.brand},${scope ? scope : props.scope},${props.itemType}`,
                        updatedAt: new Date()
                    }

                    const docRef = doc(db, "inventory", props.id);
                    setDoc(docRef, docDataUpdate, {
                        merge: true
                    }).then(() => setRefresh(!refresh));


                    setEditDetailsModal(false);
                    setName("");
                    setValidity("");
                    setScope("")
                    setFile("");
                    setLoading(false);

                }).catch((err) => {
                    console.log(err)
                })


        } else {
            const docDataUpdate = {
                name: name ? name : props.name,
                validity: validity ? validity : props.validity,
                scope: scope ? scope : props.scope,
                keywords: `${name ? name : props.name},${brand ? brand : props.brand},${scope ? scope : props.scope},${props.itemType}`,
                updatedAt: new Date()
            }

            const docRef = doc(db, "inventory", props.id);
            setDoc(docRef, docDataUpdate, {
                merge: true
            }).then(() => setRefresh(!refresh));

            setEditDetailsModal(false);
            setName("");
            setValidity("");
            setScope("")
            setFile("");
            setLoading(false);
        }
    }

    const handleSubmitUpdatedPackaged = () => {
        setLoading(true);

        if (file) {

            const data = new FormData();
            data.append("file", file)
            data.append("upload_preset", "growth")
            data.append("cloud_name", "ddmaoqwfd")

            axios.post("https://api.cloudinary.com/v1_1/ddmaoqwfd/image/upload", data)
                .then((response) => {
                    const data = response.data
                    // console.log(data)
                    const imageUrl = data.url;
                    // console.log(imageUrl)
                    const docDataUpdate = {
                        name: name ? name : props.name,
                        scope: scope ? scope : props.scope,
                        brand: brand ? brand : props.brand,
                        variantQty: variantQty ? variantQty : props.variantQty,
                        variantUnit: variantUnit ? variantUnit : props.variantUnit,
                        validity: validity ? validity : props.validity,
                        scope: scope ? scope : props.scope,
                        dp: imageUrl,
                        keywords: `${name ? name : props.name},${brand ? brand : props.brand},${scope ? scope : props.scope},${props.itemType}`,
                        updatedAt: new Date()
                    }

                    const docRef = doc(db, "inventory", props.id);
                    setDoc(docRef, docDataUpdate, {
                        merge: true
                    }).then(() => setRefresh(!refresh));

                    setEditDetailsModal(false);
                    setName("");
                    setBrand("");
                    setVariantQty("");
                    setVariantUnit("");
                    setValidity("");
                    setScope("")
                    setFile("");
                    setLoading(false);

                }).catch((err) => {
                    console.log(err)
                })


        } else {
            const docDataUpdate = {
                name: name ? name : props.name,
                scope: scope ? scope : props.scope,
                brand: brand ? brand : props.brand,
                variantQty: variantQty ? variantQty : props.variantQty,
                variantUnit: variantUnit ? variantUnit : props.variantUnit,
                validity: validity ? validity : props.validity,
                keywords: `${name ? name : props.name},${brand ? brand : props.brand},${scope ? scope : props.scope},${props.itemType}`,
                updatedAt: new Date()
            }

            const docRef = doc(db, "inventory", props.id);
            setDoc(docRef, docDataUpdate, {
                merge: true
            }).then(() => setRefresh(!refresh));

            setEditDetailsModal(false);
            setName("");
            setBrand("");
            setVariantQty("");
            setVariantUnit("");
            setValidity("");
            setScope("")
            setFile("");
            setLoading(false);
        }
    }

    const handleSubmitUpdatedWeighted = () => {
        setLoading(true);

        if (file) {

            const data = new FormData();
            data.append("file", file)
            data.append("upload_preset", "growth")
            data.append("cloud_name", "ddmaoqwfd")

            axios.post("https://api.cloudinary.com/v1_1/ddmaoqwfd/image/upload", data)
                .then((response) => {
                    const data = response.data
                    // console.log(data)
                    const imageUrl = data.url;
                    // console.log(imageUrl)
                    const docDataUpdate = {
                        name: name ? name : props.name,
                        validity: validity ? validity : props.validity,
                        scope: scope ? scope : props.scope,
                        dp: imageUrl,
                        keywords: `${name ? name : props.name},${brand ? brand : props.brand},${scope ? scope : props.scope},${props.itemType}`,
                        updatedAt: new Date()
                    }

                    const docRef = doc(db, "inventory", props.id);
                    setDoc(docRef, docDataUpdate, {
                        merge: true
                    }).then(() => setRefresh(!refresh));


                    setEditDetailsModal(false);
                    setName("");
                    setValidity("");
                    setScope("")
                    setFile("");
                    setLoading(false);

                }).catch((err) => {
                    console.log(err)
                })


        } else {
            const docDataUpdate = {
                name: name ? name : props.name,
                validity: validity ? validity : props.validity,
                scope: scope ? scope : props.scope,
                keywords: `${name ? name : props.name},${brand ? brand : props.brand},${scope ? scope : props.scope},${props.itemType}`,
                updatedAt: new Date()
            }

            const docRef = doc(db, "inventory", props.id);
            setDoc(docRef, docDataUpdate, {
                merge: true
            }).then(() => setRefresh(!refresh));

            setEditDetailsModal(false);
            setName("");
            setValidity("");
            setScope("")
            setFile("");
            setLoading(false);
        }
    }

    const [deleteAlert, setDeleteAlert] = useState(false)

    const deleteInvItemBlock = async () => {

        await deleteDoc(doc(db, "inventory", props.id));

        const imageData = props.dp;

        // cloudinary.v2.uploader.destroy(imageData.public_id, function (error, result) {
        //     console.log(result, error)
        // })
        //     .then(resp => console.log(resp))
        //     .catch(_err => console.log("Something went wrong, please try again later."));

        setRefresh(!refresh)
        setDeleteAlert(false)
        setItemDetailsModal(false)
    }

    const today = new Date()

    const uniqueLatestArr = props.purchases && Object.values(
        props.purchases?.filter((x) => x.createdAt <= today - 30).reduce((acc, obj) => ({ ...acc, [obj.sourceName]: obj }), {})
    )

    return (
        <>
            <FlexBoxSA>
                <InvBlockWrapper>
                    <InvBlockContainer onClick={() => setItemDetailsModal(true)}>
                        <Tooltip title="Quantity">
                            {
                                props.itemType === "piece"
                                    ?
                                    <InvBlockQtyCircle>
                                        <AbsoluteCenter>
                                            <InnerCircle>
                                                <AbsoluteCenter>
                                                    <SubBodyBold>
                                                        {
                                                            props.purchases
                                                                ?
                                                                props.purchases.reduce((total, next) => total + parseFloat(next.qty), 0)
                                                                :
                                                                0
                                                        }
                                                    </SubBodyBold>
                                                </AbsoluteCenter>
                                            </InnerCircle>
                                        </AbsoluteCenter>
                                    </InvBlockQtyCircle>
                                    :
                                    props.itemType === "packaged"
                                        ?
                                        <InvBlockQtyCircle>
                                            <AbsoluteCenter>
                                                <InnerCircle>
                                                    <AbsoluteCenter>
                                                        <SubBodyBold>
                                                            {
                                                                props.purchases
                                                                    ?
                                                                    props.purchases.reduce((total, next) => total + parseFloat(next.qty), 0)
                                                                    :
                                                                    0
                                                            }
                                                        </SubBodyBold>
                                                    </AbsoluteCenter>
                                                </InnerCircle>
                                            </AbsoluteCenter>
                                        </InvBlockQtyCircle>
                                        :
                                        props.itemType === "weighted"
                                            ?
                                            <InvBlockQtyRectangle>
                                                <AbsoluteCenter>
                                                    <InnerRectangle>
                                                        <AbsoluteCenter>
                                                            <SubBodyBold>
                                                                {
                                                                    props.purchases
                                                                        ?
                                                                        `${props.purchases.sort(function (a, b) {
                                                                            return new Date(b.date) - new Date(a.date);
                                                                        }).slice(-1)[0].wQty}gm/l`
                                                                        :
                                                                        0
                                                                }
                                                            </SubBodyBold>
                                                        </AbsoluteCenter>
                                                    </InnerRectangle>
                                                </AbsoluteCenter>
                                            </InvBlockQtyRectangle>
                                            :
                                            <InvBlockQtyCircle>
                                                <AbsoluteCenter>
                                                    <InnerCircle>
                                                        <AbsoluteCenter>
                                                            <SubBodyBold>
                                                                0
                                                            </SubBodyBold>
                                                        </AbsoluteCenter>
                                                    </InnerCircle>
                                                </AbsoluteCenter>
                                            </InvBlockQtyCircle>
                            }
                        </Tooltip>
                        <FlexColumnSB style={{ height: "100%" }}>
                            <FlexBoxSA>
                                <ImgHolder
                                    img={props.dp ? props.dp : dpPlaceholder}
                                    width={96}
                                    height={96}
                                />
                            </FlexBoxSA>
                            <div>
                                <SpacerTopBottomMedium />
                                <FlexBoxSB>
                                    <SubBody>
                                        {props.brand}
                                    </SubBody>
                                    <FlexBox>
                                        <SubBody>
                                            {props.variantQty}
                                        </SubBody>
                                        <SubBody>
                                            {props.variantUnit}
                                        </SubBody>
                                    </FlexBox>
                                </FlexBoxSB>
                                <SpacerTopBottomMedium />
                                <FlexBoxSB>
                                    <SubTitleSemiBold>
                                        {props.name}
                                    </SubTitleSemiBold>
                                    <SpacerLeftRightSmall />
                                    <SubTitleSemiBold>
                                        {
                                            props.purchases
                                                ?
                                                <NumericFormat
                                                    value={
                                                        props.itemType === "piece"
                                                            ?
                                                            uniqueLatestArr.reduce((total, next) => total + parseFloat(next.price), 0) / uniqueLatestArr.length / parseFloat(props.variantQty)
                                                            :
                                                            props.itemType === "packaged"
                                                                ?
                                                                uniqueLatestArr.reduce((total, next) => total + parseFloat(next.price), 0) / uniqueLatestArr.length / parseFloat(props.variantQty)
                                                                :
                                                                props.itemType === "weighted"
                                                                &&
                                                                uniqueLatestArr.reduce((total, next) => total + parseFloat(next.price), 0) / uniqueLatestArr.length / uniqueLatestArr.reduce((total, next) => total + parseFloat(next.wQty), 0) / uniqueLatestArr.length
                                                    }
                                                    decimalScale={3}
                                                    prefix="₹"
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                />
                                                :
                                                "-"
                                        }
                                    </SubTitleSemiBold>
                                </FlexBoxSB>
                                <SpacerTopBottomMedium>
                                    <div style={{ border: "1px dashed #AAA9AE" }} />
                                </SpacerTopBottomMedium>
                                <FlexBoxSB>
                                    <Body>
                                        Usage
                                    </Body>
                                    <BodySemiBold>
                                        -
                                    </BodySemiBold>
                                </FlexBoxSB>
                                <SpacerTopBottomMedium />
                                <FlexBoxSB>
                                    <Body>
                                        Replenish
                                    </Body>
                                    <BodySemiBold>
                                        -
                                    </BodySemiBold>
                                </FlexBoxSB>
                                <SpacerTopBottomMedium />
                                <FlexBoxSB>
                                    <Body>
                                        Validity
                                    </Body>
                                    <BodySemiBold>
                                        {props.validity ? props.validity === "0" ? "-" : props.validity : "-"}
                                    </BodySemiBold>
                                </FlexBoxSB>
                            </div>
                        </FlexColumnSB>
                    </InvBlockContainer>
                </InvBlockWrapper>
            </FlexBoxSA>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={itemDetailsModal}
                onClose={() => setItemDetailsModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={itemDetailsModal}>
                    <Box sx={itemDetailsModalStyle}>
                        <InvItemDetails
                            id={props.id}
                            name={props.name}
                            brand={props.brand}
                            scope={props.scope}
                            variantQty={props.variantQty}
                            variantUnit={props.variantUnit}
                            validity={props.validity}
                            dp={props.dp}
                            itemType={props.itemType}
                            purchases={props.purchases}
                            newPurchaseClicked={handleNewPurchaseClicked}
                            changeDpClicked={() => setEditDetailsModal(true)}
                            deleteAlertClicked={() => setDeleteAlert(true)}
                            width={props.width}
                        />
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={newPurchaseModal}
                // onClose={() => setNewPurchaseModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={newPurchaseModal}>
                    <Box sx={itemModalStyle}>
                        <NewPurchaseForm
                            itemType={props.itemType}
                            units={units}
                            medium={medium}
                            paymentModeArr={paymentModeArr}
                            price={price}
                            onChangePrice={(e) => setPrice(e.target.value)}
                            sourceType={sourceType}
                            onChangeSourceType={(e) => setSourceType(e.target.value)}
                            sourceName={sourceName}
                            onChangeSourceName={(e) => setSourceName(e.target.value)}
                            paymentMode={paymentMode}
                            onChangePaymentMode={(e) => setPaymentMode(e.target.value)}
                            discount={discount}
                            onChangeDiscount={(e) => setDiscount(e.target.value)}
                            qty={qty}
                            onChangeQty={(e) => setQty(e.target.value)}
                            wQty={wQty}
                            onChangeWQty={(e) => setWQty(e.target.value)}
                            startDate={startDate}
                            onChangeStartDate={(e) => setStartDate(e.target.value)}
                            mfdDate={mfdDate}
                            onChangeMfdDate={(e) => setMfdDate(e.target.value)}
                            file={file}
                            onChangeFile={(e) => setFile(e.target.files[0])}
                            deleteDP={() => setFile()}
                            btnColor={"success"}
                            loading={loading}
                            handleSave={handleSubmit}
                            handleCancel={handleCancel}
                            btnTitle={"Save"}
                            disabledPiece={
                                qty && price && sourceName
                                    ?
                                    false
                                    :
                                    true
                            }
                            disabledPackaged={
                                qty && price && sourceName
                                    ?
                                    false
                                    :
                                    true
                            }
                            disabledWeighted={
                                wQty && price && sourceName
                                    ?
                                    false
                                    :
                                    true
                            }
                            width={props.width}
                        />
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={deleteAlert}
                onClose={() => setDeleteAlert(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={deleteAlert}>
                    <Box sx={itemModalStyle}>
                        <DeleteAlert
                            handleCancel={() => setDeleteAlert(false)}
                            handleDelete={deleteInvItemBlock}
                        />
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={editDetailsModal}
                onClose={() => setEditDetailsModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={editDetailsModal}>
                    <Box sx={itemModalStyle}>
                        <NewItemForm
                            ambit="edit"
                            itemType={props.itemType}
                            name={name}
                            onChangeName={(e) => setName(e.target.value)}
                            placeholderName={props.name}
                            scope={scope}
                            onChangeScope={(e) => setScope(e.target.value)}
                            placeholderScope={props.scope}
                            scopeArr={scopeArr}
                            brand={brand}
                            onChangeBrand={(e) => setBrand(e.target.value)}
                            placeholderBrand={props.brand}
                            variantQty={variantQty}
                            onChangeVariantQty={(e) => setVariantQty(e.target.value)}
                            placeholderVariantQty={props.variantQty}
                            units={units}
                            onChangeVariantUnit={(e) => setVariantUnit(e.target.value)}
                            placeholderVariantUnit={props.variantUnit}
                            validity={validity}
                            onChangeValidity={(e) => setValidity(e.target.value)}
                            placeholderValidity={props.validity}
                            file={file}
                            onChangeFile={(e) => setFile(e.target.files[0])}
                            deleteDP={() => setFile()}
                            dp={props.dp}
                            dpPlaceholder={dpPlaceholder}
                            btnColor={"success"}
                            loading={loading}
                            handleSavePiece={handleSubmitUpdatedPiece}
                            handleSavePackaged={handleSubmitUpdatedPackaged}
                            handleSaveWeighted={handleSubmitUpdatedWeighted}
                            btnTitle={"Save"}
                            closeModal={() => setEditDetailsModal(false)}
                        />
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

import React from 'react'
import useWindowDimensions from '../../../global/components/screenSize'
import { AlignCenter, Body, BodySemiBold, SubTitleSemiBold } from '../../../global/global.styles'
import { ArchiveActionBtnContainer } from '../style/archive.styles'

export const ArchiveActionBtn = (props) => {

    const { width } = useWindowDimensions()

    return (
        <>
            <ArchiveActionBtnContainer onClick={props.handleClick}>
                <AlignCenter>
                    {
                        width > 450
                            ?
                            <SubTitleSemiBold>
                                {props.title}
                            </SubTitleSemiBold>
                            :
                            <BodySemiBold>
                                {props.title}
                            </BodySemiBold>
                    }
                </AlignCenter>
            </ArchiveActionBtnContainer>
        </>
    )
}

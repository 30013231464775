import React from 'react'
import { TimeframeBlockHorizontalContainer, TimeframeBlockVerticalContainer } from '../style/home.styles'
import { AbsoluteCenter, AlignCenter, BodySemiBold, FlexBox, Heading, Headline, SubHeadline, SubTitle, SubTitleSemiBoldG60 } from '../../../global/global.styles'
import { SpacerTopBottomMedium, SpacerBottomSmall, SpacerLeftRightLarge, SpacerLeftRightXXL } from '../../../design/spacer/spacer'

export const TimeframeBlock = (props) => {
  return (
    <>
      {
        props.orientation === "vertical"
          ?
          <TimeframeBlockVerticalContainer
            style={{ border: props.active ? "3px solid #00FFD1" : "none" }}
            onClick={props.timeframeClicked}>
            <AlignCenter>
              <SubTitleSemiBoldG60>
                {props.title}
              </SubTitleSemiBoldG60>
            </AlignCenter>
            <AbsoluteCenter>
              <AlignCenter>
                <Headline>
                  {props.dateNum}
                </Headline>
                <SpacerBottomSmall />
                <Heading>
                  {props.dateMon}
                </Heading>
              </AlignCenter>
            </AbsoluteCenter>
          </TimeframeBlockVerticalContainer>
          :
          props.orientation === "horizontal"
          &&
          <TimeframeBlockHorizontalContainer
            style={{ border: props.active ? "3px solid #00FFD1" : "none" }}
            onClick={props.timeframeClicked}>
            <AlignCenter>
              <SubTitleSemiBoldG60>
                {props.title}
              </SubTitleSemiBoldG60>
            </AlignCenter>
            <AbsoluteCenter>
              <FlexBox>
                <AlignCenter>
                  <Headline>
                    {props.dateFromNum}
                  </Headline>
                  <SpacerBottomSmall />
                  <Heading>
                    {props.dateFromMon}
                  </Heading>
                </AlignCenter>
                <SpacerLeftRightXXL>
                  <AlignCenter>
                    <Headline>
                      -
                    </Headline>
                  </AlignCenter>
                </SpacerLeftRightXXL>
                <AlignCenter>
                  <Headline>
                    {props.dateToNum}
                  </Headline>
                  <SpacerBottomSmall />
                  <Heading>
                    {props.dateToMon}
                  </Heading>
                </AlignCenter>
              </FlexBox>
            </AbsoluteCenter>
          </TimeframeBlockHorizontalContainer>
      }
    </>
  )
}

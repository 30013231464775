import styled from 'styled-components'

// ----------------------------------------Containers----------------------------------------//

export const TemplateStyle = styled.div`
    @media only screen and (min-width: 1800px) {

    }
    @media only screen and (max-width: 1300px) {
        
    }
    @media only screen and (max-width: 1100px) {
        
    }
    @media only screen and (max-width: 850px) {
        
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 370px) {

    }
`;

export const LoginWrapper = styled.div`
    height: 100vh;
    width: 100vw; 
    background-color: #3B9C8B;

    padding-top: ${(props) => props.theme.space[6]};
    padding-bottom: ${(props) => props.theme.space[6]};
    padding-left: ${(props) => props.theme.space[20]};
    padding-right: ${(props) => props.theme.space[20]};
    
    @media only screen and (min-width: 1800px) {
        padding-left: ${(props) => props.theme.space[30]};
        padding-right: ${(props) => props.theme.space[30]};
    }
    @media only screen and (max-width: 1300px) {

    }
    @media only screen and (max-width: 1100px) {
        padding-left: ${(props) => props.theme.space[10]};
        padding-right: ${(props) => props.theme.space[10]};
    }
    @media only screen and (max-width: 850px) {
    }
    @media only screen and (max-width: 650px) {
        padding-left: ${(props) => props.theme.space[7]};
        padding-right: ${(props) => props.theme.space[7]};
    }

    @media only screen and (max-width: 450px) {
        padding-left: ${(props) => props.theme.space[5]};
        padding-right: ${(props) => props.theme.space[5]};
    }

    @media only screen and (max-width: 370px) {
        padding-top: ${(props) => props.theme.space[3]};
        padding-bottom: ${(props) => props.theme.space[3]};
        padding-left: ${(props) => props.theme.space[3]};
        padding-right: ${(props) => props.theme.space[3]};
    }
`;

export const LoginFormComponent = styled.div`
    border-radius: 8px;
    padding: ${(props) => props.theme.space[6]};
    box-shadow: 0px 3px 7px 1px rgba(207,207,207,0.75);
-webkit-box-shadow: 0px 3px 7px 1px rgba(207,207,207,0.75);
-moz-box-shadow: 0px 3px 7px 1px rgba(207,207,207,0.75);
    background-color: #FFFFFF;
    min-width: 400px;
    margin: 0 auto;
    
    @media only screen and (max-width: 450px) {
        min-width: 0;
    }
`;

export const LogoHolder = styled.div`
    height: 80px;
    width: 80px;
`;

export const Logo = styled.img`
    height: 100%;
    width: 100%;
`;

export const LoginFooterContainer = styled.div`
    
`;

export const InspiriseLogoHolder = styled.div`
    width: 200px;

    @media only screen and (max-width: 350px) {
        width: 120px;
    }
`;

export const InspiriseLogo = styled.img`
    height: 100%;
    width: 100%;
`;
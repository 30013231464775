// DEFAULT
import { Backdrop, Box, Button, Fade, Grid, Modal } from '@mui/material'
import { addDoc, collection, doc, serverTimestamp } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import React from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { ApiContext } from '../../context/api/api.provider'
import { AuthContext } from '../../context/auth/auth.provider'
import { UpdateContext } from '../../context/update/update.provider'
import { SpacerLeftRightMedium, SpacerTopBottomLarge, SpacerTopBottomXXL, SpacerTopBottomXXXL } from '../../design/spacer/spacer'
import { db, storage } from '../../firebase/firebase'
import Navbar from '../../global/components/navbar'
import useWindowDimensions from '../../global/components/screenSize'
import SearchBar from '../../global/components/searchBar'
import { AbsoluteTopRight, Container, ContainerTop, FlexBox, FlexBoxSA, FlexBoxSE, Sandwich, Squeezer, SubTitle } from '../../global/global.styles'
import { ArchiveActionBtn } from './components/archiveActionBtn'
import { ArchiveBlock } from './components/archiveBlock'
import pdfIcon from '../../assets/images/pdf.png'
import docIcon from '../../assets/images/doc.png'
import pptIcon from '../../assets/images/ppt.png'
import txtIcon from '../../assets/images/txt.png'
import xlsIcon from '../../assets/images/xls.png'
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';

// INTERFACE
import { NewCredForm } from './interface/newCredForm'
import { NewDocForm } from './interface/newDocForm'
import { ArchiveHeaderContainer, ArchiveHeaderWrapper } from './style/archive.styles'


// MAIN
export const Archive = (props) => {

    const { width } = useWindowDimensions()

    const { user } = useContext(AuthContext);
    const { creds, credsCollectionRef, docs, docsCollectionRef } = useContext(ApiContext);
    const { refresh, setRefresh } = useContext(UpdateContext);

    const [search, setSearch] = useState();

    const archiveData = creds.concat(docs)

    const userArchiveData = archiveData.filter((x) => x.userId === user.uid)

    const [filteredData, setFilteredData] = useState(userArchiveData);

    useEffect(() => {
        setFilteredData(userArchiveData)
    }, [creds])

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [portal, setPortal] = useState("")
    const [category, setCategory] = useState("")
    const [other, setOther] = useState("")

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")

    const [file, setFile] = useState("");
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    const [credsModal, setCredsModal] = useState(false)

    const credModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        borderRadius: '8px',
        backgroundColor: 'white',
        p: 4,
        width: width > 450 ? '400px' : width > 350 ? '320px' : '280px',
        height: width > 850 ? 'inherit' : width > 650 ? '40vh' : width > 370 ? '48vh' : '60vh',
        overflowY: 'auto'
    };

    const [docModal, setDocModal] = useState(false)

    const docModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        borderRadius: '8px',
        backgroundColor: 'white',
        p: 4,
        width: width > 450 ? '400px' : width > 350 ? '320px' : '280px',
        height: width > 850 ? 'inherit' : width > 650 ? '32vh' : width > 370 ? '40vh' : '48vh',
        overflowY: 'auto'
    };

    const searchFilter = (text) => {
        if (text) {
            const newData = userArchiveData?.filter((item) => {
                const itemData = item.keywords
                    ? item.keywords.toLowerCase()
                    : ''.toLowerCase();
                const textData = text.toLowerCase();
                return itemData.indexOf(textData) > -1;
            });
            setFilteredData(newData);
            setSearch(text);
        } else {
            setFilteredData(userArchiveData);
            setSearch(text);
        }
    }

    const handleSaveCreds = async () => {

        const data = {
            userId: user.uid,
            username,
            password,
            portal,
            other,
            category,
            keywords: `${portal},${category}`,
            type: "Credentials",
            createdAt: serverTimestamp()
        }

        await addDoc(credsCollectionRef, data);

        setCredsModal(false);
        setRefresh(!refresh);
        setPortal("")
        setUsername("")
        setPassword("")
        setOther("")
        setCategory("")
    }

    const handleSaveDoc = async () => {

        setLoading(true);

        if (file) {
            const ext = file?.name
            const randomId = doc(collection(db, "temp")).id
            const storageRef = ref(storage, `Documents/${randomId}${ext?.substring(ext?.lastIndexOf("."))}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(prog);
                },
                (error) => console.log(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        const data = {
                            title: title,
                            description: description,
                            doc: downloadURL,
                            ext: ext.split(".").pop(),
                            category,
                            keywords: `${title},${category}`,
                            type: "Documents",
                            userId: user.uid,
                            createdAt: serverTimestamp()
                        }

                        await addDoc(docsCollectionRef, data);

                        setRefresh(!refresh);
                        setDocModal(false);
                        setTitle("");
                        setDescription("");
                        setCategory("")
                        setFile("");
                    });
                }
            );


        } else {
            const data = {
                title: title,
                description: description,
                category,
                keywords: `${title},${category}`,
                type: "Documents",
                userId: user.uid,
                createdAt: serverTimestamp()
            }

            await addDoc(docsCollectionRef, data);

            setRefresh(!refresh);
            setDocModal(false);
            setTitle("");
            setDescription("");
            setCategory("")
            setFile("");
            setLoading(false);

        }

    }

    const handleDocModalClose = () => {
        setDocModal(false);
        setFile("")
    }

    return (
        <>
            <ArchiveHeaderWrapper>
                <AbsoluteTopRight style={{ padding: 16 }}>
                    <Button
                        variant='outlined'
                        sx={{ textTransform: "none" }}
                        color='error'
                        size='small'
                        startIcon={<KeyboardReturnRoundedIcon />}
                        onClick={() => props.navigate("/time")}>
                        Return
                    </Button>
                </AbsoluteTopRight>
                <ArchiveHeaderContainer>
                    <FlexBoxSE style={{ width: '100%', overflowX: 'auto', padding: '4px' }}>
                        <ArchiveActionBtn
                            title={"New Credential"}
                            handleClick={() => setCredsModal(true)}
                        />
                        <SpacerLeftRightMedium />
                        <ArchiveActionBtn
                            title={"Log Document"}
                            handleClick={() => setDocModal(true)}
                        />
                    </FlexBoxSE>
                    <SpacerTopBottomXXL />
                    <SearchBar
                        placeholder={"Search Archive"}
                        search={search}
                        onChange={(event) => searchFilter(event.target.value)}
                        width={500}
                        xsWidth={360}
                        xxsWidth={280}
                    />
                </ArchiveHeaderContainer>
            </ArchiveHeaderWrapper>
            <Container>
                <Grid container>
                    {
                        filteredData?.map((item, index) => (
                            <ArchiveBlock
                                key={index}
                                id={item.id}
                                portal={item.portal}
                                username={item.username}
                                password={item.password}
                                other={item.other}
                                category={item.category}
                                type={item.type}
                                title={item.title}
                                description={item.description}
                                doc={item.doc}
                                url={
                                    item.ext === "pdf"
                                        ?
                                        pdfIcon
                                        :
                                        item.ext === "doc"
                                            ?
                                            docIcon
                                            :
                                            item.ext === "ppt"
                                                ?
                                                pptIcon
                                                :
                                                item.ext === "xls"
                                                    ?
                                                    xlsIcon
                                                    :
                                                    item.ext === "xlsx"
                                                        ?
                                                        xlsIcon
                                                        :
                                                        item.ext === "txt"
                                                            ?
                                                            txtIcon
                                                            :
                                                            item.ext === "docx"
                                                                ?
                                                                docIcon
                                                                :
                                                                item.doc
                                }
                            />
                        ))
                    }
                </Grid>
            </Container>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={credsModal}
                onClose={() => setCredsModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={credsModal}>
                    <Box sx={credModalStyle}>
                        <NewCredForm
                            username={username}
                            onChangeUsername={(e) => setUsername(e.target.value)}
                            password={password}
                            onChangePassword={(e) => setPassword(e.target.value)}
                            portal={portal}
                            onChangePortal={(e) => setPortal(e.target.value)}
                            category={category}
                            onChangeCategory={(e) => setCategory(e.target.value)}
                            other={other}
                            onChangeOther={(e) => setOther(e.target.value)}
                            handleSave={handleSaveCreds}
                            btnTitle={"Save"}
                            btnColor={"success"}
                        />
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={docModal}
                onClose={handleDocModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={docModal}>
                    <Box sx={docModalStyle}>
                        <NewDocForm
                            title={title}
                            onChangeTitle={(e) => setTitle(e.target.value)}
                            description={description}
                            onChangedescription={(e) => setDescription(e.target.value)}
                            category={category}
                            onChangeCategory={(e) => setCategory(e.target.value)}
                            file={file}
                            fileName={file?.name}
                            onChangeFile={e => setFile(e.target.files[0])}
                            handleSave={handleSaveDoc}
                            btnTitle={"Save"}
                            btnColor={"success"}
                        />
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}
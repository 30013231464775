import React from 'react'
import { ObjectTileContainer } from '../style/home.styles'
import { BodySemiBoldG60, BodySemiBoldWhite, FlexBox, FlexBoxSB, MagnifyIcon, SubTitleBold, SubTitleMediumG60, SubTitleWhite } from '../../../global/global.styles'
import { SpacerLeftRightLarge, SpacerLeftRightMedium } from '../../../design/spacer/spacer'
import { Tooltip } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export const ObjectTile = (props) => {

    const removeObject = () => {
        props.setSpecifiedMembers(props.specifiedMembers.filter((x) => x !== props.memberId))
    }

    return (
        <>
            <ObjectTileContainer>
                <FlexBoxSB>
                    <FlexBox>
                        <BodySemiBoldWhite style={{ color: "white" }}>
                            {props.label}
                        </BodySemiBoldWhite>
                        <SpacerLeftRightLarge />
                        <BodySemiBoldWhite>
                            {props.info}
                        </BodySemiBoldWhite>
                    </FlexBox>
                    <SpacerLeftRightMedium />
                    <Tooltip title="Remove" onClick={removeObject}>
                        <MagnifyIcon>
                            <CloseRoundedIcon
                                fontSize='small'
                                sx={{
                                    cursor: "pointer",
                                    color: "white"
                                }}
                            />
                        </MagnifyIcon>
                    </Tooltip>
                </FlexBoxSB>
            </ObjectTileContainer>
        </>
    )
}

import React, { useState } from 'react'
import { IngredientCompositionBlockContainer } from '../style/home.styles'
import { TextField } from '@mui/material'

export const IngredientCompositionBlock = (props) => {

    const [ingredientCompValue, setIngredientCompValue] = useState("")

    const handleCompValue = (e) => {
        setIngredientCompValue(e.target.value)
        const objIndex = props.ingredientsArr.findIndex((obj => obj.ingredientName == props.name))
        props.ingredientsArr[objIndex].ingredientCompValue = e.target.value

        // const arr = props.ingredientsArr.filter((x) => x.ingredientName !== props.name)
        // props.setIngredientsArr([...arr, { ingredientName: props.name, ingredientCompValue: e.target.value }])
    }

    return (
        <div>
            <IngredientCompositionBlockContainer>
                {props.name.charAt(0).toUpperCase() + props.name.slice(1)}
            </IngredientCompositionBlockContainer>
            <IngredientCompositionBlockContainer>
                <TextField
                    id="mealAvgWeight"
                    name="mealAvgWeight"
                    type="number"
                    size='small'
                    autoComplete='off'
                    sx={{ width: 80 }}
                    value={ingredientCompValue}
                    onChange={handleCompValue}
                    inputProps={{ style: { fontSize: '16px' } }}
                />
            </IngredientCompositionBlockContainer>
        </div>
    )
}

import React from 'react'
import { AbsoluteCenter, BodySemiBoldG60, FlexBox, FlexBoxSB, SubTitleG40, Title, TitleSemiBoldG40 } from '../../../global/global.styles'
import { Button } from '@mui/material'
import { RoutineActBarContainer } from '../style/time.styles'
import { SpacerLeftRightLarge, SpacerLeftRightSmall, SpacerRightSmall } from '../../../design/spacer/spacer'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import moment from 'moment'

export const RoutineActBar = (props) => {

    return (
        <>
            <RoutineActBarContainer style={{ position: "sticky", top: 91, backgroundColor: "white", zIndex: 2 }}>
                <FlexBoxSB>
                    <TitleSemiBoldG40>
                        {moment(props.today).format("MMM YYYY")}
                    </TitleSemiBoldG40>
                    <FlexBox>
                        <Button
                            variant='outlined'
                            sx={{ textTransform: "none" }}
                            size='small'
                            onClick={props.selectToday}>
                            Today
                        </Button>
                        <SpacerLeftRightLarge />
                        <div style={{ position: "relative", width: 48, height: 32, border: "1px solid #AAA9AE", borderRadius: 4, cursor: "pointer" }}>
                            <AbsoluteCenter>
                                <ArrowBackRoundedIcon fontSize='small' />
                            </AbsoluteCenter>
                        </div>
                        <SpacerLeftRightSmall />
                        <div style={{ position: "relative", width: 48, height: 32, border: "1px solid #AAA9AE", borderRadius: 4, cursor: "pointer" }}>
                            <AbsoluteCenter>
                                <ArrowForwardRoundedIcon fontSize='small' />
                            </AbsoluteCenter>
                        </div>
                        <SpacerLeftRightLarge />
                        <input type='date' id="datepicker" ref={props.inputDateRef} onChange={props.onChangeDate} />
                    </FlexBox>
                </FlexBoxSB>
            </RoutineActBarContainer>
        </>
    )
}

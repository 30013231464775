import React from 'react'
import { MealTileMealBlockContainer } from '../style/home.styles'
import { AlignCenter, BodySemiBold, FlexBoxSB, SubTitleSemiBold } from '../../../global/global.styles'
import { SpacerTopBottomMedium, SpacerTopBottomSmall } from '../../../design/spacer/spacer'
import { Divider } from '@mui/material'

export const MealTileMealBlock = (props) => {
    return (
        <>
            <MealTileMealBlockContainer>
                <AlignCenter>
                    <SubTitleSemiBold>
                        {props.mealName}
                    </SubTitleSemiBold>
                </AlignCenter>
                <SpacerTopBottomMedium>
                    <Divider />
                </SpacerTopBottomMedium>
                <FlexBoxSB>
                    {
                        props.consumption.map((item, index) => (
                            <div>
                                <BodySemiBold>
                                    {item.member}
                                </BodySemiBold>
                                <SpacerTopBottomSmall />
                                <AlignCenter>
                                    <BodySemiBold>
                                        {item.consumValue}
                                    </BodySemiBold>
                                </AlignCenter>
                            </div>
                        ))
                    }
                </FlexBoxSB>
            </MealTileMealBlockContainer>
        </>
    )
}

import React, { useState } from "react";
import { CheckSquare, Clock, MoreHorizontal } from "react-feather";

import Dropdown from "../Dropdown/Dropdown";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

import "./Card.css";
import CardInfo from "./CardInfo/CardInfo";
import { FlexBox, MagnifyIcon } from "../../../../global/global.styles";
import { SpacerLeftRightSmall } from "../../../../design/spacer/spacer";
import { Tooltip } from "@mui/material";

function Card(props) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { id, title, date, tasks, labels } = props.card;

  const formatDate = (value) => {
    if (!value) return "";
    const date = new Date(value);
    if (!date) return "";

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Aprl",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    return day + " " + month;
  };

  return (
    <>
      {showModal && (
        <CardInfo
          onClose={() => setShowModal(false)}
          card={props.card}
          cards={props.cards}
          boardId={props.boardId}
          updateCard={props.updateCard}
        />
      )}
      <div
        className="card"
        draggable
        onDragEnd={() => props.dragEnded(props.boardId, id)}
        onDragEnter={() => props.dragEntered(props.boardId, id)}
        onClick={() => setShowModal(true)}
      >
        <div className="card_top">
          <div className="card_top_labels">
            {labels?.map((item, index) => (
              <label key={index} style={{ backgroundColor: item.color }}>
                {item.text}
              </label>
            ))}
          </div>
          <FlexBox>
            <Tooltip title="Delete" onClick={(event) => {
              event.stopPropagation();
              props.removeCard(props.boardId, id, props.cards)
            }}>
              <MagnifyIcon>
                <DeleteForeverRoundedIcon
                  color="error"
                  fontSize="small"
                />
              </MagnifyIcon>
            </Tooltip>
            <SpacerLeftRightSmall />
            <Tooltip title="Completed" onClick={(event) => {
              event.stopPropagation();
              props.taskCompleted(props.boardId, id, props.cards)
            }}>
              <MagnifyIcon>
                <CheckCircleOutlineRoundedIcon
                  color="success"
                  fontSize="small"
                />
              </MagnifyIcon>
            </Tooltip>
          </FlexBox>
          {/* <div
            className="card_top_more"
            onClick={(event) => {
              event.stopPropagation();
              setShowDropdown(true);
            }}
          >
            <MoreHorizontal />
            {showDropdown && (
              <Dropdown
                class="board_dropdown"
                onClose={() => setShowDropdown(false)}
              >
                <p onClick={() => props.removeCard(props.boardId, id, props.cards)}>
                  Delete Card
                </p>
              </Dropdown>
            )}
          </div> */}
        </div>
        <div className="card_title">{title}</div>
        <div className="card_footer">
          {date ? (
            <p className="card_footer_item">
              <Clock className="card_footer_icon" />
              {formatDate(date)}
            </p>
          ) : <div />}
          {tasks && tasks?.length > 0 && (
            <p className="card_footer_item">
              <CheckSquare className="card_footer_icon" />
              {tasks?.filter((item) => item.completed)?.length}/{tasks?.length}
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default Card;

import styled from 'styled-components'

// ----------------------------------------Containers----------------------------------------//

export const TaskCorrelateWidgetContainer = styled.div`
    padding: 10px;
    border-radius: 16px;
    background-color: rgba(237, 237, 237, 0.5);
    overflow: hidden;
    width: 540px;
    height: 400px;
    box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
    -webkit-box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
    -moz-box-shadow: 0px 60px 57px -14px rgba(196,195,195,0.75);
`;

export const TaskCorrelateBlockContainer = styled.div`
    float: left;
    padding: 12px;
    background-color: white;
    margin: 8px;
    width: 240px;
`;
import React from 'react'
import { DayMealEntryContainer } from '../style/home.styles'
import { BodyMediumG60, FlexBoxSA, MagnifyIcon, SubTitle, SubTitleMediumG60, Title } from '../../../global/global.styles'
import { SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomXL } from '../../../design/spacer/spacer'
import { MealEntryCard } from './mealEntryCard'
import { useState } from 'react'
import { Tooltip } from '@mui/material'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useId } from 'react'
import { useEffect } from 'react'

export const DayMealEntry = (props) => {

    const [mealEntries, setMealEntries] = useState([
        {
            mealDate: new Date(props.day),
            mealId: "",
            startTime: "",
            endTime: "",
            members: [],
            meals: []
        }
    ])

    const mealEntriesAddRow = () => {
        setMealEntries([...mealEntries, {
            startTime: "",
            endTime: "",
            mealDate: new Date(props.day),
            mealId: "",
            members: [],
            meals: []
        }])
        props.setFinishValidation(true)
    }

    const mealEntriesSubtractRow = () => {
        const values = [...mealEntries];
        values.splice(mealEntries.length - 1, 1);
        setMealEntries(values);
    }

    const handleChangeMembers = (o, index) => {
        const values = [...mealEntries];
        values[index]["members"] = o
        setMealEntries(values);
    }

    const handleChangeMeals = (o, index) => {
        const values = [...mealEntries];
        values[index]["meals"] = o
        setMealEntries(values);
    }

    const handleStringChangeMealEntries = (index, event) => {
        const values = [...mealEntries];
        values[index][event.target.name] = event.target.value;
        setMealEntries(values);
    }

    const setMealId = (index) => {
        const values = [...mealEntries];
        values[index]["mealId"] = 1 + index;
        setMealEntries(values);
    }

    useEffect(() => {
        props.setMealsList(
            [...props.mealsList.filter((x) => x.date !== props.day),
            {
                date: props.day,
                valid: mealEntries.map((i) => i.startTime !== "" && i.endTime !== "" && i.members.length !== 0 && i.meals.length !== 0)[0],
                mealEntries: mealEntries
            }
            ])
    }, [mealEntries])

    return (
        <>
            <DayMealEntryContainer>
                <FlexBoxSA>
                    <BodyMediumG60>
                        {props.day}
                    </BodyMediumG60>
                </FlexBoxSA>
                <SpacerTopBottomXL />
                {
                    mealEntries?.map((item, index) => (
                        <div key={index}>
                            <MealEntryCard
                                index={index}
                                width={props.width}
                                mealMembers={props.mealMembers}
                                meals={props.meals}
                                users={props.users}
                                mealId={1 + index}
                                onChangeMealId={() => setMealId(index)}
                                mealEntries={mealEntries}
                                setMealEntries={setMealEntries}
                                startTime={mealEntries.startTime}
                                onChangeStartTime={event => handleStringChangeMealEntries(index, event)}
                                onChangeEndTime={event => handleStringChangeMealEntries(index, event)}
                                endTime={mealEntries.endTime}
                                specifiedMembers={mealEntries.members}
                                addedMeals={mealEntries.meals}
                                handleChangeMembers={handleChangeMembers}
                                handleChangeMeals={handleChangeMeals}
                                mealEntriesSubtractRow={mealEntriesSubtractRow}
                            />
                            <SpacerTopBottomLarge />
                        </div>
                    ))
                }
                <SpacerTopBottomLarge />
                <FlexBoxSA>
                    <Tooltip title="Add meal" placement='bottom' onClick={mealEntriesAddRow}>
                        <MagnifyIcon>
                            <AddCircleRoundedIcon
                                sx={{
                                    color: '#6E6D70',
                                    cursor: 'pointer'
                                }}
                                fontSize='large'
                            />
                        </MagnifyIcon>
                    </Tooltip>
                </FlexBoxSA>
            </DayMealEntryContainer>
        </>
    )
}

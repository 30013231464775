import React from 'react'
import { FlexBox, FlexBoxSA } from '../../../global/global.styles'
import { Button } from '@mui/material'
import { ScheduleActBar } from './scheduleActBar'
import { Schedule } from './schedule'

export const TimeSchedule = (props) => {
    return (
        <>
            <ScheduleActBar 
                routineClicked={props.routineClicked}
                projectClicked={props.projectClicked}
                eventClicked={props.eventClicked}
                taskClicked={props.taskClicked}
            />
            <Schedule />
        </>
    )
}

import React, { useState } from 'react'
import { FlexBox } from '../../../global/global.styles'
import { IngredientCompositionBlock } from './ingredientCompositionBlock'

export const IngredientComposition = (props) => {

    return (
        <>
            <FlexBox>
                {
                    props.ingredientsArr.map((item, index) => (
                        <IngredientCompositionBlock
                            key={index}
                            name={item.ingredientName}
                            ingredientsArr={props.ingredientsArr}
                            setIngredientsArr={props.setIngredientsArr}
                        />
                    ))
                }
            </FlexBox>
        </>
    )
}

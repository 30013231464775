import React from 'react'
import { FlexBox } from '../../../global/global.styles'
import { Button } from '@mui/material'
import { SpacerLeftRightMedium, SpacerTopBottomMedium } from '../../../design/spacer/spacer'

export const CompletedTasks = (props) => {
    return (
        <>
            {
                props.completedTasks?.filter((x) => x.userId === props.user.uid).map((item, index) => (
                    <div key={index}>
                        <FlexBox>
                            {item.title}
                            <SpacerLeftRightMedium />
                            <Button
                                variant='outlined'
                                color='info'
                                sx={{ textTransform: "none" }}
                                onClick={() => props.reviewTask(item)}>
                                Review
                            </Button>
                        </FlexBox>
                        <SpacerTopBottomMedium />
                    </div>
                ))
            }
        </>
    )
}

import React from 'react'
import { FlexBox, FlexBoxSA } from '../../../global/global.styles'
import { Button, Grid } from '@mui/material'
import { SpacerLeftRightMedium } from '../../../design/spacer/spacer'

export const ScheduleActBar = (props) => {
  return (
    <FlexBoxSA>
      <Grid container spacing={2} width={500}>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
          <FlexBoxSA>
            <Button
              variant='contained'
              color='warning'
              sx={{
                textTransform: "none",
                width: 96,
                backgroundColor: '#3B9C8B',
                color: '#FFFFFF',
                ':hover': {
                  bgcolor: '#217D6D',
                  color: '#FFFFFF',
                },
              }}
              size='small'
              onClick={props.routineClicked}>
              Routine
            </Button>
          </FlexBoxSA>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
          <FlexBoxSA>
            <Button
              variant='contained'
              color='warning'
              sx={{
                textTransform: "none",
                width: 96,
                backgroundColor: '#3760AE',
                color: '#FFFFFF',
                ':hover': {
                  bgcolor: '#25478B',
                  color: '#FFFFFF',
                },
              }}
              size='small'
              onClick={props.projectClicked}>
              Project
            </Button>
          </FlexBoxSA>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
          <FlexBoxSA>
            <Button
              variant='contained'
              color='warning'
              sx={{
                textTransform: "none",
                width: 96,
                backgroundColor: '#9C3B98',
                color: '#FFFFFF',
                ':hover': {
                  bgcolor: '#782475',
                  color: '#FFFFFF',
                },
              }}
              size='small'
              onClick={props.eventClicked}>
              Event
            </Button>
          </FlexBoxSA>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
          <FlexBoxSA>
            <Button
              variant='contained'
              color='warning'
              sx={{
                textTransform: "none",
                width: 96,
                backgroundColor: '#E77A16',
                color: '#FFFFFF',
                ':hover': {
                  bgcolor: '#D26500',
                  color: '#FFFFFF',
                },
              }}
              size='small'
              onClick={props.taskClicked}>
              Task
            </Button>
          </FlexBoxSA>
        </Grid>
      </Grid>
    </FlexBoxSA>
  )
}

import React from 'react'
import { FlexBoxSA, FlexBoxSB, FlexColumnSA } from '../../../global/global.styles'
import { Button } from '@mui/material'
import { SpacerTopBottomLarge } from '../../../design/spacer/spacer'

export const TimePeriods = (props) => {
    return (
        <FlexColumnSA style={{ height: 320 }}>
            <FlexBoxSA>
                <div style={{ width: 280 }}>
                    <FlexBoxSB>
                        <Button variant='outlined' size='small'>
                            Prev Hour
                        </Button>
                        <Button variant='outlined' size='small'>
                            Next Hour
                        </Button>
                    </FlexBoxSB>
                    <SpacerTopBottomLarge />
                    <FlexBoxSB>
                        <Button variant='outlined' size='small'>
                            Prev Day
                        </Button>
                        <Button variant='outlined' size='small'>
                            Next Day
                        </Button>
                    </FlexBoxSB>
                    <SpacerTopBottomLarge />
                    <FlexBoxSB>
                        <Button variant='outlined' size='small'>
                            Prev Week
                        </Button>
                        <Button variant='outlined' size='small'>
                            Next Week
                        </Button>
                    </FlexBoxSB>
                    <SpacerTopBottomLarge />
                    <FlexBoxSB>
                        <Button variant='outlined' size='small'>
                            Prev Month
                        </Button>
                        <Button variant='outlined' size='small'>
                            Next Month
                        </Button>
                    </FlexBoxSB>
                </div>
            </FlexBoxSA>
        </FlexColumnSA>
    )
}

import React from 'react'
import { useContext } from 'react';
import { ApiContext } from '../../../context/api/api.provider';
import { useState } from 'react';
import { useEffect } from 'react';
import { TaskCorrelateWidgetContainer } from '../style/dashboard.styles';
import { TaskCorrelateBlock } from './taskCorrelateBlock';
import { BodyBoldG60 } from '../../../global/global.styles';
import { SpacerTopBottomMedium } from '../../../design/spacer/spacer';
import { AuthContext } from '../../../context/auth/auth.provider';

export const TaskCorrelateWidget = (props) => {

    const myTaskBoards = props.taskBoards.filter((x) => x.userId === props.user.uid)
    const cardsArr = myTaskBoards.filter((x) => x.cards.length > 0).flatMap(m => m.cards)
    const corrArr = cardsArr.filter((x) => x.correlate)
    const unqCorr = [... new Set(corrArr.map((item) => item.correlate))]

    return (
        <>
            <TaskCorrelateWidgetContainer>
                <BodyBoldG60 style={{ margin: 8 }}>
                    Task Correlations
                </BodyBoldG60>
                <SpacerTopBottomMedium />
                {
                    unqCorr.map((item) => (
                        <TaskCorrelateBlock
                            heading={item}
                            corrArr={corrArr}
                        />
                    ))
                }
            </TaskCorrelateWidgetContainer>
        </>
    )
}

import React, { useContext, useState } from 'react'
import { SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerRightMedium, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall } from '../../../design/spacer/spacer'
import { PasswordTile } from '../../../global/components/passwordTile'
import { AbsoluteBottomRight, AbsoluteCenter, AbsoluteTopCenter, Body, BodyBoldG60, BodyBoldWhite, BodySemiBoldWhite, FlexBox, FlexBoxSB, SubBody, SubTitleBold, SubTitleSemiBold, TitleSemiBold } from '../../../global/global.styles'
import { ArchiveBlockContainer, CategoryContainer, CredBlockContainer, DocBlockContainer, DocImg, DocImgHolder, FileDisplay, FileDisplayHolder, InfoSlotContainer } from '../style/archive.styles'
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { Backdrop, Box, Fade, Modal, Tooltip } from '@mui/material'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { db, storage } from '../../../firebase/firebase'
import { deleteDoc, doc, serverTimestamp, setDoc } from 'firebase/firestore'
import { UpdateContext } from '../../../context/update/update.provider'
import { DeleteAlert } from '../../../global/components/deleteAlert'
import { ApiContext } from '../../../context/api/api.provider'
import { NewCredForm } from '../interface/newCredForm'
import { deleteObject, ref } from 'firebase/storage'
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';

export const ArchiveBlock = (props) => {

    const { creds, credsCollectionRef } = useContext(ApiContext);
    const { refresh, setRefresh } = useContext(UpdateContext);

    const [credWarningModal, setCredWarningModal] = useState(false);
    const [docWarningModal, setDocWarningModal] = useState(false);

    const DeleteModalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
        outline: 'none'
    };

    const deleteCredBlock = async () => {

        await deleteDoc(doc(db, "credentials", props.id));

        setRefresh(!refresh)
        setCredWarningModal(false)
    }


    const [archiveModal, setArchiveModal] = useState(false)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [portal, setPortal] = useState("")
    const [category, setCategory] = useState("")
    const [other, setOther] = useState("")

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: 24,
        outline: 'none',
        borderRadius: '8px',
        backgroundColor: 'white',
        p: 4,
        minWidth: '400px'
    };

    const handleUpdate = async () => {

        const updateData = {
            username: username === "" ? props.username : username,
            password: password === "" ? props.password : password,
            portal: portal === "" ? props.portal : portal,
            category: category === "" ? props.category : category,
            other: other === "" ? props.other : other,
            keywords: `${portal === "" ? props.portal : portal},${category === "" ? props.category : category}`,
            updatedAt: serverTimestamp()
        }

        const docRef = doc(db, "credentials", props.id);
        await setDoc(docRef, updateData, {
            merge: true
        }).then(() => console.log("Document updated"));

        setArchiveModal(false);
        setRefresh(!refresh);

    }

    const deleteBlockImg = () => {
        // Create a reference to the file to delete
        const storageRef = ref(storage, props.doc);

        // Delete the file
        deleteObject(storageRef).then(async () => {

            // File deleted successfully
            await deleteDoc(doc(db, "documents", props.id));
            setRefresh(!refresh);

        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
    }

    const download = (dataurl, filename) => {
        const link = document.createElement("a");
        link.setAttribute('target', '_blank');
        link.href = dataurl;
        link.download = filename;
        link.click();
    }


    return (
        <>
            {
                <ArchiveBlockContainer>
                    {
                        props.type === "Credentials"
                            ?
                            <CredBlockContainer>
                                <FlexBoxSB>
                                    <TitleSemiBold>
                                        {props.portal}
                                    </TitleSemiBold>
                                    <FlexBox>
                                        <Tooltip title="Edit" placement='top' onClick={() => setArchiveModal(true)}>
                                            <BorderColorRoundedIcon
                                                color='secondary'
                                                sx={{
                                                    fontSize: '16px',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </Tooltip>
                                        <SpacerLeftRightMedium />
                                        <Tooltip title="Delete" placement='top' onClick={() => setCredWarningModal(true)}>
                                            <DeleteRoundedIcon
                                                color='error'
                                                sx={{
                                                    fontSize: '18px',
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </Tooltip>
                                    </FlexBox>
                                </FlexBoxSB>
                                <SpacerTopBottomLarge />
                                <Body>
                                    {props.username}
                                </Body>
                                <SpacerTopBottomSmall />
                                <PasswordTile
                                    value={props.password}
                                />
                                <SpacerTopBottomLarge />
                                <FlexBoxSB>
                                    <SubBody>
                                        {props.other}
                                    </SubBody>
                                    <SpacerLeftRightMedium />
                                    <CategoryContainer>
                                        <BodySemiBoldWhite>
                                            {props.category}
                                        </BodySemiBoldWhite>
                                    </CategoryContainer>
                                </FlexBoxSB>
                            </CredBlockContainer>
                            :
                            props.type === "Documents"
                            &&
                            <DocBlockContainer>
                                <div style={{ position: 'absolute', top: 0, right: 0, padding: 8 }}>
                                    <Tooltip title="Delete block" style={{ cursor: 'pointer' }} onClick={() => setDocWarningModal(true)}>
                                        <DeleteRoundedIcon
                                            color='error'
                                        />
                                    </Tooltip>
                                </div>
                                <div style={{ position: 'absolute', top: 0, padding: 8 }}>
                                    <Tooltip
                                        title="Download document"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => download(props.doc, props.title)}>
                                        <DownloadForOfflineRoundedIcon />
                                    </Tooltip>
                                </div>
                                <DocImgHolder>
                                    <DocImg src={props.url} />
                                </DocImgHolder>
                                <InfoSlotContainer>
                                    <SubTitleBold>
                                        {props.title}
                                    </SubTitleBold>
                                    <SpacerTopBottomMedium />
                                    <FlexBox style={{ justifyContent: 'end' }}>
                                        <CategoryContainer>
                                            <BodySemiBoldWhite>
                                                {props.category}
                                            </BodySemiBoldWhite>
                                        </CategoryContainer>
                                    </FlexBox>
                                </InfoSlotContainer>
                            </DocBlockContainer>
                    }
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={credWarningModal}
                        onClose={() => setCredWarningModal(false)}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={credWarningModal}>
                            <Box sx={DeleteModalStyle}>
                                <DeleteAlert
                                    handleCancel={() => setCredWarningModal(false)}
                                    handleDelete={deleteCredBlock}
                                />
                            </Box>
                        </Fade>
                    </Modal>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={docWarningModal}
                        onClose={() => setDocWarningModal(false)}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={docWarningModal}>
                            <Box sx={DeleteModalStyle}>
                                <DeleteAlert
                                    handleCancel={() => setDocWarningModal(false)}
                                    handleDelete={deleteBlockImg}
                                />
                            </Box>
                        </Fade>
                    </Modal>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={archiveModal}
                        onClose={() => setArchiveModal(false)}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={archiveModal}>
                            <Box sx={modalStyle}>
                                <NewCredForm
                                    username={username}
                                    placeholderUsername={props.username}
                                    onChangeUsername={(e) => setUsername(e.target.value)}
                                    password={password}
                                    placeholderPassword={props.password}
                                    onChangePassword={(e) => setPassword(e.target.value)}
                                    portal={portal}
                                    placeholderPortal={props.portal}
                                    onChangePortal={(e) => setPortal(e.target.value)}
                                    category={category}
                                    placeholderCategory={props.category}
                                    onChangeCategory={(e) => setCategory(e.target.value)}
                                    other={other}
                                    placeholderOther={props.other}
                                    onChangeOther={(e) => setOther(e.target.value)}
                                    handleSave={handleUpdate}
                                    btnTitle={"Update"}
                                    btnColor={"warning"}
                                />
                            </Box>
                        </Fade>
                    </Modal>
                </ArchiveBlockContainer>
            }
        </>
    )
}

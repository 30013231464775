import styled from 'styled-components'

// ----------------------------------------Containers----------------------------------------//

export const TemplateStyle = styled.div`
    @media only screen and (min-width: 1800px) {

    }
    @media only screen and (max-width: 1300px) {
        
    }
    @media only screen and (max-width: 1100px) {
        
    }
    @media only screen and (max-width: 850px) {
        
    }
    @media only screen and (max-width: 650px) {

    }
    @media only screen and (max-width: 370px) {
        
    }
`;

export const ProfileActBarContainer = styled.div`
    padding-bottom: ${(props) => props.theme.space[2]};
    padding-left: ${(props) => props.theme.space[3]};
    padding-right: ${(props) => props.theme.space[3]};
    position: sticky;
    z-index: 1;
    top: 104px;
    background-color: white;
    border-bottom: 2px solid #EBEFF4;
`;

export const DpHolder = styled.div`
    width: 240px;
    height: 240px;
    border-radius: 50%;
    position: relative;
`;

export const DpImg = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
`;

export const ThumbnailImg = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
`;

export const EditIconHolder = styled.div`
    width: 32px;
    height: 32px;
    cursor: pointer;
`;

export const EditIconImg = styled.img`
    width: 100%;
    height: 100%;
`;

export const AbsoluteEditIcon = styled.div`
    position: absolute;
    bottom: 16px;
    right: 16px;
`;

export const FamilyGroupInterfaceContainer = styled.div`
    
`;

export const SearchResultsBlockContainer = styled.div`
    
`;
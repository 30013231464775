import React from 'react'
import { AlignCenter, BodySemiBold, FlexBox, MagnifyIcon } from '../../../global/global.styles'
import { CircularProgress, TextField, Tooltip } from '@mui/material'
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { SpacerLeftRightMedium } from '../../../design/spacer/spacer';
import { GenericAlert } from '../../../global/components/genericAlert';
import { useState } from 'react';
import { deleteDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase/firebase';
import { useContext } from 'react';
import { UpdateContext } from '../../../context/update/update.provider';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import moment from 'moment';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

export const IngredientTableTbody = (props) => {

    const { refresh, setRefresh } = useContext(UpdateContext);

    const [loading, setLoading] = useState(false)
    const [editIngredient, setEditIngredient] = useState(false)
    const [deleteIngredient, setDeleteIngredient] = useState(false)

    const today = new Date()

    const [ingredientName, setIngredientName] = useState("")
    const [updated, setUpdated] = useState(moment(today).format("DD/MM/YY, h:mm:ss a"))
    const [calories, setCalories] = useState("")
    const [carbs, setCarbs] = useState("")
    const [protein, setProtein] = useState("")

    const handleDelete = async () => {
        await deleteDoc(doc(db, "ingredients", props.id));

        setRefresh(!refresh)
        setDeleteIngredient(false)
    }

    const handleUpdate = async () => {
        setLoading(true);

        const updateData = {
            ingredientName: ingredientName ? ingredientName : props.ingredientName,
            calories: calories ? calories : props.calories,
            carbs: carbs ? carbs : props.carbs,
            protein: protein ? protein : props.protein,
            keywords: `${ingredientName ? ingredientName : props.ingredientName}`,
            updatedAt: new Date()
        }

        const docRef = doc(db, "ingredients", props.id);
        await setDoc(docRef, updateData, {
            merge: true
        }).then(() => console.log("Document updated"));

        setRefresh(!refresh);
        setLoading(false);
        setEditIngredient(false)
    }

    return (
        <>
            {
                editIngredient
                    ?
                    <tr>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <AlignCenter>
                                <BodySemiBold>
                                    {props.index + 1}
                                </BodySemiBold>
                            </AlignCenter>
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <BodySemiBold>
                                {updated}
                            </BodySemiBold>
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <TextField
                                id="ingredientName"
                                name="ingredientName"
                                type="text"
                                size='small'
                                autoComplete='off'
                                value={ingredientName}
                                onChange={(e) => setIngredientName(e.target.value)}
                                inputProps={{ style: { fontSize: '16px' } }}
                                placeholder={props.ingredientName}
                            />
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <TextField
                                id="calories"
                                name="calories"
                                type="number"
                                size='small'
                                autoComplete='off'
                                value={calories}
                                onChange={(e) => setCalories(e.target.value)}
                                inputProps={{ style: { fontSize: '16px' } }}
                                placeholder={props.calories}
                            />
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <TextField
                                id="carbs"
                                name="carbs"
                                type="number"
                                size='small'
                                autoComplete='off'
                                value={carbs}
                                onChange={(e) => setCarbs(e.target.value)}
                                inputProps={{ style: { fontSize: '16px' } }}
                                placeholder={props.carbs}
                            />
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <TextField
                                id="protein"
                                name="protein"
                                type="number"
                                size='small'
                                autoComplete='off'
                                value={protein}
                                onChange={(e) => setProtein(e.target.value)}
                                inputProps={{ style: { fontSize: '16px' } }}
                                placeholder={props.protein}
                            />
                        </td>
                        <td style={{
                            padding: '8px 16px',
                            border: '1px solid grey'
                        }}>
                            <FlexBox>
                                {
                                    loading
                                        ?
                                        <Tooltip title="Saving" >
                                            <CircularProgress size="1rem" />
                                        </Tooltip>
                                        :
                                        <Tooltip title="Update" onClick={handleUpdate}>
                                            <MagnifyIcon>
                                                <CheckCircleRoundedIcon fontSize='small' color='warning' sx={{ cursor: 'pointer' }} />
                                            </MagnifyIcon>
                                        </Tooltip>
                                }
                                <SpacerLeftRightMedium />
                                <Tooltip title="Cancel" onClick={() => setEditIngredient(false)}>
                                    <MagnifyIcon>
                                        <CancelRoundedIcon fontSize='small' color='info' sx={{ cursor: 'pointer' }} />
                                    </MagnifyIcon>
                                </Tooltip>
                            </FlexBox>
                        </td>
                    </tr>
                    :
                    <tr>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <AlignCenter>
                                <BodySemiBold>
                                    {props.index + 1}
                                </BodySemiBold>
                            </AlignCenter>
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <BodySemiBold>
                                {props.updated}
                            </BodySemiBold>
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <BodySemiBold>
                                {props.ingredientName}
                            </BodySemiBold>
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <AlignCenter>
                                <BodySemiBold>
                                    {props.calories}
                                </BodySemiBold>
                            </AlignCenter>
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <AlignCenter>
                                <BodySemiBold>
                                    {props.carbs}
                                </BodySemiBold>
                            </AlignCenter>
                        </td>
                        <td style={{
                            padding: '8px 8px',
                            border: '1px solid grey'
                        }}>
                            <AlignCenter>
                                <BodySemiBold>
                                    {props.protein}
                                </BodySemiBold>
                            </AlignCenter>
                        </td>
                        <td style={{
                            padding: '8px 16px',
                            border: '1px solid grey'
                        }}>
                            <FlexBox>
                                <Tooltip title="Edit" onClick={() => setEditIngredient(true)}>
                                    <MagnifyIcon>
                                        <BorderColorRoundedIcon fontSize='small' color='secondary' sx={{ cursor: 'pointer' }} />
                                    </MagnifyIcon>
                                </Tooltip>
                                <SpacerLeftRightMedium />
                                <Tooltip title="Delete" onClick={() => setDeleteIngredient(true)}>
                                    <MagnifyIcon>
                                        <DeleteForeverRoundedIcon fontSize='small' color='error' sx={{ cursor: 'pointer' }} />
                                    </MagnifyIcon>
                                </Tooltip>
                            </FlexBox>
                        </td>
                    </tr>
            }
            <GenericAlert
                open={deleteIngredient}
                onClose={() => setDeleteIngredient(false)}
                title="Are you sure you want to delete?"
                cancelColor="info"
                cancelText="Cancel"
                handleCancel={() => setDeleteIngredient(false)}
                proceedColor="error"
                proceedText="Delete"
                handleProceed={handleDelete}
            />
        </>
    )
}

import React from 'react'
import { MealTileContainer, MealTileWrapper } from '../style/home.styles'
import { Grid } from '@mui/material'
import { FlexBox, SubBodyMediumG40, SubTitleSemiBold } from '../../../global/global.styles'
import { SpacerLeftRightLarge, SpacerTopBottomLarge } from '../../../design/spacer/spacer'
import { MealTileMealBlock } from './mealTileMealBlock'

export const MealTile = (props) => {
    return (
        <>
            <MealTileWrapper>
                <Grid container spacing={2}>
                    <Grid item xl={2} lg={2} md={3} sm={3} xs={12}>
                        <SubTitleSemiBold>
                            {props.startTime}
                        </SubTitleSemiBold>
                    </Grid>
                    <Grid item xl={10} lg={10} md={9} sm={9} xs={12}>
                        <MealTileContainer>
                            <SubBodyMediumG40>
                                {props.mealId}
                            </SubBodyMediumG40>
                            <SpacerTopBottomLarge />
                            <Grid container>
                                {
                                    props.meals.map((item, index) => (
                                        <Grid item xl={3} lg={3} md={6} sm={12} xs={12} key={index}>
                                            <MealTileMealBlock
                                                mealName={item.mealName}
                                                mealId={item.id}
                                                consumption={item.consumption}
                                            />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </MealTileContainer>
                    </Grid>
                </Grid>
            </MealTileWrapper>
        </>
    )
}

import React from 'react'
import { FlexBoxSA, FlexBoxSB } from '../../../global/global.styles'
import { Button } from '@mui/material'
import SearchBar from '../../../global/components/searchBar'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

export const InvMenu = (props) => {
    return (
        <>
            <FlexBoxSA>
                {/* <Button
                    variant='outlined'>
                    Insights
                </Button>
                <Button
                    variant='outlined'>
                    Automate
                </Button> */}
                <SearchBar
                    placeholder={"Search Inventory"}
                    search={props.search}
                    onChange={(event) => props.searchFilter(event.target.value)}
                    width={300}
                    xsWidth={200}
                    xxsWidth={120}
                />
                {/* <Button
                    variant='outlined'>
                    Categories
                </Button> */}
                <Button
                    variant='outlined'
                    onClick={props.newItemClicked}
                    sx={{
                        textTransform: "none"
                    }}
                    startIcon={<AddCircleRoundedIcon />}
                    disabled={
                        props.familyId
                            ?
                            false
                            :
                            true
                    }>
                    New Item
                </Button>
            </FlexBoxSA>
        </>
    )
}

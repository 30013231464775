import React, { useState } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IngCompTileContainer } from '../style/home.styles';
import { AbsoluteTopRight, BodySemiBold, FlexBox, FlexBoxSB, MagnifyIcon, SubBody } from '../../../global/global.styles';
import { SpacerLeftRightLarge, SpacerTopBottomLarge, SpacerTopBottomMedium } from '../../../design/spacer/spacer';
import { Grid, Tooltip } from '@mui/material';
import { ConsumInput } from './consumInput';

export const IngCompTile = (props) => {

    const [consumption, setConsumption] = useState([])

    const removeObject = () => {
        props.setAddedMeals(props.addedMeals.filter((x) => x.id !== props.mealId))
    }

    return (
        <>
            <IngCompTileContainer>
                <FlexBoxSB>
                    <BodySemiBold>
                        {props.label}
                    </BodySemiBold>
                    <AbsoluteTopRight style={{ padding: 8 }}>
                        <Tooltip title="Remove" onClick={removeObject}>
                            <MagnifyIcon>
                                <CloseRoundedIcon
                                    fontSize='small'
                                    color='error'
                                    sx={{
                                        cursor: "pointer"
                                    }}
                                />
                            </MagnifyIcon>
                        </Tooltip>
                    </AbsoluteTopRight>
                </FlexBoxSB>
                {
                    props.specifiedMembers.length > 0
                    &&
                    <>
                        <SpacerTopBottomLarge />
                        <SubBody>
                            Specify consumption (%)
                        </SubBody>
                        <SpacerTopBottomMedium />
                    </>
                }
                <Grid container>
                    {
                        props.specifiedMembers.map((item, index) => (
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={index}>
                                <ConsumInput
                                    memberId={item}
                                    member={`${props.users.filter((x) => x.userId === item)[0].firstname} ${props.users.filter((x) => x.userId === item)[0].lastname}`}
                                    consumption={consumption}
                                    setConsumption={setConsumption}
                                    addedMeals={props.addedMeals}
                                    setAddedMeals={props.setAddedMeals}
                                    addedMealIndex={props.addedMealIndex}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </IngCompTileContainer>
        </>
    )
}

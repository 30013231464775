import React from 'react'
import { FlexBoxSB } from '../../../global/global.styles'
import { Button } from '@mui/material'
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import { TimeTopBar } from '../style/time.styles';
import { RoutineActBar } from './routineActBar';
import { RoutineBody } from './routineBody';
import { useState } from 'react';
import { useRef } from 'react';

export const Routine = (props) => {

    const today = new Date()
    const inputDateRef = useRef()
    const [scrollDate, setScrollDate] = useState(today)

    const handleSelectToday = () => {
        setScrollDate(today)
        inputDateRef.current.value = ""
    }

    return (
        <>
            <TimeTopBar style={{ backgroundColor: "#FFF" }}>
                <FlexBoxSB>
                    <h2>Scheduler</h2>
                    <Button
                        variant='outlined'
                        sx={{ textTransform: "none" }}
                        color='error'
                        size='small'
                        startIcon={<KeyboardReturnRoundedIcon />}
                        onClick={() => props.navigate("/time")}>
                        Return
                    </Button>
                </FlexBoxSB>
            </TimeTopBar>
            <RoutineActBar
                today={today}
                inputDateRef={inputDateRef}
                selectToday={handleSelectToday}
                onChangeDate={(e) => setScrollDate(e.target.value)}
            />
            <RoutineBody
                today={today}
                scrollDate={scrollDate}
            />
        </>
    )
}

import React, { useContext, useEffect, useState } from 'react'

import Board from "../components/Board/Board";

import "../style/time.css";
import Editable from "../components/Editabled/Editable";
import { BodySemiBold, BodySemiBoldG60, FlexBox, FlexBoxSB, MagnifyIcon } from '../../../global/global.styles';
import { Backdrop, Box, Button, Fade, IconButton, Modal, Switch, Tooltip } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { ApiContext } from '../../../context/api/api.provider';
import { addDoc, deleteDoc, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { UpdateContext } from '../../../context/update/update.provider';
import { AuthContext } from '../../../context/auth/auth.provider';
import { DeleteAlert } from '../../../global/components/deleteAlert';
import { db } from '../../../firebase/firebase';
import { TimeTopBar } from '../style/time.styles';
import { SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerLeftRightSmall } from '../../../design/spacer/spacer';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { CompletedTasks } from './completedTasks';

export const Tasks = (props) => {

  const { user } = useContext(AuthContext);
  const { taskBoards, taskBoardsCollectionRef, completedTasksCollectionRef, completedTasks } = useContext(ApiContext);
  const { refresh, setRefresh } = useContext(UpdateContext);

  const [completedModal, setCompletedModal] = useState(false);
  const [seqModal, setSeqModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [boardId, setBoardId] = useState();

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    outline: 'none',
    borderRadius: '8px',
    backgroundColor: 'white',
    p: 4,
    overflowY: 'auto'
  };

  const openDeleteModal = () => {
    setDeleteModal(true)
  }

  const [targetCard, setTargetCard] = useState({
    bid: "",
    cid: "",
  });

  const addBoardHandler = async (name) => {
    const docData = {
      title: name,
      cards: [],
      sequence: taskBoards.length,
      userId: user.uid
    }
    await addDoc(taskBoardsCollectionRef, docData);
    setRefresh(!refresh);
  };

  const boardSequence = (id) => {

  }

  const removeBoard = (id) => {
    setDeleteModal(true)
    setBoardId(id)
  }

  const removeBoardConfirm = async () => {
    await deleteDoc(doc(db, "taskBoards", boardId));
    setDeleteModal(false)
    setRefresh(!refresh)
  }

  const addCardHandler = async (id, title, cards) => {
    const cardData = {
      id: Date.now() + Math.random() * 2,
      title,
      labels: [],
      date: "",
      tasks: [],
      keywords: `${title}`,
      userId: user.uid
    }

    const updateData = {
      cards: [...cards, cardData]
    }

    const docRef = doc(db, "taskBoards", id);
    await setDoc(docRef, updateData, {
      merge: true
    }).then(() => console.log("Document updated"));

    setRefresh(!refresh);
  };

  const removeCard = async (bid, cid, cards) => {
    const updatedCards = cards.filter((x) => x.id !== cid)
    const updateData = {
      cards: updatedCards
    }

    const docRef = doc(db, "taskBoards", bid);
    await setDoc(docRef, updateData, {
      merge: true
    }).then(() => console.log("Document updated"));

    setRefresh(!refresh);
  };

  const taskCompleted = async (bid, cid, cards) => {
    const card = cards.filter((x) => x.id === cid)[0]
    const updatedCards = cards.filter((x) => x.id !== cid)
    const updateData = {
      cards: updatedCards
    }

    await addDoc(completedTasksCollectionRef, card);

    const docRef = doc(db, "taskBoards", bid);
    await setDoc(docRef, updateData, {
      merge: true
    }).then(() => console.log("Document updated"));

    setRefresh(!refresh);
  };

  const dragEnded = (bid, cid) => {
    let s_boardIndex, s_cardIndex, t_boardIndex, t_cardIndex;
    s_boardIndex = taskBoards.findIndex((item) => item.id === bid);
    if (s_boardIndex < 0) return;

    s_cardIndex = taskBoards[s_boardIndex]?.cards?.findIndex(
      (item) => item.id === cid
    );
    if (s_cardIndex < 0) return;

    t_boardIndex = taskBoards.findIndex((item) => item.id === targetCard.bid);
    if (t_boardIndex < 0) return;

    t_cardIndex = taskBoards[t_boardIndex]?.cards?.findIndex(
      (item) => item.id === targetCard.cid
    );
    if (t_cardIndex < 0) return;

    const tempBoards = [...taskBoards];
    const sourceCard = tempBoards[s_boardIndex].cards[s_cardIndex];
    tempBoards[s_boardIndex].cards.splice(s_cardIndex, 1);
    tempBoards[t_boardIndex].cards.splice(t_cardIndex, 0, sourceCard);
    console.log(tempBoards);

    tempBoards.map(async (item) => {
      await setDoc(doc(db, "taskBoards", item.id),
        item,
        { merge: true }).then(() => console.log("Document updated"));
    })

    setRefresh(!refresh);

    setTargetCard({
      bid: "",
      cid: "",
    });
  };

  const dragEntered = (bid, cid) => {
    if (targetCard.cid === cid) return;
    setTargetCard({
      bid,
      cid,
    });
  };

  const updateCard = async (bid, cid, card, cards) => {
    const updatedCards = cards.filter((x) => x.id !== cid)
    const updateData = {
      cards: [...updatedCards, card]
    }

    const docRef = doc(db, "taskBoards", bid);
    await setDoc(docRef, updateData, {
      merge: true
    }).then(() => console.log("Document updated"));

    setRefresh(!refresh);
  };

  const reviewTask = async (card) => {

    const myTaskBoards = taskBoards.filter((x) => x.userId === user.uid)
    const reviewBoard = myTaskBoards.filter((x) => x.title === "Review")[0]
    const cards = reviewBoard?.cards

    if (reviewBoard) {
      const updateData = {
        cards: [...cards, card]
      }
      const docRef = doc(db, "taskBoards", reviewBoard.id);
      await setDoc(docRef, updateData, {
        merge: true
      }).then(() => console.log("Document updated"));
    } else {
      const docData = {
        title: "Review",
        cards: [],
        sequence: taskBoards.length,
        userId: user.uid
      }
      const newReviewBoard = await addDoc(taskBoardsCollectionRef, docData);

      const updateData = {
        cards: [card]
      }
      const docRef = doc(db, "taskBoards", newReviewBoard.id);
      await setDoc(docRef, updateData, {
        merge: true
      }).then(() => console.log("Document updated"));
    }

    await deleteDoc(doc(db, "completedTasks", card.id));

    setCompletedModal(false)
    setRefresh(!refresh)
  }

  return (
    <>
      <div className="app">
        <TimeTopBar style={{ backgroundColor: "#F9F0B1" }}>
          <FlexBoxSB>
            <h2>Task Board</h2>
            <FlexBox>
              <Tooltip title="Completed tasks">
                <IconButton
                  color="success"
                  aria-label="Completed tasks"
                  onClick={() => setCompletedModal(true)}>
                  <CheckCircleRoundedIcon />
                </IconButton>
              </Tooltip>
              <SpacerLeftRightSmall />
              <Tooltip title="Switch to Professional">
                <IconButton
                  color="secondary"
                  aria-label="Switch to Professional"
                  disabled>
                  <BusinessCenterRoundedIcon />
                </IconButton>
              </Tooltip>
              <SpacerLeftRightSmall />
              <Tooltip title="Exit screen">
                <IconButton
                  color="error"
                  aria-label="Exit screen"
                  onClick={() => props.navigate("/time")}>
                  <ExitToAppRoundedIcon />
                </IconButton>
              </Tooltip>
            </FlexBox>
          </FlexBoxSB>
        </TimeTopBar>
        <div className="app_boards_container">
          <div className="app_boards">
            {taskBoards.filter((x) => x.userId === user.uid).sort(function (a, b) {
              return parseFloat(a.sequence) - parseFloat(b.sequence);
            }).map((item) => (
              <Board
                key={item.id}
                board={item}
                addCard={addCardHandler}
                boardSequence={() => setSeqModal(true)}
                removeBoard={() => removeBoard(item.id)}
                removeCard={removeCard}
                taskCompleted={taskCompleted}
                dragEnded={dragEnded}
                dragEntered={dragEntered}
                updateCard={updateCard}
              />
            ))}
            <div className="app_boards_last">
              <Editable
                displayClass="app_boards_add-board"
                editClass="app_boards_add-board_edit"
                placeholder="Enter Board Name"
                text="Add Board"
                buttonText="Add Board"
                onSubmit={addBoardHandler}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={deleteModal}>
          <Box sx={modalStyle}>
            <DeleteAlert
              handleDelete={removeBoardConfirm}
              handleCancel={() => setDeleteModal(false)}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={completedModal}
        onClose={() => setCompletedModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={completedModal}>
          <Box sx={modalStyle}>
            <CompletedTasks
              completedTasks={completedTasks}
              user={user}
              reviewTask={reviewTask}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={seqModal}
        onClose={() => setSeqModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={seqModal}>
          <Box sx={modalStyle}>

          </Box>
        </Fade>
      </Modal>
    </>
  )
}

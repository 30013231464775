import React from 'react'
import { SpacerLeftRightLarge, SpacerLeftRightXL, SpacerTopBottomSmall } from '../../design/spacer/spacer'
import { BodySemiBold, FlexBox, FlexBoxSB, NavBarContainer, NavItem, SubTitleItalics, TitleItalics } from '../global.styles'
import { NavLink, useNavigate } from 'react-router-dom'
import SideBar from './sidebar'
import useWindowDimensions from './screenSize'
import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'
import { AuthContext } from '../../context/auth/auth.provider'
import { ApiContext } from '../../context/api/api.provider'
import { useContext } from 'react'
import { useState } from 'react'
import userPH from '../../assets/images/user.png'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import { Settings } from '@mui/icons-material'
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import ContactSupportRoundedIcon from '@mui/icons-material/ContactSupportRounded';
import Logout from '@mui/icons-material/Logout';

const Navbar = () => {

    const { height, width } = useWindowDimensions();

    let activeStyle = {
        color: "#000000",
        textDecoration: "none",
        fontWeight: 700,
        fontSize: 16,
    };

    let inActiveStyle = {
        color: "#313232",
        textDecoration: "none",
        fontWeight: 400,
        fontSize: 16,
    };

    const greeting = () => {
        const today = new Date()
        const curHr = today.getHours()

        if (curHr < 12) {
            return ('Good morning')
        } else if (curHr < 18) {
            return ('Good afternoon')
        } else {
            return ('Good evening')
        }
    }

    const { user, logout } = useContext(AuthContext);
    const { profile } = useContext(ApiContext);

    const navigate = useNavigate();

    const handleLogout = () => {
        logout()
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigateProfile = () => {
        navigate('/profile')
    }

    return (
        <>
            {
                width > 650
                    ?
                    <NavBarContainer>
                        <FlexBoxSB>
                            <SubTitleItalics>
                                {greeting()}
                            </SubTitleItalics>
                            <FlexBox>
                                <NavLink
                                    to="/dashboard"
                                    style={({ isActive }) =>
                                        isActive ? activeStyle : inActiveStyle
                                    }>
                                    Dashboard
                                </NavLink>
                                <SpacerLeftRightXL />
                                <NavLink
                                    to="/time"
                                    style={({ isActive }) =>
                                        isActive ? activeStyle : inActiveStyle
                                    }>
                                    Time
                                </NavLink>
                                <SpacerLeftRightXL />
                                <NavLink
                                    to="/health"
                                    style={({ isActive }) =>
                                        isActive ? activeStyle : inActiveStyle
                                    }>
                                    Health
                                </NavLink>
                                <SpacerLeftRightXL />
                                <NavLink
                                    to="/home"
                                    style={({ isActive }) =>
                                        isActive ? activeStyle : inActiveStyle
                                    }>
                                    Home
                                </NavLink>
                                <SpacerLeftRightXL />
                                <NavLink
                                    to="/portfolio"
                                    style={({ isActive }) =>
                                        isActive ? activeStyle : inActiveStyle
                                    }>
                                    Portfolio
                                </NavLink>
                                <SpacerLeftRightXL />
                                <NavLink
                                    to="/recreation"
                                    style={({ isActive }) =>
                                        isActive ? activeStyle : inActiveStyle
                                    }>
                                    Recreation
                                </NavLink>
                                {/* <SpacerLeftRightXL />
                                <NavLink
                                    to="/archive"
                                    style={({ isActive }) =>
                                        isActive ? activeStyle : inActiveStyle
                                    }>
                                    Archive
                                </NavLink> */}
                                <SpacerLeftRightXL />
                                <div>
                                    <Tooltip title={user.email}>
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        >
                                            <Avatar alt="Profile Picture" src={profile?.dp ? profile?.dp : userPH} />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        anchorEl={anchorEl}
                                        id="account-menu"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={handleClose}
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                mt: 1.5,
                                                '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                '&:before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: 'background.paper',
                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                    zIndex: 0,
                                                },
                                            },
                                        }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    >
                                        <MenuItem onClick={navigateProfile}>
                                            <Avatar alt="Profile Picture" src={profile?.dp ? profile?.dp : userPH} />
                                            Profile
                                        </MenuItem>
                                        <MenuItem>
                                            <ListItemIcon>
                                                <PeopleAltRoundedIcon fontSize="small" />
                                            </ListItemIcon>
                                            Switch account
                                        </MenuItem>
                                        <NavLink exact to="/portfolio" style={{ textDecoration: 'none', color: '#212121' }}>
                                            <MenuItem>
                                                <ListItemIcon>
                                                    <SchoolRoundedIcon fontSize="small" />
                                                </ListItemIcon>
                                                Academia
                                            </MenuItem>
                                        </NavLink>
                                        <SpacerTopBottomSmall>
                                            <Divider />
                                        </SpacerTopBottomSmall>
                                        <MenuItem>
                                            <ListItemIcon>
                                                <Settings fontSize="small" />
                                            </ListItemIcon>
                                            Settings
                                        </MenuItem>
                                        <MenuItem>
                                            <ListItemIcon>
                                                <FormatListNumberedRoundedIcon fontSize="small" />
                                            </ListItemIcon>
                                            Terms & Conditions
                                        </MenuItem>
                                        <MenuItem>
                                            <ListItemIcon>
                                                <ContactSupportRoundedIcon fontSize="small" />
                                            </ListItemIcon>
                                            Support
                                        </MenuItem>
                                        <MenuItem onClick={handleLogout}>
                                            <ListItemIcon>
                                                <Logout fontSize="small" color='error' />
                                            </ListItemIcon>
                                            <div style={{ color: '#c62828' }}>
                                                Logout
                                            </div>
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </FlexBox>
                        </FlexBoxSB>
                    </NavBarContainer>
                    :
                    <NavBarContainer>
                        <FlexBoxSB>
                            <BodySemiBold>
                                Pillars
                            </BodySemiBold>
                            <SideBar />
                        </FlexBoxSB>
                    </NavBarContainer>

            }
        </>
    )
}

export default Navbar
import { Backdrop, Box, Button, Fade, Modal } from '@mui/material'
import React from 'react'
import { SpacerTopBottomXL } from '../../design/spacer/spacer'
import { AlignCenter, FlexBoxSA, SubTitleSemiBold } from '../global.styles'

const genericModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    outline: 'none',
    borderRadius: '8px',
    backgroundColor: 'white',
    p: 4,
    overflowY: 'auto'
};

export const GenericAlert = (props) => {
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={props.onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <Box sx={genericModalStyle}>
                        <AlignCenter>
                            <SubTitleSemiBold>
                                {props.title}
                            </SubTitleSemiBold>
                            <SpacerTopBottomXL />
                            <FlexBoxSA>
                                <Button
                                    variant='outlined'
                                    color={props.cancelColor}
                                    onClick={props.handleCancel}>
                                    {props.cancelText}
                                </Button>
                                <Button
                                    variant='contained'
                                    color={props.proceedColor}
                                    onClick={props.handleProceed}>
                                    {props.proceedText}
                                </Button>
                            </FlexBoxSA>
                        </AlignCenter>
                    </Box>
                </Fade>
            </Modal>
        </>
    )
}

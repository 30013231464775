import React from 'react'
import { AbsoluteCenter, BodyBoldG60, BodySemiBold, BodySemiBoldG40, FlexBox, FlexBoxSA, FlexBoxSB, FlexColumnSA, MagnifyIcon, SubBodyBold, Title } from '../../../global/global.styles'
import { SpacerLeftRightLarge, SpacerLeftRightMedium, SpacerLeftRightSmall, SpacerRightSmall, SpacerTopBottomLarge, SpacerTopBottomMedium, SpacerTopBottomSmall, SpacerTopBottomXL, SpacerTopBottomXXL } from '../../../design/spacer/spacer'
import { Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip } from '@mui/material'
import { AddImageContainer } from '../style/home.styles';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { ImgHolder } from '../../../global/components/imgHolder';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import EggRoundedIcon from '@mui/icons-material/EggRounded';
import BalanceRoundedIcon from '@mui/icons-material/BalanceRounded';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';

export const NewItemForm = (props) => {

  return (
    <>
      {
        props.itemType === "none"
        &&
        <FlexColumnSA style={{ height: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <FlexBoxSA>
                <Button
                  variant='outlined'
                  sx={{ textTransform: 'none' }}
                  color="info"
                  onClick={props.pieceClicked}
                  sx={{ width: props.width > 650 ? 500 : 160, textTransform: "none" }}
                  endIcon={<EggRoundedIcon />}>
                  Piece
                </Button>
              </FlexBoxSA>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <FlexBoxSA>
                <Button
                  variant='outlined'
                  sx={{ textTransform: 'none' }}
                  color="info"
                  onClick={props.packagedClicked}
                  sx={{ width: props.width > 650 ? 500 : 160, textTransform: "none" }}
                  endIcon={<Inventory2RoundedIcon />}>
                  Packaged
                </Button>
              </FlexBoxSA>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <FlexBoxSA>
                <Button
                  variant='outlined'
                  sx={{ textTransform: 'none' }}
                  color="info"
                  onClick={props.weightedClicked}
                  sx={{ width: props.width > 650 ? 500 : 160, textTransform: "none" }}
                  endIcon={<BalanceRoundedIcon />}>
                  Weighted
                </Button>
              </FlexBoxSA>
            </Grid>
          </Grid>
        </FlexColumnSA>
      }
      {
        props.itemType === "piece"
          ?
          <>
            <Title>
              {
                props.scope === "edit"
                  ?
                  "Edit Item details:"
                  :
                  "Enter new Item details:"
              }
            </Title>
            <SpacerTopBottomXL />
            <FlexBoxSB>
              <div>
                <BodySemiBold>
                  Name*
                </BodySemiBold>
                <SpacerTopBottomSmall />
                <TextField
                  id="name"
                  name="name"
                  type="text"
                  size='small'
                  autoComplete='off'
                  fullWidth
                  value={props.name}
                  onChange={props.onChangeName}
                  inputProps={{ style: { fontSize: '16px' }, maxLength: 20 }}
                  placeholder={props.placeholderName}
                />
              </div>
              <SpacerLeftRightMedium />
              <div>
                <BodySemiBold>
                  Validity (days)
                </BodySemiBold>
                <SpacerTopBottomSmall />
                <TextField
                  id="validity"
                  name="validity"
                  type="number"
                  size='small'
                  autoComplete='off'
                  fullWidth
                  value={props.validity}
                  onChange={props.onChangeValidity}
                  inputProps={{ style: { fontSize: '16px' } }}
                  placeholder={props.placeholderValidity}
                />
              </div>
            </FlexBoxSB>
            <SpacerTopBottomLarge />
            <FlexBoxSB>
              <div>
                <BodySemiBold>
                  Scope
                </BodySemiBold>
                <SpacerTopBottomSmall />
                <FormControl sx={{ width: props.width > 450 ? 120 : 160 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.scope}
                    onChange={props.onChangeScope}
                    size='small'
                    fullWidth
                  >
                    {props.scopeArr.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <SpacerLeftRightMedium />
              <div>
                <BodySemiBold>
                  Whereabouts
                </BodySemiBold>
                <SpacerTopBottomSmall />
                <FormControl sx={{ width: props.width > 450 ? 120 : 160 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.whereabouts}
                    onChange={props.onChangeWhereabouts}
                    size='small'
                    fullWidth
                  >
                    {/* {props.scopeArr.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))} */}
                  </Select>
                </FormControl>
              </div>
            </FlexBoxSB>
            <SpacerTopBottomLarge />
            <BodySemiBold>
              Display Picture
            </BodySemiBold>
            <SpacerTopBottomSmall />
            {
              props.scope === "edit"
                ?
                <>
                  <FlexBoxSB>
                    <div>
                      <BodySemiBoldG40>
                        Current
                      </BodySemiBoldG40>
                      <SpacerTopBottomLarge />
                      <ImgHolder
                        img={props.dp ? props.dp : props.dpPlaceholder}
                        width={120}
                        height={120}
                      />
                    </div>
                    <ArrowForwardRoundedIcon />
                    <div>
                      <BodySemiBoldG40>
                        New
                      </BodySemiBoldG40>
                      <SpacerTopBottomLarge />
                      {
                        props.file
                          ?
                          <FlexBox>
                            <AddImageContainer htmlFor="file">
                              <AbsoluteCenter>
                                <div>
                                  <FlexBoxSA>
                                    <ImgHolder
                                      width={80}
                                      height={80}
                                      img={URL.createObjectURL(props.file)}
                                    />
                                  </FlexBoxSA>
                                  <SpacerTopBottomSmall />
                                  <SubBodyBold style={{ maxWidth: '104px' }}>
                                    {props.file.name}
                                  </SubBodyBold>
                                </div>
                              </AbsoluteCenter>
                            </AddImageContainer>
                          </FlexBox>
                          :
                          <AddImageContainer htmlFor="file">
                            <input
                              id="file"
                              type='file'
                              accept="image/*"
                              style={{ display: 'none' }}
                              onChange={props.onChangeFile}
                            />
                            <AbsoluteCenter>
                              <MagnifyIcon>
                                <AddCircleRoundedIcon
                                  sx={{
                                    color: '#B1B7B7',
                                    fontSize: '40px',
                                    cursor: 'pointer'
                                  }}
                                />
                              </MagnifyIcon>
                            </AbsoluteCenter>
                          </AddImageContainer>
                      }
                    </div>
                  </FlexBoxSB>
                </>
                :
                props.file
                  ?
                  <FlexBox>
                    <AddImageContainer htmlFor="file">
                      <AbsoluteCenter>
                        <div>
                          <FlexBoxSA>
                            <ImgHolder
                              width={80}
                              height={80}
                              img={URL.createObjectURL(props.file)}
                            />
                          </FlexBoxSA>
                          <SpacerTopBottomSmall />
                          <SubBodyBold style={{ maxWidth: '104px' }}>
                            {props.file.name}
                          </SubBodyBold>
                        </div>
                      </AbsoluteCenter>
                    </AddImageContainer>
                    <SpacerLeftRightMedium />
                    <FlexBox>
                      <label htmlFor="file">
                        <input
                          id="file"
                          type='file'
                          accept="image/*"
                          style={{ display: 'none' }}
                          onChange={props.onChangeFile}
                        />
                        <Tooltip title="Change Picture" placement='bottom'>
                          <BorderColorRoundedIcon
                            color='secondary'
                            fontSize='small'
                            sx={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                      </label>
                      <SpacerLeftRightMedium />
                      <Tooltip title="Delete Picture" placement='bottom' onClick={props.deleteDP}>
                        <DeleteForeverRoundedIcon
                          color='error'
                          sx={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </FlexBox>
                  </FlexBox>
                  :
                  <AddImageContainer htmlFor="file">
                    <input
                      id="file"
                      type='file'
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={props.onChangeFile}
                    />
                    <AbsoluteCenter>
                      <MagnifyIcon>
                        <AddCircleRoundedIcon
                          sx={{
                            color: '#B1B7B7',
                            fontSize: '40px',
                            cursor: 'pointer'
                          }}
                        />
                      </MagnifyIcon>
                    </AbsoluteCenter>
                  </AddImageContainer>
            }
            <SpacerTopBottomLarge />
            {
              props.loading
                ?
                <LoadingButton
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  fullWidth
                  variant="outlined"
                  sx={{
                    textTransform: 'none'
                  }}
                  size='large'
                >
                  Saving
                </LoadingButton>
                :
                <Button
                  variant='contained'
                  color={props.btnColor}
                  fullWidth
                  sx={{
                    textTransform: 'none'
                  }}
                  onClick={props.handleSavePiece}
                  disabled={props.disabledPiece}>
                  {props.btnTitle}
                </Button>
            }
            {
              props.scope === "edit"
                ?
                <>
                  <SpacerTopBottomMedium />
                  <Button
                    variant='outlined'
                    color="error"
                    fullWidth
                    sx={{
                      textTransform: 'none'
                    }}
                    onClick={props.closeModal}>
                    Cancel
                  </Button>
                </>
                :
                <>
                  <SpacerTopBottomMedium />
                  <Button
                    variant='outlined'
                    color="primary"
                    fullWidth
                    sx={{
                      textTransform: 'none'
                    }}
                    onClick={props.backClicked}>
                    Back
                  </Button>
                </>
            }
          </>
          :
          props.itemType === "packaged"
            ?
            <>
              <Title>
                {
                  props.scope === "edit"
                    ?
                    "Edit Item details:"
                    :
                    "Enter new Item details:"
                }
              </Title>
              <SpacerTopBottomXL />
              <FlexBoxSB>
                <div>
                  <BodySemiBold>
                    Name*
                  </BodySemiBold>
                  <SpacerTopBottomSmall />
                  <TextField
                    id="name"
                    name="name"
                    type="text"
                    size='small'
                    autoComplete='off'
                    fullWidth
                    value={props.name}
                    onChange={props.onChangeName}
                    inputProps={{ style: { fontSize: '16px' }, maxLength: 20 }}
                    placeholder={props.placeholderName}
                  />
                </div>
                <SpacerLeftRightMedium />
                <div>
                  <BodySemiBold>
                    Brand/Company
                  </BodySemiBold>
                  <SpacerTopBottomSmall />
                  <TextField
                    id="brand"
                    name="brand"
                    type="text"
                    size='small'
                    autoComplete='off'
                    fullWidth
                    value={props.brand}
                    onChange={props.onChangeBrand}
                    inputProps={{ style: { fontSize: '16px' } }}
                    placeholder={props.placeholderBrand}
                  />
                </div>
              </FlexBoxSB>
              <SpacerTopBottomLarge />
              <FlexBoxSB>
                <div>
                  <BodySemiBold>
                    Variant*
                  </BodySemiBold>
                  <SpacerTopBottomSmall />
                  <FlexBox>
                    <TextField
                      id="variantQty"
                      name="variantQty"
                      type="number"
                      size='small'
                      autoComplete='off'
                      fullWidth
                      value={props.variantQty}
                      onChange={props.onChangeVariantQty}
                      sx={{ width: 87 }}
                      inputProps={{ style: { fontSize: '16px' } }}
                      placeholder={props.placeholderVariantQty}
                    />
                    <SpacerRightSmall />
                    <FormControl>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.variantUnit}
                        onChange={props.onChangeVariantUnit}
                        size='small'
                        sx={{ minWidth: 69 }}
                      >
                        {props.units.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FlexBox>
                </div>
                <SpacerLeftRightMedium />
                <div>
                  <BodySemiBold>
                    Validity (days)
                  </BodySemiBold>
                  <SpacerTopBottomSmall />
                  <TextField
                    id="validity"
                    name="validity"
                    type="number"
                    size='small'
                    autoComplete='off'
                    fullWidth
                    value={props.validity}
                    onChange={props.onChangeValidity}
                    inputProps={{ style: { fontSize: '16px' } }}
                    placeholder={props.placeholderValidity}
                  />
                </div>
              </FlexBoxSB>
              <SpacerTopBottomLarge />
              <FlexBoxSB>
                <div>
                  <BodySemiBold>
                    Scope
                  </BodySemiBold>
                  <SpacerTopBottomSmall />
                  <FormControl sx={{ width: props.width > 450 ? 120 : 160 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={props.scope}
                      onChange={props.onChangeScope}
                      size='small'
                      fullWidth
                    >
                      {props.scopeArr.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <SpacerLeftRightMedium />
                <div>
                  <BodySemiBold>
                    Whereabouts
                  </BodySemiBold>
                  <SpacerTopBottomSmall />
                  <FormControl sx={{ width: props.width > 450 ? 120 : 160 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={props.whereabouts}
                      onChange={props.onChangeWhereabouts}
                      size='small'
                      fullWidth
                    >
                      {/* {props.scopeArr.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))} */}
                    </Select>
                  </FormControl>
                </div>
              </FlexBoxSB>
              <SpacerTopBottomLarge />
              <BodySemiBold>
                Display Picture
              </BodySemiBold>
              <SpacerTopBottomSmall />
              {
                props.scope === "edit"
                  ?
                  <>
                    <FlexBoxSB>
                      <div>
                        <BodySemiBoldG40>
                          Current
                        </BodySemiBoldG40>
                        <SpacerTopBottomLarge />
                        <ImgHolder
                          img={props.dp ? props.dp : props.dpPlaceholder}
                          width={120}
                          height={120}
                        />
                      </div>
                      <ArrowForwardRoundedIcon />
                      <div>
                        <BodySemiBoldG40>
                          New
                        </BodySemiBoldG40>
                        <SpacerTopBottomLarge />
                        {
                          props.file
                            ?
                            <FlexBox>
                              <AddImageContainer htmlFor="file">
                                <AbsoluteCenter>
                                  <div>
                                    <FlexBoxSA>
                                      <ImgHolder
                                        width={80}
                                        height={80}
                                        img={URL.createObjectURL(props.file)}
                                      />
                                    </FlexBoxSA>
                                    <SpacerTopBottomSmall />
                                    <SubBodyBold style={{ maxWidth: '104px' }}>
                                      {props.file.name}
                                    </SubBodyBold>
                                  </div>
                                </AbsoluteCenter>
                              </AddImageContainer>
                            </FlexBox>
                            :
                            <AddImageContainer htmlFor="file">
                              <input
                                id="file"
                                type='file'
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={props.onChangeFile}
                              />
                              <AbsoluteCenter>
                                <MagnifyIcon>
                                  <AddCircleRoundedIcon
                                    sx={{
                                      color: '#B1B7B7',
                                      fontSize: '40px',
                                      cursor: 'pointer'
                                    }}
                                  />
                                </MagnifyIcon>
                              </AbsoluteCenter>
                            </AddImageContainer>
                        }
                      </div>
                    </FlexBoxSB>
                  </>
                  :
                  props.file
                    ?
                    <FlexBox>
                      <AddImageContainer htmlFor="file">
                        <AbsoluteCenter>
                          <div>
                            <FlexBoxSA>
                              <ImgHolder
                                width={80}
                                height={80}
                                img={URL.createObjectURL(props.file)}
                              />
                            </FlexBoxSA>
                            <SpacerTopBottomSmall />
                            <SubBodyBold style={{ maxWidth: '104px' }}>
                              {props.file.name}
                            </SubBodyBold>
                          </div>
                        </AbsoluteCenter>
                      </AddImageContainer>
                      <SpacerLeftRightMedium />
                      <FlexBox>
                        <label htmlFor="file">
                          <input
                            id="file"
                            type='file'
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={props.onChangeFile}
                          />
                          <Tooltip title="Change Picture" placement='bottom'>
                            <BorderColorRoundedIcon
                              color='secondary'
                              fontSize='small'
                              sx={{ cursor: "pointer" }}
                            />
                          </Tooltip>
                        </label>
                        <SpacerLeftRightMedium />
                        <Tooltip title="Delete Picture" placement='bottom' onClick={props.deleteDP}>
                          <DeleteForeverRoundedIcon
                            color='error'
                            sx={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                      </FlexBox>
                    </FlexBox>
                    :
                    <AddImageContainer htmlFor="file">
                      <input
                        id="file"
                        type='file'
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={props.onChangeFile}
                      />
                      <AbsoluteCenter>
                        <MagnifyIcon>
                          <AddCircleRoundedIcon
                            sx={{
                              color: '#B1B7B7',
                              fontSize: '40px',
                              cursor: 'pointer'
                            }}
                          />
                        </MagnifyIcon>
                      </AbsoluteCenter>
                    </AddImageContainer>
              }
              <SpacerTopBottomLarge />
              {
                props.loading
                  ?
                  <LoadingButton
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    fullWidth
                    variant="outlined"
                    sx={{
                      textTransform: 'none'
                    }}
                    size='large'
                  >
                    Saving
                  </LoadingButton>
                  :
                  <Button
                    variant='contained'
                    color={props.btnColor}
                    fullWidth
                    sx={{
                      textTransform: 'none'
                    }}
                    onClick={props.handleSavePackaged}
                    disabled={props.disabledPackaged}>
                    {props.btnTitle}
                  </Button>
              }
              {
                props.scope === "edit"
                  ?
                  <>
                    <SpacerTopBottomMedium />
                    <Button
                      variant='outlined'
                      color="error"
                      fullWidth
                      sx={{
                        textTransform: 'none'
                      }}
                      onClick={props.closeModal}>
                      Cancel
                    </Button>
                  </>
                  :
                  <>
                    <SpacerTopBottomMedium />
                    <Button
                      variant='outlined'
                      color="primary"
                      fullWidth
                      sx={{
                        textTransform: 'none'
                      }}
                      onClick={props.backClicked}>
                      Back
                    </Button>
                  </>
              }
            </>
            :
            props.itemType === "weighted"
            &&
            <>
              {
                props.ambit === "edit"
                  ?
                  "Edit Item details:"
                  :
                  "Enter new Item details:"
              }
              <SpacerTopBottomXL />
              <FlexBoxSB>
                <div>
                  <BodySemiBold>
                    Name*
                  </BodySemiBold>
                  <SpacerTopBottomSmall />
                  <TextField
                    id="name"
                    name="name"
                    type="text"
                    size='small'
                    autoComplete='off'
                    fullWidth
                    value={props.name}
                    onChange={props.onChangeName}
                    inputProps={{ style: { fontSize: '16px' }, maxLength: 20 }}
                    placeholder={props.placeholderName}
                  />
                </div>
                <SpacerLeftRightMedium />
                <div>
                  <BodySemiBold>
                    Validity (days)
                  </BodySemiBold>
                  <SpacerTopBottomSmall />
                  <TextField
                    id="validity"
                    name="validity"
                    type="number"
                    size='small'
                    autoComplete='off'
                    fullWidth
                    value={props.validity}
                    onChange={props.onChangeValidity}
                    inputProps={{ style: { fontSize: '16px' } }}
                    placeholder={props.placeholderValidity}
                  />
                </div>
              </FlexBoxSB>
              <SpacerTopBottomLarge />
              <FlexBoxSB>
                <div>
                  <BodySemiBold>
                    Scope
                  </BodySemiBold>
                  <SpacerTopBottomSmall />
                  <FormControl sx={{ width: props.width > 450 ? 120 : 160 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={props.scope}
                      onChange={props.onChangeScope}
                      size='small'
                      fullWidth
                    >
                      {props.scopeArr.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <SpacerLeftRightMedium />
                <div>
                  <BodySemiBold>
                    Whereabouts
                  </BodySemiBold>
                  <SpacerTopBottomSmall />
                  <FormControl sx={{ width: props.width > 450 ? 120 : 160 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={props.whereabouts}
                      onChange={props.onChangeWhereabouts}
                      size='small'
                      fullWidth
                    >
                      {/* {props.scopeArr.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))} */}
                    </Select>
                  </FormControl>
                </div>
              </FlexBoxSB>
              <SpacerTopBottomLarge />
              <BodySemiBold>
                Display Picture
              </BodySemiBold>
              <SpacerTopBottomSmall />
              {
                props.scope === "edit"
                  ?
                  <>
                    <FlexBoxSB>
                      <div>
                        <BodySemiBoldG40>
                          Current
                        </BodySemiBoldG40>
                        <SpacerTopBottomLarge />
                        <ImgHolder
                          img={props.dp ? props.dp : props.dpPlaceholder}
                          width={120}
                          height={120}
                        />
                      </div>
                      <ArrowForwardRoundedIcon />
                      <div>
                        <BodySemiBoldG40>
                          New
                        </BodySemiBoldG40>
                        <SpacerTopBottomLarge />
                        {
                          props.file
                            ?
                            <FlexBox>
                              <AddImageContainer htmlFor="file">
                                <AbsoluteCenter>
                                  <div>
                                    <FlexBoxSA>
                                      <ImgHolder
                                        width={80}
                                        height={80}
                                        img={URL.createObjectURL(props.file)}
                                      />
                                    </FlexBoxSA>
                                    <SpacerTopBottomSmall />
                                    <SubBodyBold style={{ maxWidth: '104px' }}>
                                      {props.file.name}
                                    </SubBodyBold>
                                  </div>
                                </AbsoluteCenter>
                              </AddImageContainer>
                            </FlexBox>
                            :
                            <AddImageContainer htmlFor="file">
                              <input
                                id="file"
                                type='file'
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={props.onChangeFile}
                              />
                              <AbsoluteCenter>
                                <MagnifyIcon>
                                  <AddCircleRoundedIcon
                                    sx={{
                                      color: '#B1B7B7',
                                      fontSize: '40px',
                                      cursor: 'pointer'
                                    }}
                                  />
                                </MagnifyIcon>
                              </AbsoluteCenter>
                            </AddImageContainer>
                        }
                      </div>
                    </FlexBoxSB>
                  </>
                  :
                  props.file
                    ?
                    <FlexBox>
                      <AddImageContainer htmlFor="file">
                        <AbsoluteCenter>
                          <div>
                            <FlexBoxSA>
                              <ImgHolder
                                width={80}
                                height={80}
                                img={URL.createObjectURL(props.file)}
                              />
                            </FlexBoxSA>
                            <SpacerTopBottomSmall />
                            <SubBodyBold style={{ maxWidth: '104px' }}>
                              {props.file.name}
                            </SubBodyBold>
                          </div>
                        </AbsoluteCenter>
                      </AddImageContainer>
                      <SpacerLeftRightMedium />
                      <FlexBox>
                        <label htmlFor="file">
                          <input
                            id="file"
                            type='file'
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={props.onChangeFile}
                          />
                          <Tooltip title="Change Picture" placement='bottom'>
                            <BorderColorRoundedIcon
                              color='secondary'
                              fontSize='small'
                              sx={{ cursor: "pointer" }}
                            />
                          </Tooltip>
                        </label>
                        <SpacerLeftRightMedium />
                        <Tooltip title="Delete Picture" placement='bottom' onClick={props.deleteDP}>
                          <DeleteForeverRoundedIcon
                            color='error'
                            sx={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                      </FlexBox>
                    </FlexBox>
                    :
                    <AddImageContainer htmlFor="file">
                      <input
                        id="file"
                        type='file'
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={props.onChangeFile}
                      />
                      <AbsoluteCenter>
                        <MagnifyIcon>
                          <AddCircleRoundedIcon
                            sx={{
                              color: '#B1B7B7',
                              fontSize: '40px',
                              cursor: 'pointer'
                            }}
                          />
                        </MagnifyIcon>
                      </AbsoluteCenter>
                    </AddImageContainer>
              }
              <SpacerTopBottomLarge />
              {
                props.loading
                  ?
                  <LoadingButton
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    fullWidth
                    variant="outlined"
                    sx={{
                      textTransform: 'none'
                    }}
                    size='large'
                  >
                    Saving
                  </LoadingButton>
                  :
                  <Button
                    variant='contained'
                    color={props.btnColor}
                    fullWidth
                    sx={{
                      textTransform: 'none'
                    }}
                    onClick={props.handleSaveWeighted}
                    disabled={props.disabledWeighted}>
                    {props.btnTitle}
                  </Button>
              }
              {
                props.scope === "edit"
                  ?
                  <>
                    <SpacerTopBottomMedium />
                    <Button
                      variant='outlined'
                      color="error"
                      fullWidth
                      sx={{
                        textTransform: 'none'
                      }}
                      onClick={props.closeModal}>
                      Cancel
                    </Button>
                  </>
                  :
                  <>
                    <SpacerTopBottomMedium />
                    <Button
                      variant='outlined'
                      color="primary"
                      fullWidth
                      sx={{
                        textTransform: 'none'
                      }}
                      onClick={props.backClicked}>
                      Back
                    </Button>
                  </>
              }
            </>
      }
    </>
  )
}

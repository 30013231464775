import { IconButton, InputBase, Paper } from '@mui/material'
import React from 'react'
import { FlexBoxSA } from '../global.styles'
import SearchIcon from '@mui/icons-material/Search';
import useWindowDimensions from './screenSize';

const SearchBar = (props) => {

    const { height, width } = useWindowDimensions();

    return (
        <>
            <FlexBoxSA>
                <Paper
                    component="form"
                    sx={
                        width > 650
                            ?
                            {
                                p: '2px',
                                display: 'flex',
                                alignItems: 'center',
                                width: props.width,
                                height: 35,
                                borderRadius: 16,
                                position: props.position,
                                zIndex: props.position === "fixed" ? 1 : 0,
                                top: props.top
                            }
                            :
                            width > 380
                                ?
                                {
                                    p: '2px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: props.xsWidth,
                                    height: 35,
                                    borderRadius: 16,
                                    position: props.position,
                                    zIndex: props.position === "fixed" ? 1 : 0,
                                    top: props.top
                                }
                                :
                                {
                                    p: '2px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: props.xxsWidth,
                                    height: 35,
                                    borderRadius: 16,
                                    position: props.position,
                                    zIndex: props.position === "fixed" ? 1 : 0,
                                    top: props.top
                                }
                    }>
                    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                    <InputBase
                        sx={{ ml: 1, flex: 1, fontSize: 16, fontWeight: 500 }}
                        placeholder={props.placeholder}
                        inputProps={{ 'aria-label': 'Type a company name' }}
                        value={props.search}
                        onChange={props.onChange}
                    />
                </Paper>
            </FlexBoxSA>
        </>
    )
}

export default SearchBar
import { Button } from '@mui/material'
import React from 'react'
import { SpacerTopBottomXL } from '../../design/spacer/spacer'
import { AlignCenter, FlexBoxSA, SubTitleSemiBold } from '../global.styles'

export const DeleteAlert = (props) => {
    return (
        <>
            <AlignCenter>
                <SubTitleSemiBold>
                    Are you sure you want to delete ?
                </SubTitleSemiBold>
                <SpacerTopBottomXL />
                <FlexBoxSA>
                    <Button
                        variant='outlined'
                        onClick={props.handleCancel}>
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        color='error'
                        onClick={props.handleDelete}>
                        Delete
                    </Button>
                </FlexBoxSA>
            </AlignCenter>
        </>
    )
}

import React from 'react'
import Navbar from '../../global/components/navbar'
import { Container } from '../../global/global.styles'
import { ProfileDetails } from './interface/profileDetails'

export const Profile = () => {
    return (
        <>
            <Navbar />
            <Container>
                <ProfileDetails />
            </Container>
        </>
    )
}